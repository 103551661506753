<template>
    <div>
        <v-text-field v-model="company_name" :placeholder="translate('indehouse.request_access')"/>
        <v-btn v-on:click="request" :disabled="disabled">
            {{ translate('indehouse.request_access') }}
        </v-btn>
    </div>
</template>
<script>

import {api_mixin} from "@/shared/mixins/api_mixin";
import {rights_mapping} from "@/views/components/mappings";
import {layout_mixin} from "@/shared/mixins/layout";

export default {
    name: "request_access",
    mixins: [api_mixin, layout_mixin],
    data: function () {
        return {
            company_name: "",
            disabled_internal: true,
            selected_company_id: null
        }
    },
    methods: {
        async request() {
            if (this.disabled_internal) {
                this.$toasted.error("Invalid company")
            } else {
                let res = await this.request_access(this.company_name)

            }

        },
        request_access: async function () {
            if (this.selected_company_id === null) {
                throw "Selected company id is null"
            }
            let res = await this.api_post(
                {
                    resource: `request_access`,
                    data: {
                        company_id: this.selected_company_id
                    }
                }
            )
            if (res.user_added) {
                this.toast_results(res, `Requested access to company ${this.company_name}`)
            } else {
                this.toast_results(res, `Access already requested to ${this.company_name} current rights ${rights_mapping[res.current_rights]}`)
            }
            return res.company_exists
        },
        async company_exists(name) {
            let res = await api_mixin.methods.api_get(
                {
                    endpoint: `company_exists/${name}`,
                    return_data: true
                }
            )
            if (res.company_exists) {
                this.selected_company_id = res.company_id
            } else {
                this.selected_company_id = null
            }
            return res.company_exists
        },
        async valid_input() {
            let company_exists = await this.company_exists(this.company_name)
            this.disabled_internal = company_exists === false;
        }
    },
    computed: {
        disabled() {
            if (this.company_name.length < 4) {
                return true
            }
            this.valid_input()
            return this.disabled_internal
        }
    }
}
</script>

<style scoped>
input {
    font-size: 20px;
    text-align: center;
    width: fit-content;
    height: 30px;
    border: 1px solid #999999;
    padding: 5px;
}

</style>