<template>
    <div>
        <v-text-field v-model="internal_value" :label="field.name?field.name: label">
            <template #append-outer>
                <v-menu offset-y>
                    <template v-slot:activator="{ props }">
                        <v-btn
                            icon
                            v-bind="props"
                            @click="color_picker_open=!color_picker_open">
                            <v-icon>
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                    </template>
                </v-menu>

            </template>
        </v-text-field>
        <v-dialog v-model="color_picker_open" width="unset" overlay-opacity="0">
            <v-card>
                <v-card-text class="pt-3">
                    <v-color-picker
                        v-model="internal_value_color_picker"
                        :mode="'hexa'"
                    />
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="color_picker_open = false">
                        {{ translate('close') }}
                    </v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
    </div>
</template>

<script>
import {dialog_mixin} from "@/shared/mixins/dialog_mixin";
import {layout_mixin} from "@/shared/mixins/layout";

export default {
    name: "InputColorPicker",
    mixins: [dialog_mixin, layout_mixin],
    data() {
        return {
            internal_value: null,
            color_picker_open: false
        }
    },
    props: {
        value: {
            type: String
        },
        field: {
            type: Object,
            required: true
        },
        dotted_path: {
            type: String
        },
        label: {
            type: String,
            default: ""
        },
        items: {
            type: Array,
            default: () => []
        },
        resource: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        internal_value(val) {
            this.$emit("input", val)
        }
    },
    computed: {
        internal_value_color_picker: {
            set(val) {
                if (typeof val === "object" && val !== null) {
                    this.internal_value = val.hex.toLowerCase()
                } else if (typeof val === "string") {
                    this.internal_value = val.toLowerCase()
                } else {
                    throw `Invalid val ${val}`
                }
            },
            get() {
                return this.internal_value
            }
        }
    },
    created() {
        this.internal_value = this.value
    }
}
</script>

<style scoped>

</style>