<template>
    <div>
        <div class="panel">
            <button
                class="button_shadow"
                :class="{
                button_dark: this.$vuetify.theme.dark,
                button_light: !this.$vuetify.theme.dark}"
                @click="$emit('click')"
            >
                <slot name="default">
                    {{ text }}
                </slot>
            </button>
        </div>

    </div>

</template>

<script>
export default {
    name: "button-shadow",
    emits: ["click"],
    props: {
        text: String
    }
}
</script>

<style scoped lang="css">


.panel {
    display: flex;
    padding: 0px 0px 3px 0px;
    border-radius: 5px;
    border: 1px solid black;
    background-color: #000000;
    marign: 10;
    width: max-content;
}

.button_shadow {
    cursor: pointer;
    padding: 8px 16px;
}

.button_light {
    background: white;
    color: black;
}

.button_light:hover {
    transform: translate(0px, 1px);
    background: black;
    color: white;
}

.button_dark {
    background: grey;
    color: white;
}

.button_dark:hover {
    transform: translate(0px, 1px);
    background: black;
    color: white;
}

</style>
