<template>
    <json-viewer
        v-if="internal_value != null"
        v-model="internal_value"
        :expand-depth=6
        copyable
        sort
    />
</template>

<script>
export default {
    name: "InputJsonViewer",
    props: {
        value: {
            type: [Object, Array, null],
            nullable: true
        },
        dotted_path: {
            type: String
        },
        label: {
            type: String,
            default: ""
        },
        items: {
            type: Array,
            default: () => []
        },
        resource: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        field: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
            internal_value: {}
        }
    },
    watch: {
        internal_value(val) {
            this.$emit("input", val)
        },
        value(val) {
            this.internal_value = val
        }
    },
    created() {
        this.internal_value = this.value
    }
}
</script>

<style scoped>

</style>