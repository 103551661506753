<template>
    <div>
        <v-card flat v-if="number_of_pages === 0 & !this.loading & this.form_id === null">
            <v-card-text>
                <p> {{ translate("indehouse.configuration_not_setup_yet") }}</p>
                {{ layouts_per_page }}
            </v-card-text>
        </v-card>
        <v-card
            flat
            v-else-if="!this.loading & this.form_id === null"
        >
            <v-tabs
                v-model="tab"
                center-active
                :color="deep_get(app_settings, `tabs.color`)"
            >
                <v-tab
                    v-for="(page, index) in data.form_pages"
                    :key="index"
                    :disabled="!form_valid[index]"
                >
                    {{ page.locale[locale] }}
                </v-tab>
                <v-tab :key="number_of_pages + 1" :disabled="form_id === null">
                    {{ translate("confirmation_page", true) }}
                </v-tab>
            </v-tabs>
            <v-tabs-items
                v-model="tab"
            >
                <v-tab-item
                    v-for="(page, index) in data.form_pages"
                    :key="index"
                    class="px-5 py-5"
                >
                    <v-card>
                        <v-card-text>
                            <FormInputs
                                v-model="internal_value"
                                :layouts="data.form_layouts"
                                :options_per_id="array_to_object(data.form_options)"
                                :products_per_id="array_to_object(data.form_products)"
                                :services_per_id="array_to_object(data.form_services)"
                                :page="page"
                                :valid.sync="form_valid[index]"
                                :auth="auth"
                                :app_id="app_id"
                            />
                        </v-card-text>
                        <v-card-actions>
                            <v-btn
                                @click="go_to_next_page_or_submit"
                                :disabled="!form_valid[index] || sending_response"
                                :color="deep_get(app_settings, `buttons.color`) ?  deep_get(app_settings, `buttons.color`) :'primary'"
                                :outlined="!!deep_get(app_settings, `buttons.outlined`)"
                                :rounded="!!deep_get(app_settings, `buttons.rounded`)"
                                :loading="sending_response"
                            >
                                {{ translate(next_page_or_submit, true) }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
        <ConfirmationPage
            v-else-if="!!form_id"
            :app_id="app_id"
            :form_id="form_id"
            :auth="auth"
        />
    </div>
</template>
<script>
import {api_mixin} from "@/shared/mixins/api_mixin";
import FormInputs from "./inputs.vue";
import {layout_mixin} from "@/shared/mixins/layout";
import {mapActions} from "vuex";
import ConfirmationPage from "@/views/components/form/form/confirmation_page.vue";
import ValidationPage from "@/views/components/form/form/validation_page.vue";
import {dict_mixin} from "@/shared/mixins/dict_mixin";
import {form_mixin} from "@/mixins/form_mixin";

export default {
    name: "FormInputMain",
    components: {ValidationPage, ConfirmationPage, FormInputs},
    mixins: [api_mixin, layout_mixin, dict_mixin, form_mixin],
    props: {
        company_id: {type: String, required: true},
        app_id: {type: String, required: true},
        auth: {
            type: Boolean,
            default: false
        },
        app: {
            required: true
        },
        refresh: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        refresh(val) {
            if (val) {
                this.load_data(true)
            }
        }
    },
    data() {
        return {
            show_validation_page: false,
            internal_value: {},
            tab: 0,
            form_valid: {},
            loading: true,
            form_id: null,
            sending_response: false,
            data: {
                form_layouts: [],
                form_pages: [],
                form_products: [],
                form_services: [],
                form_options: []
            }
        }
    },
    methods: {
        ...mapActions({
            "set_loading": "dialog/set_loading"
        }),
        async go_to_next_page_or_submit() {
            if (this.next_page_or_submit === "next_page") {
                this.tab += 1
            } else if (this.next_page_or_submit === "submit") {
                await this.submit()
            } else {
                throw "Invalid option"
            }
        },
        async submit() {
            this.sending_response = true
            let item = Object.assign({})
            item["app"] = this.app_id
            item["company"] = this.company_id
            item["data"] = this.internal_value
            let res = await this.api_post(
                {
                    resource: "forms/make_form_booking",
                    data: item,
                    auth: this.auth
                }
            )
            if (!!res?._id) {
                this.form_id = res._id
            }
            this.toast_results(res, res?.message)
            this.sending_response = false

        },
        async load_data(disable_cache = true) {
            for (let resource of ["form_layouts", "form_pages", "form_products", "form_services", "form_options"]) {
                this.data[resource] = await this.api_get_items(
                    {
                        resource: resource,
                        lookup: {
                            app: this.app_id
                        },
                        auth: this.auth,
                        disable_cache
                    }
                )
            }
        },
        array_to_object(arr) {
            let tmp = {}
            for (let item of arr) {
                tmp[item["_id"]] = item
            }
            return tmp
        }
    },
    computed: {
        app_settings() {
            return this.deep_get(this.app, `settings.form.design`)
        },
        locale() {
            return this.$i18n.locale
        },
        next_page_or_submit() {
            let is_last_page = this.tab + 1 === Object.keys(this.layouts_per_page).length
            if (is_last_page) {
                return "submit"
            } else {
                return "next_page"
            }
        },
        layouts_per_page() {
            if (!this.data.form_layouts) {
                return {}
            }
            let pages = {}
            for (let layout of this.data.form_layouts) {
                if (!('page' in layout)) {
                    continue
                }
                let page = this.pages_per_id[layout.page]
                if (layout.page in pages) {
                    pages[layout.page].layouts.push(layout)
                } else {
                    pages[layout.page] = {
                        page,
                        layouts: [layout]
                    }
                }
            }
            return pages
        },
        number_of_pages() {
            return Object.keys(this.layouts_per_page).length
        },
        pages_per_id() {
            let tmp = {}
            for (let pages of this.data.form_pages) {
                tmp[pages["_id"]] = pages
            }
            return tmp
        },
        all_pages_valid() {
            for (let key in Object.keys(this.form_valid)) {
                if (!this.form_valid[key]) {
                    return false
                }
            }
            return true
        },
    },
    async created() {
        this.loading = true
        await this.load_data(false)
        this.loading = false
    }
}
</script>

<style scoped>
</style>