<template>
    <div>
        <inde-dialog-layout>
            <template #main>
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-card-title>
                                    {{ value.name }}
                                </v-card-title>
                                <v-card-subtitle>
                                    {{ value.email }}
                                </v-card-subtitle>
                                <v-card-text>
                                    Vad vill vi se här?
                                </v-card-text>
                                <!--                                <IndeTableView-->
                                <!--                                    resource="form_logs"-->
                                <!--                                    :lookup="{'customer': value['_id']}"-->
                                <!--                                    :open_on_close="{-->
                                <!--                                        item: value,-->
                                <!--                                        resource_id: value['_id'],-->
                                <!--                                        resource: 'form_customers',-->
                                <!--                                        location: 'default'-->
                                <!--                                    }"-->
                                <!--                                    :init_sort_by="['_created']"-->
                                <!--                                    :init_sort_desc="[true]"-->
                                <!--                                    hide_header-->

                                <!--                                />-->
                            </v-col>
                            <v-col>
                                <IndeTableView
                                    resource="forms"
                                    :lookup="{'customer.customer': value['_id']}"
                                    :open_on_close="{
                                        item: value,
                                        resource_id: value['_id'],
                                        resource: 'form_customers',
                                        location: 'default'
                                    }"
                                    :init_sort_by="['_created']"
                                    :init_sort_desc="[true]"
                                    hide_header

                                />
                                <!--                                <IndeTableView-->
                                <!--                                    resource="email_distributions"-->
                                <!--                                    :lookup="{'customer': value['_id']}"-->
                                <!--                                    :open_on_close="{-->
                                <!--                                        item: value,-->
                                <!--                                        resource_id: value['_id'],-->
                                <!--                                        resource: 'form_customers',-->
                                <!--                                        location: 'default'-->
                                <!--                                    }"-->
                                <!--                                    :init_sort_by="['_created']"-->
                                <!--                                    :init_sort_desc="[true]"-->
                                <!--                                    hide_header-->
                                <!--                                />-->
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </template>
            <template #actions>
                <v-btn @click="close_item">
                    {{ translate('close') }}
                </v-btn>
            </template>
        </inde-dialog-layout>
    </div>
</template>

<script>
import IndeDialogLayout from "@/shared/components/other/inde_dialog_layout.vue";
import IndeTableView from "@/shared/components/view/inde_table_view.vue";
import {api_mixin} from "@/shared/mixins/api_mixin";
import {layout_mixin} from "@/shared/mixins/layout";
import OpenEmail from "@/views/components/form/view_booking/open_email.vue";
import {mapActions} from "vuex";

export default {
    name: "form-customer",
    components: {OpenEmail, IndeTableView, IndeDialogLayout},
    mixins: [api_mixin, layout_mixin],
    props: {
        value: {
            type: Object
        },
        form: {
            type: Object
        },
        customer_id: {
            type: String
        }
    },
    data() {
        return {
            count: null,
            email_distributions: []
        }
    },
    methods: {
        ...mapActions({close_item: "dialog/close_item"}),
        async get_count() {
            let response = await this.api_post(
                {
                    resource: "forms/count_forms",
                    data: {
                        app: this.value["app"],
                        customer: this.value["_id"],
                    }
                }
            )
            if (response["_status"] === "OK") {
                this.count = response["count"]
            }
        },
        async get_email_deliveries() {
            this.email_distributions = await this.api_get_items(
                {
                    resource: "email_distributions",
                    lookup: {
                        "customer": this.value["_id"]
                    },
                    sort: {"_created": -1}
                }
            )

        }
    },
    async created() {
        await this.get_count()
        await this.get_email_deliveries()
    }
}
</script>

<style scoped>

</style>