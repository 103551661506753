<template>
    <inde-dialog-layout>
        <template #tool_bar_left>
            Hello
        </template>
        <template #main>
            <spacing :col="10">
                <v-row>
                    <v-col>
                        <input-type
                            resource="form_inventory_adjustment"
                            dotted_path="form"
                            v-model="internal_value.form"
                            :field="field.schema.form"
                            :disabled="false"
                        />
                    </v-col>
                    <v-col>
                        <input-type
                            resource="form_inventory_adjustment"
                            dotted_path="inventory"
                            v-model="internal_value.inventory"
                            :field="field.schema.inventory"
                            :disabled="false"

                        />
                        <input-type
                            resource="form_inventory_adjustment"
                            dotted_path="amount"
                            v-model="internal_value.amount"
                            :field="field.schema.amount"
                            :disabled="false"

                        />
                        <input-type
                            v-if="reset_amount"
                            resource="form_inventory_adjustment"
                            dotted_path="inventory"
                            v-model="internal_value.reset_amount"
                            :field="field.schema.reset_amount"
                            :disabled="false"
                        />
                    </v-col>
                </v-row>
            </spacing>
        </template>
        <template #actions>
            <v-card
                flat
                height="100%"
                width="100%"
                align="center"
            >
                <v-card-subtitle :align="'center'">
                    {{ translate('created') }}
                </v-card-subtitle>
            </v-card>
        </template>
    </inde-dialog-layout>
</template>
<script>
import IndeDialogLayout from "@/shared/components/other/inde_dialog_layout.vue";
import {layout_mixin} from "@/shared/mixins/layout";
import InputType from "@/shared/components/dialogs/input_type.vue";
import View_objectid from "@/shared/components/helper/view_objectid.vue";
import {mapGetters} from "vuex";
import InputSelect from "@/shared/components/dialogs/input_select.vue";
import Spacing from "@/shared/components/helper/Spacing.vue";

export default {
    name: "form_inventory_adjustment",
    components: {Spacing, InputSelect, View_objectid, InputType, IndeDialogLayout},
    mixins: [layout_mixin],
    props: {
        value: {
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false
        },
        dotted_path: {
            type: String
        },
        field: {
            type: Object
        },
        reset_amount: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            internal_value: {}
        }
    },
    watch: {
        value(val) {
            this.internal_value = val
        },
        internal_value(val) {
            this.$emit("input", val)
        },
    },
    computed: {
        ...mapGetters({current_mode: "dialog/current_mode"}),

    },
    created() {
        this.internal_value = this.value
    }
}
</script>

<style scoped>

</style>