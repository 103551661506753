import {api_mixin} from "@/shared/mixins/api_mixin";

export let form_mixin = {
    mixins: [api_mixin],
    methods: {
        async submit_form_log({interaction_log, company, app, name, data = {}}) {
            try {
                let res = await this.api_post({
                    resource: "form/log",
                    data: {
                        name,
                        company,
                        app,
                    },
                    auth: false,
                })
                console.log(res)
            } catch (e) {
                return null
            }
        },
        async get_data() {
            let data = {}
            for (let resource in ["form_layouts", "form_pages", "form_products", "form_services", "form_options"]) {
                data[resource] = await this.api_get_items(
                    {
                        resource: resource,
                        lookup: {
                            app: this.app_id
                        },
                        auth: !this.app_is_public
                    }
                )
            }

            return data
        },
        duplicate(resource, item) {
            let tmp_item = {...item}
            delete tmp_item["_etag"]
            delete tmp_item["_id"]
            delete tmp_item["_created"]
            delete tmp_item["_updated"]
            this.open_item({
                    resource: resource,
                    item: tmp_item,
                    mode: "create",
                    locked_data: this.default_data,
                }
            )
        },
    }
}