import { render, staticRenderFns } from "./syncogs_listings.vue?vue&type=template&id=ff7b9dce&scoped=true"
import script from "./syncogs_listings.vue?vue&type=script&lang=js"
export * from "./syncogs_listings.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff7b9dce",
  null
  
)

export default component.exports