<template>
    <div>
        <p class="cut-text">{{ internal_value }}</p>
    </div>
</template>

<script>
import {api_mixin} from "@/shared/mixins/api_mixin";
import {layout_mixin} from "@/shared/mixins/layout";

export default {
    name: "view_objectid",
    mixins: [api_mixin, layout_mixin],
    props: {
        value: {},
        resource: {
            required: true,
            type: String
        },
        itemText: {
            type: String,
            default: "name"
        }
    },
    data() {
        return {
            internal_value: ""
        }
    },
    watch: {
        value(val) {
            this.get_data()
        }
    },
    methods: {
        async get_data() {
            if (this.is_object_id(this.value)) {
                let res = await this.api_get_items(
                    {
                        resource: this.resource,
                        lookup: {
                            "_id": this.value
                        },
                        projection: {
                            [this.itemText]: 1
                        },
                        first: true
                    }
                )
                if (res === undefined){
                    throw `Nothing returned from resource ${this.resource} _id ${this.value}`
                }
                this.internal_value = res[this.itemText]
            } else if (Array.isArray(this.value)) {
                let res = await this.api_get_items(
                    {
                        resource: this.resource,
                        lookup: {"_id": {"$in": this.value}},
                        projection: {
                            [this.itemText]: 1
                        }
                    }
                )
                this.internal_value = res.map(x => x[this.itemText]).join(", ")
            } else {
                this.internal_value = "-"
            }
        },

    },
    async created() {
        await this.get_data()
    }
}
</script>

<style scoped>
.cut-text {
    text-overflow: ellipsis;
    overflow: hidden;
}
</style>