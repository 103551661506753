import { render, staticRenderFns } from "./theme_template_section.vue?vue&type=template&id=44988a75&scoped=true"
import script from "./theme_template_section.vue?vue&type=script&lang=js"
export * from "./theme_template_section.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44988a75",
  null
  
)

export default component.exports