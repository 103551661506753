import { render, staticRenderFns } from "./BottonBar.vue?vue&type=template&id=2737c581&scoped=true"
import script from "./BottonBar.vue?vue&type=script&lang=js"
export * from "./BottonBar.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2737c581",
  null
  
)

export default component.exports