<template>
    <div>
        <loading v-if="internal_loading"/>
        <v-row v-else-if="apps.length === 0">
            <v-col align="center">
                <v-card width="100vw" max-width="800px" class="px-10 pt-10">
                    <h2>{{ translate('user_have_no_apps', true) }}</h2>
                    <v-card-text>
                        <v-row justify="center">
                            <v-col :cols="12" :md="12" :lg="6">
                                <CreateCompany/>
                            </v-col>
                            <v-col :cols="12" :md="12" :lg="6">
                                <RequestAccess/>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col :cols="12" :lg="6" :xl="6" class="pb-10">
                <IndeCardViewBasic :cols="1" :sm="2" :lg="3" :items="current_apps">
                    <template #default="{item}">
                        <App :app="item" :edit="false"/>
                    </template>
                </IndeCardViewBasic>
            </v-col>
            <v-col :cols="12" :lg="6" :xl="6" class="pb-10">
                <MyPickups class="my-2" v-if="have_pickup_app"/>
                <MyBookings class="my-2" v-if="have_booking_app"/>
            </v-col>
        </v-row>

    </div>
</template>

<script>
import MyBookings from "@/views/components/home/my_bookings.vue";
import App from "@/views/components/app/app.vue";
import {layout_mixin} from "@/shared/mixins/layout";
import {api_mixin} from "@/shared/mixins/api_mixin";
import {mapActions, mapGetters} from "vuex";
import MyPickups from "@/views/components/home/my_pickups.vue";
import IndeCardViewBasic from "@/shared/components/other/inde_card_view_basic.vue";
import Create_company from "@/views/components/create_company/create_company.vue";
import Loading from "@/shared/components/loading.vue";
import CreateCompany from "@/views/components/create_company/create_company.vue";
import RequestAccess from "@/views/components/create_company/request_access.vue";


export default {
    name: "logged-in",
    mixins: [layout_mixin, api_mixin],
    components: {
        RequestAccess, CreateCompany,
        Loading,
        Create_company,
        IndeCardViewBasic,
        MyPickups,
        MyBookings,
        App
    },
    data() {
        return {
            selected_company: {},
            apps: [],
            companies: [],
            have_booking_app: false,
            have_pickup_app: false
        }
    },
    computed: {
        ...mapGetters({
            current_companies: "navigation/current_company",
            current_apps: "navigation/current_apps"
        })
    },
    methods: {
        ...mapActions({set_loading: "dialog/set_loading"}),
        async get_apps_and_companies() {
            this.companies = await this.api_get_items({
                resource: "companies",
                lookup: {},
            })
            this.apps = await this.api_get_items({
                resource: "apps",
                lookup: {"company": {"$in": this.companies.map(x => x._id)}},
            })
        }
    },
    async created() {
        this.internal_loading = true
        await this.get_apps_and_companies()
        this.have_booking_app = Boolean(this.apps.filter(x => ["booking", "hotel"].includes(x.type)).length)
        this.have_pickup_app = Boolean(this.apps.filter(x => ["pickup"].includes(x.type)).length)
        this.internal_loading = false

    }
}
</script>

<style scoped>

</style>