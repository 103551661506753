import {mutation, state} from "@/store/booking/mutations";
import store from "@/store"
import {Book} from "@/interfaces";
import {api_mixin} from "@/shared/mixins/api_mixin";
import {DateTime, now} from "@/shared/helpers/date_helper";

export const booking_store = {
    namespaced: true,
    state() {
        return state
    },
    mutations: mutation,
    actions: {
        async set_company({commit}, {company_id, app_id}) {
            commit("update_company", {company_id, app_id})
        },
        async update_week_data({commit, dispatch, state}, {company_id, app_id, year, month, day}) {
            commit("update_company", {company_id, app_id})
            let week_bookings = await api_mixin.methods.api_get(
                {
                    endpoint: `booking_week/${year}/${month}/${day}`
                }
            )
            try {
                commit("update_booking", week_bookings)
                dispatch("select_date", new DateTime({year, month, day}))
            } catch (e) {
                console.log("Could not parse the data Error:", e)
            }
        },
        async update_daily_data({state, commit}) {
            let date = state.selected.current_date
            let data = await api_mixin.methods.api_get(
                {
                    endpoint: `booking_day/${state.company_id}/${state.app_id}/${date.year}/${date.month}/${date.day}`
                }
            )
            try {
                let bookings = data.bookings.map((d) => new Book(d))
                return commit("update_booking_day", bookings)
            } catch (e) {
                console.log("Could not parse data booking day \n", e)
                console.log(data)
            }

        },
        async select_date({state, dispatch, commit}, date) {
            if (state.selected.current_date === null) {
                state.selected.current_date = date
            } else if (state.selected.current_date.is_equal(date)) {
                return
            } else {
                commit("clear_selected_hours")
                state.selected.current_date = date
            }
            await dispatch("update_daily_data")
            history.pushState({}, null, `/booking/${state.company_id}/${state.app_id}/${date.year}/${date.month}/${date.day}`)
        },
        select_hour({state, commit, dispatch}, book) {
            if (Object.keys(state.selected.hours).length === 0) {
                // First selected
                commit("update_selected", {key: "date_from", value: book.date_from})
                commit("update_selected", {key: "date_to", value: book.date_to})
                commit("insert_hour", book)
                book.selected = true
            } else if (state.selected.date_from === null) {
                // Second select
                console.log("Second selected")
                if (book.date_from.hour in Object.keys(state.selected.hours) || book.date_from.hour < Object.keys(state.selected.hours)[0]) {
                    dispatch("clear_selected_hours")
                    console.log("Clearing hours")
                } else {
                    console.log("Adding hours 1")
                    let bookings_to_add = state.data.filter((b) => (state.selected.date_from.hour <= b.date_from.hour <= book.date_to.hour))
                    for (let bo of bookings_to_add) {
                        console.log("Adding hours 2", bo.date_from.hour)
                        commit("insert_hour", bo)
                    }
                    commit("update_selected", {key: "date_to", value: book})
                }
            } else {
                let selected_hours_start = Object.keys(state.selected.hours).map((x) => parseInt(x))
                let current_max = Math.max.apply(Math, selected_hours_start)
                if (current_max < book.date_from.hour) {
                    let bookings_to_add = []
                    for (let b of state.data) {
                        let from_hour = b.date_from.hour
                        if (current_max < from_hour && from_hour <= book.date_from.hour) {
                            bookings_to_add.push(b)
                        }
                    }
                    for (let bo of bookings_to_add) {
                        commit("insert_hour", bo)

                    }
                    commit("update_selected", {key: "date_to", value: book.date_to})
                } else {
                    commit("clear_selected_hours")
                    dispatch("select_hour", book)
                }
            }
        },
        async go_to_new_day({state, dispatch}, {year, month, day}) {
            console.log("going to ", year, month, day)
            dispatch("update_week_data", {
                company_id: state.company_id, app_id: state.app_id, year, month, day
            })
            dispatch("update_daily_data", {
                company_id: state.company_id, app_id: state.app_id, year, month, day
            })
        },
        async go_to_today({dispatch}) {
            let today = now()
            dispatch("go_to_new_day", {
                year: today.year, month: today.month, day: today.day,
            })
        },
        async go_to_last_week({state, dispatch}) {
            dispatch("go_to_new_day", {
                year: state.last_week.year, month: state.last_week.month, day: state.last_week.day,
            })
            history.pushState({}, null, `/booking/${state.company_id}/${state.app_id}/${state.last_week.year}/${state.last_week.month}/${state.last_week.day}`)
        },
        async go_to_next_week({state, dispatch}) {
            dispatch("go_to_new_day", {
                year: state.next_week.year, month: state.next_week.month, day: state.next_week.day,
            })
            history.pushState({}, null, `/booking/${state.company_id}/${state.app_id}/${state.next_week.year}/${state.next_week.month}/${state.next_week.day}`)
        },
        async delete_booking({state, dispatch}, {_id, _etag, date_from, date_to}) {

            let data = {
                company: state.company_id,
                app: state.app_id,
                date_from,
                date_to,
                booking_id: _id,
                booking_etag: _etag
            }
            console.log(data)
            let res = await api_mixin.methods.api_post({
                    resource: "delete_booking",
                    data
                }
            )
            console.log(res)
            if (res._status === "OK") {
                dispatch("update_daily_data")
            }
            return res
        },
        async submit({state, dispatch, commit}) {
            let date_from = state.selected.date_from.date
            let date_to
            // todo check this
            if (state.selected.date_to === null || state.selected.date_to === undefined) {
                console.log("date to is null or undefined")
                date_to = state.selected.date_from.date
            } else {
                console.log("date to not null")
                date_to = state.selected.date_to.date
            }
            let res = await api_mixin.methods.api_post(
                {
                    resource: "make_booking",
                    data: {
                        company: state.company_id,
                        app: state.app_id,
                        user: store.getters["auth/current_user"]._id,
                        date_from: date_from,
                        date_to: date_to
                    }
                }
            )
            if (res._status === "OK") { // something
                dispatch("update_daily_data")
            }
            commit("clear_selected_hours")
            return res
        },
    },
    getters: {
        bookings(state) {
            return state.bookings
        }, loaded(state) {
            return state.loaded
        }, dates_clean(state) {
            return state.dates.map((d) => ({
                "index": d.index, "date": d.date, "text": d.date.day.toString()
            }))
        }
    }
}
