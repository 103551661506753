<template>
    <div>
        <loading v-if="internal_loading"/>
        <autocomplete
            v-model="internal_value"
            v-else-if="layout.type.startsWith('select_info')"
            :items="internal_options"
            :item-text="`name`"
            item-value="_id"
            :label="layout.locale?.[locale]"
            :rules="internal_rules"
            :multiple="layout.type === 'select_info_multiple'"
            clearable
        >
            <template #selection="{item}">
                <v-chip small :color="item.product ? 'secondary': 'accent'">
                    {{ item[`locale`][locale] }}
                </v-chip>
            </template>
            <template #item="{item}">
                <v-row>
                    <v-col :align="'start'">
                        {{ item[`locale`][locale] }}
                    </v-col>
                    <v-col :align="'end'" v-if="item.product">
                        {{ get_price(item.product, item, 'product') }}
                        {{ internal_products?.[item.product]?.currency }}
                    </v-col>
                    <v-col :align="'end'" v-if="item.service">
                        {{ get_price(item.service, item, 'service') }}
                        {{ internal_services?.[item.service]?.currency }}
                    </v-col>
                </v-row>
            </template>
        </autocomplete>
        <AutocompleteWithNumbers
            v-model="internal_value"
            v-else-if="layout.type === 'select_with_amount_multiple'"
            :items="internal_options"
            :item-text="`name`"
            item-value="_id"
            :label="layout.locale?.[locale]"
            :rules="internal_rules"
            :layout="layout"
            :products_per_id="products_per_id"
        />
        <v-select
            v-model="internal_value"
            v-else-if="layout.type ==='select_single'"
            :items="internal_options"
            :item-text="`locale.${locale}`"
            item-value="_id"
            :label="layout.locale?.[locale]"
            :rules="internal_rules"
            clearable
        />
        <v-select
            v-model="internal_value"
            v-else-if="layout.type ==='select_multiple'"
            :items="internal_options"
            :item-text="`locale.${locale}`"
            :label="layout.locale?.[locale]"
            :rules="internal_rules"
            item-value="_id"
            multiple
            clearable
        />
        <v-text-field
            v-model="internal_value"
            v-else-if="layout.type ==='string'"
            item-value="value"
            :label="layout.locale?.[locale]"
            :rules="internal_rules"
            clearable
        />
        <v-text-field
            v-model="internal_value"
            v-else-if="layout.type ==='number'"
            item-value="value"
            type="number"
            :label="layout.locale?.[locale]"
            :rules="internal_rules"
            clearable
        />
        <inde-stepper-date
            v-model="internal_value"
            v-else-if="layout.type ==='date'"
            item-value="value"
            type="number"
            :label="layout.locale?.[locale]"
        />
        <v-textarea
            v-model="internal_value"
            v-else-if="layout.type === 'textarea'"
            item-value="value"
            clearable
            :label="layout.locale?.[locale]"
            :rules="internal_rules"
        />
        <v-text-field
            v-model="internal_value"
            v-else-if="layout.type ==='textfield'"
            item-value="value"
            clearable
            :name="layout?.customer_field"
            :autocomplete="auto_complete"
            :label="layout.locale?.[locale]"
            :rules="internal_rules"
        />
        <p v-else>
            invalid layout type {{ layout.type }}
        </p>
    </div>
</template>

<script>
import {layout_mixin} from "@/shared/mixins/layout";
import IndeStepperDate from "@/shared/components/dialogs/stepper_date.vue";
import Loading from "@/shared/components/loading.vue";
import Autocomplete from "@/views/components/form/form/autocomplete.vue";
import {dict_mixin} from "@/shared/mixins/dict_mixin";
import {api_mixin} from "@/shared/mixins/api_mixin";
import AutocompleteWithNumbers from "@/views/components/form/form/multiselect_with_amount.vue";

export default {
    name: "FormInput",
    components: {AutocompleteWithNumbers, Autocomplete, Loading, IndeStepperDate},
    mixins: [layout_mixin, dict_mixin, api_mixin],
    props: {
        layout: {
            type: Object,
            required: true,
        },
        options_per_id: {
            type: Object,
            required: true,
        },
        products_per_id: {
            type: Object,
            required: true,
        },
        services_per_id: {
            type: Object,
            required: true,
        },
        dependable_value: {},
        show_if_value: {},
        value: {},
        auth: {
            type: Boolean,
            required: true
        },
        app_id: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            internal_value: null,
            internal_rules: [],
            internal_options: [],
            internal_products: [],
            internal_services: [],
            tmp: 0
        }
    },
    watch: {
        internal_value(val) {
            this.$emit("input", val)
        },
        async dependable_value() {
            if (this.layout.dependable_options || this.layout.show_if) {
                this.$nextTick(() => {
                    this.create_internal_options()
                })
            }
        },
        async show_if_value() {
            if (this.layout.dependable_options || this.layout.show_if) {
                this.$nextTick(() => {
                    this.create_internal_options()
                })
            }
        },
    },
    methods: {
        create_rules() {
            let tmp = []
            if (this.layout.rules) {
                if (this.layout.rules?.required?.value) {
                    tmp.push(
                        value => !!value || this.layout.rules?.required?.locale?.[this.locale]
                    )
                }
                if (this.layout.rules?.regex?.value) {
                    let re = new RegExp(this.layout.rules?.regex?.value)
                    let locale = this.layout.rules?.regex?.locale?.[this.locale]
                    console.log("locale", locale)

                    function test_regex(value) {
                        if (re.test(value)) {
                            return true
                        }
                        return locale
                    }

                    tmp.push(
                        value => test_regex(value)
                    )
                }
            }
            return tmp
        },
        async create_internal_options() {
            if (this.layout?.dependable_options?.depends_on_field) {
                if (!this.dependable_value) {
                    this.internal_options = []
                    return
                }

                if (!Array.isArray(this.layout.dependable_options.options)) {
                    console.log(this.layout)
                    throw `Layout name ${this.layout.name} layout id ${this.layout._id} doesn't have an option`
                }
                let dependable_options_id = []
                if (Array.isArray(this.dependable_value)) {
                    for (let dep_opt of this.layout.dependable_options.options) {
                        for (let dep_val of this.dependable_value) {
                            if (dep_opt.equals.includes(dep_val)) {
                                console.log(dep_opt, !(dep_opt in dependable_options_id))
                                if (!(dep_opt in dependable_options_id)) {
                                    dependable_options_id.push(dep_opt)
                                }
                            }
                        }
                    }
                    console.log(dependable_options_id)
                } else {
                    dependable_options_id = this.layout.dependable_options.options.filter(
                        x => x.equals.includes(this.dependable_value)
                    )
                }

                let options_ids = []

                for (let dependable_option of dependable_options_id) {
                    for (let o of dependable_option.options) {
                        if (!(o in options_ids)) {
                            options_ids.push(o)
                        }
                    }
                }
                if (options_ids.length !== 0) {
                    let options_per_id = await this.api_get_items({
                        resource: "form_options",
                        lookup: {"_id": {"$in": options_ids}, "app": this.app_id},
                        auth: this.auth,
                        return_per_id: true
                    })


                    let result = []
                    for (let option_id of options_ids) {
                        result.push(options_per_id[option_id])
                    }
                    this.internal_options = result
                } else {
                    this.internal_options = []
                }


            } else if (this.layout.options) {
                let options_per_id = await this.api_get_items({
                    resource: "form_options",
                    lookup: {"_id": {"$in": this.layout.options}, "app": this.app_id},
                    auth: this.auth,
                    return_per_id: true
                })

                let result = []
                for (let option of this.layout.options) {
                    result.push(options_per_id[option])
                }
                this.internal_options = result
            } else if (["select_single", "select_multiple"].includes(this.layout.type)) {
                console.log(`Missing options or dependable value for layout ${this.layout.name}`)
                this.internal_options = []
            }
        },
        async get_internal_products_and_services() {
            let services = []
            let products = []
            for (let option of this.internal_options) {
                if ("product" in option) {
                    products.push(option["product"])
                }
                if ("service" in option) {
                    services.push(option["service"])
                }
            }
            if (products.length > 0) {
                this.internal_products = await this.api_get_items(
                    {
                        resource: "form_products",
                        lookup: {"_id": {"$in": products}, "app": this.app_id},
                        auth: this.auth
                    }
                )
            }

            if (services.length > 0) {
                this.internal_services = await this.api_get_items(
                    {
                        resource: "form_services",
                        lookup: {"_id": {"$in": services}, "app": this.app_id},
                        auth: this.auth
                    }
                )
            }
        },
        get_price(item_id, option, service_or_product) {
            let item, number_of_items
            if (service_or_product === "service") {
                item = this.services_per_id[item_id]
                number_of_items = 1
            } else {
                item = this.products_per_id[item_id]
                number_of_items = "number_of_items" in option ? option["number_of_items"] : 1
            }
            if (!item?.price) {
                console.warn(`Missing price on product ${item_id} ${service_or_product}`)
            }
            return item['price'] * number_of_items
        }
    },
    computed: {
        locale() {
            return this.$i18n.locale
        },
        auto_complete() {
            if (this.layout?.customer_field) {
                if (this.layout?.customer_field === "phone_number") {
                    return "tel"
                }
                return this.layout?.customer_field
            }
            return null
        }
    },
    async created() {
        this.internal_loading = true
        this.internal_rules = this.create_rules()
        await this.create_internal_options()
        await this.get_internal_products_and_services()
        this.internal_value = this.value
        this.internal_loading = false

    }
}
</script>

<style scoped>

</style>