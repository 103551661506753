<template>
    <div>
        <v-row justify="center">
            <v-col sm="12" xs="12" md="12">
                <create_company/>
            </v-col>
            <v-col sm="12" xs="12" md="12">
                <request_access/>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import request_access from "@/views/components/create_company/request_access";
import create_company from "@/views/components/create_company/create_company";

export default {
    name: "request_create",
    components: {create_company, request_access}
}
</script>

<style scoped>

</style>