<template>
    <div>
        <InputMain
            v-if="current_item.item.template"
            v-model="internal_value"
            :dotted_path="`${dotted_path}.${current_item.item.template}`"
            :domain_settings="field.schema?.[current_item.item.template]"
            :disabled="current_mode === 'view'"
            is_card
            force_horizontal_tabs
        />
        <p v-else>
            Select a template first
        </p>
    </div>
</template>

<script>
import {api_mixin} from "@/shared/mixins/api_mixin";
import {layout_mixin} from "@/shared/mixins/layout";
import {dict_mixin} from "@/shared/mixins/dict_mixin";
import {mapGetters} from "vuex";
import InputMain from "@/shared/components/dialogs/input_main.vue";

export default {
    name: "email_template",
    components: {InputMain},
    mixins: [api_mixin, layout_mixin, dict_mixin],
    props: {
        value: {
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false
        },
        dotted_path: {
            type: String
        },
        field: {
            type: Object
        },
    },
    data() {
        return {
            internal_value: {}
        }
    },
    watch: {
        value(val) {

        },
        internal_value(val) {
            this.$emit("input", val)
        },
    },
    computed: {
        ...mapGetters({current_item: "dialog/current_item", current_mode: "dialog/current_mode"})
    }
}
</script>

<style scoped>

</style>