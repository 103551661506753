<template>
    <div v-if="!internal_loading">
        {{ get_translation(internal_section.name) }}
        <v-col v-for="(setting, index) in internal_section.settings"
               v-bind:key="index">
            <v-row>
                <ThemeSettingItem
                    v-model="internal_value[setting.id]"
                    :name="setting.label"
                    :label="setting.label"
                    :setting="setting"
                    :type="setting.type"
                    :theme_locales="theme_locales"
                />
            </v-row>
        </v-col>
    </div>
</template>

<script>
import {api_mixin} from "@/shared/mixins/api_mixin";
import ThemeSettingItem from "@/views/components/theme/settings/theme_setting_item.vue";
import {layout_mixin} from "@/shared/mixins/layout";
import {dict_mixin} from "@/shared/mixins/dict_mixin";

export default {
    name: "ThemeTemplateSection",
    components: {ThemeSettingItem},
    mixins: [api_mixin, layout_mixin, dict_mixin],
    props: {
        value: {
            type: Array,
        },
        theme: {
            type: Object,
            required: true
        },
        section_id: {
            type: String,
            required: true
        },
        theme_locales: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            internal_section: null,
            internal_value: {}
        }
    },
    methods: {
        async load_data() {
            this.internal_section = await this.api_get_items({
                resource: "theme_sections",
                lookup: {
                    _id: this.section_id,
                    theme: this.theme["_id"]
                },
                first: true
            })
            this.internal_loading = false
        },
        get_translation(val) {
            let path = `data.${val.substring(2)}`
            let translation = this.deep_get(this.theme_locales, path)
            if (translation) {
                return translation
            }
            return val
        },
        convert_to_dict(value) {
            let tmp = {}
            for (let setting of value) {
                if (!setting.value) {
                    continue
                }
                tmp[setting.name] = setting.value
            }
            return tmp
        },
    },
    watch: {
        internal_value: {
            handler(val) {
                let tmp = []
                let value_dict = this.convert_to_dict(this.value)
                let should_emit = false
                for (let setting in val) {
                    if (val[setting]) {
                        tmp.push({name: setting, value: val[setting]})
                    }
                    if (val[setting] !== value_dict[setting]) {
                        should_emit = true
                    }
                }
                if (should_emit) {
                    this.$emit("input", tmp)
                }
            },
            deep: true
        },
        value: {
            async handler(val, old_val) {
                if (val !== old_val) {
                    this.internal_value = this.convert_to_dict(val)
                }
            },
            deep: true
        },
        async section_id(val, old_val) {
            if (val && val !== old_val) {

                await this.load_data()
            }
        }
    },

    async created() {
        this.internal_value = this.convert_to_dict(this.value)
        await this.load_data()
    }
}
</script>

<style scoped>

</style>