export let company_routes = [
    {
        path: '/c',
        name: 'Company',
        component: () => import(/* webpackChunkName: "Company" */ '@/views/Company.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/c/:company_id',
        name: 'EditCompany',
        component: () => import(/* webpackChunkName: "EditCompany" */ '@/views/EditCompany.vue'),
        meta: {requiresAuth: true},
        props: true
    },
    {
        path: '/c/:company_id/:app_id',
        name: 'EditApp',
        component: () => import(/* webpackChunkName: "EditApp" */ '@/views/EditApp.vue'),
        meta: {requiresAuth: true},
        props: true
    },
]