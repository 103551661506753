import FormExternal from "@/views/components/form/external.vue";
import FormCustomer from "@/views/components/form/customers/customers.vue";
import ModifyInventory from "@/views/components/form/modify_inventory.vue";


export let form_routes = [
    {
        path: "/f/dashboard/:company_id/:app_id",
        name: "FormApp",
        component: () => import(/* webpackChunkName: "FormApp" */ '@/views/components/form/form_main.vue'),
        meta: {requiresAuth: true},
        props: true,
    },
    {
        path: "/f/bookings/:company_id/:app_id",
        name: "FormBookings",
        component: () => import(/* webpackChunkName: "FormBookings" */ '@/views/components/form/view_bookings.vue'),
        meta: {requiresAuth: true},
        props: true,
    },
    {
        path: "/f/settings/:company_id/:app_id/:tab?",
        name: "FormSettings",
        component: () => import(/* webpackChunkName: "FormSettings" */ '@/views/components/form/settings'),
        meta: {requiresAuth: true},
        props: true,
    },
    {
        path: "/f/settings2/:company_id/:app_id/:tab?",
        name: "FormSettings2",
        component: () => import(/* webpackChunkName: "FormSettings2" */ '@/views/components/form/settings2'),
        meta: {requiresAuth: true},
        props: true,
    },
    {
        path: "/f/modify_booking/:company_id/:app_id/:form_id",
        name: "FormCancelBooking",
        component: () => import(/* webpackChunkName: "CancelBooking" */ '@/views/components/form/customer_modify_booking.vue'),
        props: true,
    },
    {
        path: "/f/external/:company_id/:app_id",
        name: "FormExternal",
        component: FormExternal,
        meta: {disable_navbar: true},
        props: true,
    },
    {
        path: "/f/external_with_auth/:company_id/:app_id",
        name: "FormExternalWithAuth",
        component: FormExternal,
        meta: {disable_navbar: true},
        props: route => ({auth: true, company_id: route.params.company_id, app_id: route.params.app_id})
    },
    {
        path: "/f/customer/:company_id/:app_id",
        name: "FormCustomer",
        component: FormCustomer,
        props: true,
    },
    {
        path: "/f/inventory/:company_id/:app_id",
        name: "FormInventory",
        component: ModifyInventory,
        props: true,
    },
]