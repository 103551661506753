import Home from "@/views/Home.vue";

import RequestAccess from "@/views/RequestCreate.vue";
import oauth from "@/shared/components/Oauth.vue";


export let other_routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },

    {
        path: "/booking/:company_id/:app_id/:year/:month/:day",
        name: 'BookingApp',
        component: () => import(/* webpackChunkName: "BookingApp" */ '@/views/Booking.vue'),
        meta: {requiresAuth: true},
        props: true
    },
    {
        path: "/h/:company_id/:app_id/",
        name: 'HotelApp',
        component: () => import(/* webpackChunkName: "HotelApp" */ '@/views/components/hotel_booking/hotel.vue'),
        meta: {requiresAuth: true},
        props: true,
    },
    {
        path: "/p/:company_id/:app_id",
        name: "PickupApp",
        component: () => import(/* webpackChunkName: "PickupApp" */ '@/views/components/pickup/pickup.vue'),
        meta: {requiresAuth: true},
        props: true,
    },
    {
        path: "/create",
        name: "RequestAccess",
        component: RequestAccess
    },
    {
        path: "/login/activate/:token/:refresh_token",
        name: "Activate",
        component: oauth,
        props: true
    },
    {
        path: "/view/:resource",
        name: "View",
        component: () => import(/* webpackChunkName: "View" */ '@/views/View.vue'),
        props: true,
        meta: {requiresAdmin: true}
    },

    {
        path: "/privacy",
        name: "Privacy",
        component: () => import(/* webpackChunkName: "View" */ '@/shared/components/layout/privacy-policy.vue'),
    },
    {
        path: "/shopify_link_account",
        name: "ShopifyLinkAccount",
        component: () => import(/* webpackChunkName: "View" */ '@/views/ShopifyLinkAccount.vue'),
    },
    {
        path: "/shopify",
        name: "ShopifyApproved",
        component: () => import(/* webpackChunkName: "View" */ '@/views/Shopify.vue'),
    },

]