<template>
    <div>
        <div>
            <v-dialog v-model="show_dialog" v-if="show_dialog" width="unset" overlay-opacity="0">
                <v-card>
                    <v-card-title>
                        {{ translate(action) }}
                    </v-card-title>

                    <v-card-text>
                        <slot name="default" :item="internal_value" :domain_setting="action_domain_setting">
                            <input-main
                                v-if="action_domain_setting"
                                v-model="internal_value"
                                :domain_settings="action_domain_setting"
                                :locked_data="locked_data"
                                :disabled="false"
                            />
                        </slot>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="show_dialog = false">
                            {{ translate('cancel', true) }}
                        </v-btn>
                        <v-btn @click="save_data" class="primary" :loading="post_loading">
                            {{ translate(action_domain_setting.action_locale, true) }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-tooltip bottom :disabled="!action_domain_setting?.icon">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        @click.stop="show_dialog = true"
                        :icon="!!action_domain_setting?.icon"
                        :small="small"
                        :class="{'primary':!action_domain_setting?.icon}"
                        class="mb-2"
                        v-bind="attrs"
                        v-on="on"
                        :loading="post_loading"
                    >
                        <v-icon v-if="action_domain_setting?.icon" :small="small">
                            {{ action_domain_setting?.icon }}
                        </v-icon>
                        <template v-else>
                            {{ translate(action) }}
                        </template>
                    </v-btn>
                </template>
                <span> {{ translate(action) }}</span>
            </v-tooltip>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import InputMain from "@/shared/components/dialogs/input_main.vue";
import {dict_mixin} from "@/shared/mixins/dict_mixin";
import {layout_mixin} from "@/shared/mixins/layout";
import {api_mixin} from "@/shared/mixins/api_mixin";
import {dialog_mixin} from "@/shared/mixins/dialog_mixin";

export default {
    name: "inde-resource-action",
    mixins: [dict_mixin, layout_mixin, api_mixin, dialog_mixin],
    components: {InputMain},
    props: {
        resource: {
            type: String,
            required: true
        },
        action: {
            type: String,
            required: true
        },
        item: {
            type: Object,
            required: true
        },
        locked_data_fields: {
            type: Array,
            default() {
                return []
            }
        },
        default_data_fields: {
            type: Array,
            default() {
                return []
            }
        },
        small: {
            type: Boolean,
            default: false
        },
        refresh_resources: {
            type: Array,
            default() {
                return []
            }
        },
        disable_reload_item: {
            type: Boolean,
            default: false
        },
        on_reload: {
            type: Function,
            default: null
        }
    },
    watch: {
        internal_value: {
            handler(val) {
                // resource action
                this.$emit("input", val)
            },
            deep: true
        },
    },
    data() {
        return {
            internal_value: {},
            show_dialog: false,
            post_loading: false
        }
    },
    methods: {
        ...mapActions({
            set_resource: "dialog/set_resource",
            reload_item: "dialog/reload_item",
            change_refresh_resource: "dialog/change_refresh_resource"
        }),
        async save_data() {
            for (let key of this.locked_data_fields) {
                this.internal_value[key] = this.item[key]
            }
            this.post_loading = true
            let res = await this.api_post(
                {
                    resource: `${this.resource}/${this.action}`,
                    data: this.internal_value,
                    remove_id: false
                }
            )
            this.post_loading = false
            console.log(res)
            this.toast_results(res, this.translate(`${this.resource}_${this.action}_updated`))
            if (!this.disable_reload_item) {
                await this.reload_item({resource: this.resource, resource_id: this.item._id})
            } else if (this.on_reload != null) (
                this.on_reload()
            )
            await this.change_refresh_resource({resource: this.resource, value: true})
            for (let resource of this.refresh_resources) {
                await this.change_refresh_resource({resource: resource, value: true})
            }
            this.show_dialog = false
        },
    },
    computed: {
        ...mapGetters({
            domain_settings: "dialog/domain_settings"
        }),
        action_domain_setting() {
            return this.deep_get(this.domain_settings, `${this.resource}.actions.${this.action}`)
        },
        locked_data() {
            let tmp = {}
            for (let key of this.locked_data_fields) {
                tmp[key] = this.item[key]
            }
            return tmp
        }
    },
    async created() {
        await this.set_resource(this.resource)
        if (!this.deep_get(this.domain_settings, `${this.resource}.actions.${this.action}`)) {
            throw `Missing schema for action ${this.action} resource ${this.resource}}`
        }
        if (!this.action_domain_setting?.icon) {
            throw `Missing icon for action ${this.action} resource ${this.resource}`
        }
        for (let key of this.default_data_fields) {
            this.internal_value[key] = this.filter_data(this.item, this.action_domain_setting, key)

        }
    }
}
</script>

<style scoped>

</style>