import {DateTime} from "@/shared/helpers/date_helper";


export class BookingDay {
    /**
     * @param index {number}
     * @param date {DateTime}
     * @param bookings {Array} Book
     */

    constructor({index, date}) {
        this.index = index
        this.date = new DateTime(date)
    }
}

export class Blocks {
    constructor({date_from, date_to}) {
        this.date_from = date_from
        this.date_to = date_to
        this.selected = false
    }


}

export class Book {
    constructor({date_from, date_to, blocked, full_name, user, type, _id = null, _etag = null, reason = null}) {
        this._id = _id
        this._etag = _etag
        this.type = type
        this.date_from = new DateTime(date_from)
        this.date_to = new DateTime(date_to)
        this.blocked = blocked
        this.full_name = full_name
        this.user = user
        this.selected = false
        this.reason = reason
    }


}
