import ThemeMain from "@/views/components/theme/theme_main.vue";
import ThemeEditor from "@/views/components/theme/theme_editor.vue";
import View from "@/views/View.vue";
import RenderRaw from "@/views/components/theme/render_raw.vue";

export let theme_routes = [
    {
        path: "/t/theme/:company_id/:app_id",
        name: "ThemeApp",
        component: ThemeMain,
        props: true,
        meta: {requiresAuth: true},
    },
    {
        path: '/t/editor/:theme_id/:tab?/:sub_tab?',
        name: 'theme_editor',
        component: ThemeEditor,
        props: ({params}) => {
            let tab = params.tab ? params.tab : "themes"
            let sub_tab = params.sub_tab ? params.sub_tab : "site"
            return {
                theme_id: params.theme_id,
                tab: tab,
                sub_tab: sub_tab
            }
        },
        meta: {requiresAuth: true},
    },
    {
        path: '/t/global',
        component: View,
        props: {
            resource: "theme_global_assets"
        },
        meta: {requiresAuth: true},

    },
    {
        path: '/t/render_raw',
        component: RenderRaw,
        meta: {requiresAuth: true},
    },
]