<template>
    <div>
        <AppSettings
            v-if="edit_app_settings"
            v-model="edit_app_settings"
            :app_id="app._id"
            :company_id="app.company"
        />
        <v-card class="item py-10">
            <v-card-title class="white--text">
                {{ app.name }}
            </v-card-title>
            <v-card-subtitle style="text-align: left" class="white--text">
                {{ app.company_name }}
                <span v-if="edit">
                    {{ app.type }}
                </span>
            </v-card-subtitle>
            <v-card-text>

            </v-card-text>
            <v-card-actions>
                <v-row justify="center">
                    <template v-if="edit">
                        <div v-if="deleting">
                            <v-row align="center">
                                <p> Delete app? </p>
                            </v-row>
                            <v-row v-if="deleting">
                                <v-col>
                                    <v-btn @click="del_app(app)">Yes</v-btn>
                                </v-col>
                                <v-col>
                                    <v-btn @click="deleting = false">No</v-btn>
                                </v-col>
                            </v-row>
                        </div>
                        <v-col v-else>
                            <v-btn @click="deleting=true">
                                <v-icon>
                                    mdi-delete
                                </v-icon>
                            </v-btn>
                            <v-btn
                                v-if="['hotel', 'booking'].includes(app.type)"
                                :to="{name: 'EditApp', params: {company_id: app.company,app_id: app._id}}"
                            >
                                <v-icon>
                                    mdi-pencil
                                </v-icon>
                            </v-btn>
                            <v-btn :to="app_url(app)">
                                <v-icon>
                                    mdi-link
                                </v-icon>
                            </v-btn>
                            <v-btn @click="edit_app_settings= !!!edit_app_settings">
                                <v-icon>
                                    mdi-cog
                                </v-icon>
                            </v-btn>
                            <v-btn v-if="app.type === 'syncogs'" @click="go_to_syncogs">
                                syncog
                            </v-btn>

                        </v-col>
                    </template>
                    <v-col v-else>
                        <v-btn :to="app_url(app)">
                            <v-icon>
                                mdi-link
                            </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import {company_app_mixin} from "@/mixins/company_app_mixin";
import {mapActions} from "vuex";
import {layout_mixin} from "@/shared/mixins/layout";
import {api_mixin} from "@/shared/mixins/api_mixin";
import AppSettings from "@/views/components/app/app_settings.vue";

export default {
    name: "App",
    emits: ["refresh"],
    components: {AppSettings},
    mixins: [company_app_mixin, api_mixin, layout_mixin],
    props: {
        app: {type: Object, required: true},
        edit: {type: Boolean, default: false}
    },
    data: function () {
        return {
            deleting: false,
            edit_app_settings: false,
        }
    },
    methods: {
        ...mapActions({change_refresh_resource: "dialog/change_refresh_resource"}),
        async del_app(app) {
            let del_app_res = await this.api_delete({
                    resource: "apps",
                    item: app
                }
            )
            this.toast_results(del_app_res, "deleted_app")
            this.$emit("refresh", true)
        },
        async go_to_syncogs() {
            let app_url = this.app_url(this.app)
            console.log(app_url)

            let response = this.$router.push(app_url)
            console.log(response)
            let tmp = await response

        }
    },
    async created() {
    }
}
</script>
<style scoped>


.item {
    background: linear-gradient(120deg, #425077 0%, #a75721 100%);
}


</style>