import {dict_mixin} from "@/shared/mixins/dict_mixin";
import {api_mixin} from "@/shared/mixins/api_mixin";

export let navigation_store = {
    namespaced: true,
    state() {
        return {
            navigation_open: false,
            navigation_mini: false,
            links: {
                name: "Change Space",
                locale: "change_space",
                url: "/c",
            },
            current_apps: [],
            current_company: null,
            current_rights: -1
        }
    },
    mutations: {
        set_property(state, {dotted_path, value, copy = false}) {
            if (copy) {
                value = {...value}
            }
            dict_mixin.methods.deep_set(state, dotted_path, value)
        },
    },
    actions: {
        async load_current_company({getters, commit, dispatch, rootGetters}, company_id = null) {
            let current_user = rootGetters["auth/current_user"]
            if (current_user !== null) {
                let current_company = null
                let user_settings = rootGetters["auth/user_settings"]
                if (user_settings === null) {
                    console.log("user settings is null")
                    return
                }
                if (company_id == null && user_settings["current_company"] === null) {
                    console.log("Current company is none")
                    current_company = await api_mixin.methods.api_get_items({
                            resource: "companies",
                            lookup: {},
                            first: true
                        }
                    )
                    if (current_company) {
                        company_id = current_company["_id"]
                    } else {
                        console.log("No current company found")
                        return
                    }
                }
                if (company_id) {
                    await dispatch("auth/update_user_settings", {"current_company": company_id}, {root: true})
                }

                if (!!rootGetters["auth/user_settings"]["current_company"]) {
                    let current_apps = await api_mixin.methods.api_get_items(
                        {
                            resource: "apps",
                            lookup: {
                                "company": user_settings["current_company"]
                            }
                        }
                    )
                    if (current_company === null) {
                        current_company = await api_mixin.methods.api_get_items(
                            {
                                resource: "companies",
                                lookup: {
                                    "_id": user_settings["current_company"]
                                },
                                first: true
                            }
                        )
                        company_id = user_settings["current_company"]
                    }
                    let current_rights = await api_mixin.methods.api_get_items({
                            resource: "company_rights",
                            lookup: {
                                company: company_id,
                                user: current_user._id
                            },
                            first: true
                        }
                    )
                    commit("set_property", {dotted_path: "current_apps", value: current_apps})
                    if (current_company !== undefined) {
                        commit("set_property", {dotted_path: "current_rights", value: current_rights["rights"]})
                        commit("set_property", {dotted_path: "current_company", value: current_company})
                    }
                }
            } else {
                commit("set_property", {dotted_path: "current_apps", value: []})
                commit("set_property", {dotted_path: "current_company", value: null})
                commit("set_property", {dotted_path: "current_rights", value: -1})
            }

        },
        change_navigation_open({commit}, {open = null, mini = null}) {
            // console.log("change navigation open ", {open, mini})
            if (open !== null) {
                commit("set_property", {dotted_path: "navigation_open", value: open})
            }
            if (mini !== null) {
                commit("set_property", {dotted_path: "navigation_mini", value: mini})
            }
        }

    },
    getters: {
        current_company(state) {
            return state.current_company
        },
        current_rights(state) {
            return state.current_rights
        },
        current_apps(state) {
            return state.current_apps
        },
        navigation_open(state) {
            return state.navigation_open
        },
        navigation_mini(state) {
            return state.navigation_mini
        }
    }
}