<template>
    <div>
        <v-card min-height="30vh" flat class="inde_flex_container">
            <v-card-title class="inde_flex_header">
                <v-row>
                    <v-col :align="'left'" cols="2">
                        <v-btn
                            color="blue darken-1"
                            @click="close_item_internal"
                            icon
                        >
                            <v-icon>
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </v-col>
                    <v-col>
                        <v-row style="float: right" dense class="mr-auto">
                            <AddExpectedDelivery
                                :form="internal_value"
                                :icon="icon"
                                :small="small"
                            />
                            <AddInternalNote
                                :form="internal_value"
                                :icon="icon"
                                :small="small"
                            />
                            <EmailDistribution
                                :form="internal_value"
                                :icon="icon"
                                hide_badge
                            />
                            <CustomerView
                                v-if="internal_value?.customer?.customer"
                                :form="internal_value"
                                :icon="icon"
                                :small="small"
                            />
                            <PrintablePage
                                :form="internal_value"
                                :icon="icon"
                                :small="small"
                            />
                            <IndeResourceAction
                                resource="forms"
                                action="modify_booking"
                                :item="internal_value"
                                :icon="icon"
                                :small="small"
                                :default_data_fields="['services', 'products']"
                                :locked_data_fields="['_etag', '_id']"
                                :refresh_resources="['form_logs']"
                            />
                        </v-row>

                    </v-col>
                </v-row>

            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col col="12" sm="6" md="4">
                        <v-list dense>
                            <v-list-item
                                v-for="field in customer_fields_computed(internal_value)"
                                v-bind:key="field.path">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ translate(field.locale) }}
                                        <template v-if="field.format_date">
                                            {{ format_date(deep_get(internal_value, field.path)) }}
                                        </template>
                                        <template v-else>
                                            {{ deep_get(internal_value, field.path) }}
                                        </template>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                        <v-list v-if="Array.isArray(internal_value?.text_fields)" dense>
                            <v-list-item v-for="item in internal_value.text_fields" :key="item.name" class="ml-0 pl-0">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ item?.locale?.[$i18n.locale] }}
                                    </v-list-item-title>
                                    {{ item["value"] }}
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>

                    </v-col>
                    <v-col col="12" sm="6" md="3">
                        <v-col
                            v-if="Array.isArray(internal_value?.services) && internal_value.services.length > 0"
                            cols="12"
                        >
                            Services
                            <v-list v-if="Array.isArray(internal_value?.services)" dense>
                                <v-list-item v-for="item in internal_value.services" :key="item.service"
                                             class="ml-0 pl-0">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ item.locale?.[$i18n.locale] }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ item.amount }} SEK</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-col>
                        <v-col
                            v-if="Array.isArray(internal_value?.products) && internal_value.products.length > 0"
                            col="12">
                            Products
                            <v-list v-if="Array.isArray(internal_value?.products)" dense>
                                <v-list-item
                                    v-for="item in internal_value.products"
                                    :key="item.product"
                                    class="ml-0 pl-0"
                                >
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ item.number_of_items }} x {{ item?.locale?.[$i18n.locale] }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>{{ item.amount }} SEK</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-col>
                        <v-col cols="12">
                            Total {{ internal_value.total }} SEK
                        </v-col>
                    </v-col>

                    <v-col cols="12" sm="6" md="5" align="center">
                        <status-timeline :form="internal_value"/>
                    </v-col>
                </v-row>
                <v-row>
                    <spacing :cols="8">
                        <Activity :form="internal_value"></Activity>
                    </spacing>
                </v-row>

            </v-card-text>
            <v-card-actions class="mb-5">
                <v-row justify="center" style="width: 100%">
                    <v-btn
                        color="blue darken-1"
                        @click="close_item_internal"
                        icon
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                    <AddExpectedDelivery
                        :form="internal_value"
                        :icon="icon"
                    />
                    <AddInternalNote
                        :form="internal_value"
                        :icon="icon"
                        :small="small"
                    />
                    <EmailDistribution
                        :form="internal_value"
                        :icon="icon"
                    />
                    <v-badge
                        bordered
                        :value="count !== null"
                        :offset-x="30"
                    >
                        <template #badge>
                            {{ count }}
                        </template>
                        <CustomerView
                            v-if="internal_value?.customer?.customer"
                            :form="internal_value"
                            :icon="icon"
                        />
                    </v-badge>

                    <PrintablePage
                        :form="internal_value"
                        :icon="icon"
                    />
                    <IndeResourceAction
                        resource="forms"
                        action="modify_booking"
                        :item="internal_value"
                        :icon="icon"
                        :default_data_fields="['services', 'products']"
                        :locked_data_fields="['_etag', '_id']"
                        :refresh_resources="['form_logs']"
                    />
                </v-row>
            </v-card-actions>
        </v-card>
    </div>
</template>
<script>


import {layout_mixin} from "@/shared/mixins/layout";
import {mapActions, mapGetters} from "vuex";
import {api_mixin} from "@/shared/mixins/api_mixin";
import {dialog_mixin} from "@/shared/mixins/dialog_mixin";
import {dict_mixin} from "@/shared/mixins/dict_mixin";
import {format_date} from "@/shared/helpers/date_helper";

import AddExpectedDelivery from "@/views/components/form/view_booking/expected_delivery.vue";
import AddInternalNote from "@/views/components/form/view_booking/add_note.vue";
import IndeResourceAction from "@/shared/components/view/inde_resource_action.vue";
import Activity from "@/views/components/form/view_booking/activites/activity.vue";
import Spacing from "@/shared/components/helper/Spacing.vue";
import EmailDistribution from "@/views/components/form/view_booking/email_distribution.vue";
import StatusTimeline from "@/views/components/form/view_booking/status_timeline.vue";

export default {
    name: 'Forms',
    components: {
        StatusTimeline,
        EmailDistribution,
        Spacing,
        Activity,
        IndeResourceAction,
        AddInternalNote,
        AddExpectedDelivery,
        CustomerView: () => import("@/views/components/form/view_booking/customer.vue"),
        PrintablePage: () => import("@/views/components/form/view_booking/printable_page.vue"),

    },
    mixins: [layout_mixin, api_mixin, dialog_mixin, dict_mixin],
    props: {
        value: {
            required: true,
            type: [Object, Array]
        },
        list: {
            type: Boolean,
            default: false
        },
        dotted_path: {
            type: String
        },
        dialog: {
            type: Boolean,
            default: true
        }

    },
    data() {
        return {
            count: null,
            customer_fields: [
                {path: "reference_number", locale: "reference_number"},
                {path: "customer.name", locale: "name"},
                {path: "customer.email", locale: "email"},
                {path: "customer.phone_number", locale: "phone_number"},
                {path: "customer.brand", locale: "brand"},
                {path: "customer.model", locale: "model"},
                {path: "expected_delivery.date", locale: "expected_delivery", format_date: true},
                {path: "expected_delivery.user_name", locale: "service_technician"},
                {path: "_created", locale: "booking_date", format_date: true},
                // {path: "_etag", locale: "etag"}
            ],
            internal_value: undefined,
            domain_settings: null,
            email_dist_per_id: {},
        }
    },

    watch: {
        value(val) {
            this.internal_value = val
        },
        internal_value: {
            handler(val) {
                this.$emit("input", val)
            },
            deep: true
        },

    },
    methods: {
        format_date,
        ...mapActions({
                change_tab: "dialog/change_tab",
                open_item: "dialog/open_item",
                set_resource: "dialog/set_resource",
                close_item: "dialog/close_item",

            }
        ),
        close_item_internal() {
            if (this.dialog) {
                this.close_item()
            } else {
                this.$emit("close", true)
            }
        },
        get_status_color(status) {
            let current_status = this.internal_value.status
            let index_of_current_status = this.domain_settings.schema.status.allowed.indexOf(current_status)
            let index_of_status = this.domain_settings.schema.status.allowed.indexOf(status)
            return index_of_current_status < index_of_status ? "white" : "green"
        },
        customer_fields_computed(item) {
            return this.customer_fields.filter(x => this.deep_get(item, x.path) !== null)
        },
        async get_count() {
            let customer_id = this.deep_get(this.value, "customer.customer")
            if (!customer_id) {
                return
            }
            let response = await this.api_post(
                {
                    resource: "forms/count_forms",
                    data: {
                        app: this.value["app"],
                        customer: this.value["customer"]["customer"],
                    }
                }
            )
            if (response["_status"] === "OK") {
                this.count = response["count"]
            }
        }
    },
    computed: {
        ...mapGetters({
            current_item: "dialog/current_item",
            current_mode: "dialog/current_mode",
            current_tab: "dialog/current_tab",
            dialog_settings_vuex: "dialog/domain_settings"
        }),
        type() {
            return this.field.schema.type
        },
        tab: {
            get() {
                return this.current_tab
            },
            set(val) {
                if (val !== this.tab) {
                    this.change_tab(val)
                }
            }
        },
        icon() {
            return true
        },
        small() {
            return this.$vuetify.breakpoint.mdAndDown
        }

    },
    async created() {

        this.internal_loading = true

        this.domain_settings = this.dialog_settings_vuex.forms

        this.internal_value = this.value
        await this.get_count()
        this.internal_loading = false
        await this.set_resource("email_distributions")


    }
}
</script>
