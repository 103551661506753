<template>
    <div>
        <v-card>
            <v-card-title>
                Syncogs Settings
            </v-card-title>
            <v-card-text>
                <div v-if="token_exists.discogs">
                    <p> Discogs is connected</p>
                    <v-btn @click="sync_now">
                        Sync now
                    </v-btn>
                </div>
                <div v-else>
                    <p> Discogs is not connected yet please login</p>
                </div>
                <div v-if="token_exists.shopify">
                    <p> Shopify is connected</p>
                </div>
                <div v-else>
                    <p> Shopify is not connected</p>
                    <p> Please install the app in the shopify app store</p>
                </div>
                <div v-if="app">
                    <v-checkbox v-model="sync_enabled"></v-checkbox>

                    <p v-if="last_synced">last_synced: {{ last_synced }}</p>
                    <p v-else>Not synced yet</p>
                </div>
                <v-btn
                    :to="listing_route"
                >
                    Downloaded listings
                </v-btn>

            </v-card-text>
            <v-card-actions>
                <v-btn @click="login_discogs">
                    log in discogs
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>


<script>
import {mapActions, mapGetters} from "vuex";
import {api_mixin} from "@/shared/mixins/api_mixin";
import {layout_mixin} from "@/shared/mixins/layout";

export default {
    name: "SyncogsSettings",
    mixins: [api_mixin, layout_mixin],
    props: {
        company_id: {
            type: String,
            required: true
        },
        app_id: {
            type: String,
            required: true
        },

    },
    data() {
        return {
            app: null,
            token_exists: {},
            sync_enabled: null,
            last_synced: null
        }
    },
    watch: {
        async sync_enabled(val, old_val) {
            if (old_val != null) {
                let response = await this.api_patch({
                        resource: "apps",
                        data: {
                            settings: {
                                syncogs: {
                                    sync_enabled: val
                                }
                            },
                            _etag: this.app._etag,
                            _id: this.app._id
                        }
                    }
                )
                this.toast_results(response, val ? "Sync is enabled" : "Sync is disabled")
                if (response._status === 'OK') {
                    this.app._etag = response._etag
                }
            }
        }
    },
    computed: {
        ...mapGetters({rawToken: "auth/rawToken"}),
        listing_route() {
            return {
                name: "SyncogsListings",
                params: {
                    app_id: this.app_id,
                    company_id: this.company_id
                }
            }
        }
    },
    methods: {
        ...mapActions({send_to_login: "auth/send_to_login"}),
        async login_discogs() {
            let params = [this.app_id, this.rawToken]
            if (this.current_user.embedded) {
                let url = await this.send_to_login({id_type: "discogs", params, return_url: true})
                window.open(url)
            } else {
                await this.send_to_login({id_type: "discogs", params})
            }
        },
        async get_token_exists() {
            this.token_exists = await this.api_get({
                endpoint: `discogs/token_exists/${this.app_id}`,
                headers: {},
                auth: true,
                return_items: false,
                return_data: true
            })
        },
        async sync_now() {
            let response = await this.api_post({
                    resource: "discogs/sync_data",
                    data: {
                        app_id: this.app_id
                    }
                }
            )
            if (response.ok) {
                this.$toasted.success(`Found ${response.result} new listings`)
            } else {
                this.$toasted.error("Some thing wrong maybe credentials are missing?")
            }
        }

    },
    async created() {
        await this.get_token_exists()
        this.app = await this.api_get_items({
            resource: "apps",
            lookup: {_id: this.app_id},
            first: true
        })
        this.sync_enabled = !!this.app?.settings?.syncogs?.sync_enabled
        this.last_synced = this.app?.settings?.syncogs?.last_synced
    }
}
</script>

<style scoped>

</style>