<template>
    <div>
        <inde-dialog-layout>
            <template #main>
                <v-row>
                    <v-col cols="2">
                        <v-text-field v-model="search" :label="translate('search')"/>
                        <v-list dense>
                            <v-list-group
                                v-for="resource in Object.keys(internal_value)"
                                v-bind:key="resource"
                                v-model="selected_item[resource]"
                                @input="(val) => set_internal_action(resource, val)"
                                color="primary"
                            >
                                <template #activator>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title v-text="translate(resource)"></v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                                <v-list-item
                                    dense
                                    v-for="(item, i) in internal_value[resource]"
                                    :key="i"
                                    @click="() => open_file(resource, item['_id'])"
                                >
                                    <v-list-item-content>
                                        <v-list-item-title v-text="item.file_info.name"></v-list-item-title>
                                    </v-list-item-content>
                                    <v-btn icon @click="() => open_item({
                                         resource,
                                         resource_id: item._id,
                                         item: item,
                                        })">
                                        <v-icon>mdi-link</v-icon>
                                    </v-btn>
                                </v-list-item>
                            </v-list-group>
                        </v-list>
                    </v-col>
                    <v-col cols="10">
                        <div v-if="resource">
                            <monaco
                                v-model="internal_value[resource][resource_id].file.file"
                                @input="update_value"
                                v-if="resource_id"
                                :content_type="internal_value[resource][resource_id].file.content_type"
                            />
                            <v-btn @click="save_resource_internal">
                                {{ translate('save') }}
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </template>
        </inde-dialog-layout>
    </div>
</template>

<script>
import {dict_mixin} from "@/shared/mixins/dict_mixin";
import {api_mixin} from "@/shared/mixins/api_mixin";
import Monaco from "@/views/components/editor/monaco.vue";
import IndeDialogLayout from "@/shared/components/other/inde_dialog_layout.vue";
import {mapActions, mapGetters} from "vuex";
import {layout_mixin} from "@/shared/mixins/layout";

export default {
    name: "ThemeEditorPage",
    components: {IndeDialogLayout, Monaco},
    mixins: [dict_mixin, api_mixin, layout_mixin],
    props: {
        theme: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            theme_resources: ["theme_templates", "theme_sections", "theme_layouts"],
            internal_value: {},
            modified_files: {},
            history: [],
            resource: null,
            resource_id: null,
            selected_item: {},
            search: ""
        }
    },
    watch: {
        "theme.id": function (val) {
            if (val) {
                this.load_data()
            }

        },
        search(val) {
            this.debounce(this.load_data, 500)()
        }

    },
    methods: {
        ...mapActions({open_item: "dialog/open_item", set_resource: "dialog/set_resource"}),
        async load_data() {
            let lookup = {theme: this.theme["_id"]}
            if (this.search) {
                lookup["file.name"] = {
                    "$regex": this.search
                }
            }
            console.log(this.search, "lookup", lookup)
            for (let resource of this.theme_resources) {
                let items = await this.api_get_items({
                        resource: resource,
                        lookup,
                        return_per_id: true,
                    }
                )
                if (Object.keys(items)?.length) {
                    this.$set(this.internal_value, resource, items)
                }
                console.log(resource, items)
            }
        },
        add_modified_file(resource, resource_id) {
            if (!(resource in this.modified_files)) {
                this.modified_files[resource] = {}
            }
            this.modified_files[resource][resource_id] = true
        },
        remove_modified_file(resource, resource_id) {
            if (!this.deep_get(this.modified_files, `${resource}.${resource_id}`))
                if (!(resource in this.modified_files)) {
                    this.modified_files[resource] = {}
                }
            this.modified_files[resource][resource_id] = false
        },
        update_value() {
            let value = this.deep_get(this.internal_value, `${this.resource}.${this.resource_id}`)
            if (value) {
                let file = this.deep_get(value, "file.file")
            }
        },
        async open_file(resource, resource_id) {
            await this.get_media(this.internal_value[resource][resource_id]["file"])
            this.resource = resource
            this.resource_id = resource_id
            this.history.push({resource, resource_id})
        },
        async save_resource_internal() {
            await this.set_resource(this.resource)
            let response = await this.save_resource(
                this.internal_value,
                this.domain_settings,
                this.resource
            )
        },
        close_file(resource, resource_id) {
            let index = this.history.indexOf({resource, resource_id})
            if (index !== -1) {
                this.history.splice(index, 1);
            }
        },
        set_internal_action(resource, val) {
            if (this.search.length !== 0) {
                this.selected_item[resource] = val
            }
        },
        get_internal_active(resource) {
            if (this.search.length) {
                return true
            }
            return this.selected_item[resource]
        }
    },
    computed: {
        ...mapGetters({domain_settings: "dialog/domain_settings"})
    },
    async created() {
        await this.load_data()
    }
}
</script>


<style scoped>

</style>