<template>
    <div class="text-center">
        <v-list>
            <v-list-item
                v-for="(item, index) in items"
                :key="index"
            >
                <v-list-item-title
                    :align="'start'"
                    @click="click_row(item)">
                    <p class="cut-text">
                        {{ item.locale[$i18n.locale] }}
                        <span
                            class="text-subtitle-2"
                        >
<!--                            v-if="typeof internal_value[item['_id']] === 'number' && !!internal_value[item['_id']] >0"-->
                            {{ products_per_id[item.product].price }} Sek
                        </span>
                    </p>

                </v-list-item-title>
                <template v-if="typeof internal_value?.[item['_id']] === 'number' && internal_value?.[item['_id']] > 0">
                    <v-btn
                        icon
                        @click="() => minus(item)"
                        class="mb-2"
                    >
                        <v-icon>
                            mdi-minus
                        </v-icon>
                    </v-btn>
                    <v-text-field
                        class="input_text_center"
                        style="width: 40px"
                        dense
                        v-model.number="internal_value[item['_id']]"
                        type="number"
                    />
                    <v-btn
                        @click="() => plus(item)"
                        icon
                        class="mb-2 mr-4"
                    >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>

                </template>
                <v-slide-x-reverse-transition
                    mode="out-in"
                >
                    <v-icon
                        class="mb-2"
                        @click="() => click_item(item)"
                        :color="!!internal_value[item['_id']] ? 'error': 'success' "
                        v-text="!!internal_value[item['_id']] ? 'mdi-close' : 'mdi-plus'"
                    ></v-icon>
                </v-slide-x-reverse-transition>
            </v-list-item>
        </v-list>


    </div>
</template>

<script>
export default {
    name: "autocomplete-with-numbers",
    props: {
        items: {
            required: true
        },
        itemText: {
            type: String,
            required: true
        },
        itemValue: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        rules: {
            type: Array,
            default() {
                return []
            }
        },
        clearable: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        products_per_id: {
            type: Object,

        },
        layout: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            internal_value: {},
            search: "",
        }
    },
    computed: {},
    watch: {
        internal_value(val) {
            this.$emit("input", val)
        },
        value(val) {
            this.internal_value = val
        },
    },
    methods: {
        minus(item) {

            if (this.internal_value[item["_id"]] - 1 <= 0) {
                this.internal_value[item["_id"]] = 0
                this.$nextTick(() => {
                    delete this.internal_value[item["_id"]]
                })
            } else {
                this.internal_value[item["_id"]] -= 1
            }

        },
        plus(item) {
            console.log(this.internal_value[item['_id']])
            this.internal_value[item['_id']] += 1
        },
        click_item(item) {
            if (typeof this.internal_value[item["_id"]] === "number" && !!this.internal_value[item["_id"]] > 0) {
                this.$delete(this.internal_value, item["_id"])

            } else {
                console.log("num =1")
                this.$set(this.internal_value, item["_id"], 1)

            }
        },
        click_row(item) {
            if (typeof this.internal_value[item['_id']] !== 'number') {
                this.$set(this.internal_value, item["_id"], 1)
            } else {
                this.$delete(this.internal_value, item["_id"])
            }
        }
    },
    created() {
    }
}
</script>

<style scoped>
.input_text_center >>> input {
    text-align: center;
}

.cut-text {
    text-overflow: ellipsis;
    overflow: hidden;
}
</style>