<template>
    <inde-dialog-layout>
        <template #main>
            <div id="editorjs">

            </div>
            editor {{ editor }} <br>
            internal value {{ internal_value }}
        </template>
        <template #actions>
            <v-btn @click="save">
                {{ translate("save") }}
            </v-btn>
        </template>
    </inde-dialog-layout>
</template>

<script>
import {layout_mixin} from "@/shared/mixins/layout";
import EditorJS from "@editorjs/editorjs";
import IndeDialogLayout from "@/shared/components/other/inde_dialog_layout.vue";

export default {
    name: "editor",
    mixins: [layout_mixin],
    components: {IndeDialogLayout},
    data() {
        return {
            editor: null,
            internal_value: "",
            latest_event: null
        }
    },
    methods: {
        update_internal_value(api, event) {
            this.latest_event = event
        },
        async save() {
            this.internal_value = await this.editor.save()
        }
    },
    mounted() {
        this.editor = new EditorJS({
            /**
             * id of Element that should contain Editor instance
             */
            holder: 'editorjs',
            onChange: this.update_internal_value,
            placeholder: "placeholder",

        })
    },
    created() {
        console.log("editor", this.editor)
    }
}
</script>


<style scoped>

</style>
