<template>
    <div>

        <v-btn style="position: fixed; bottom: 0; left: 10%;" icon color="orange" large>
            <v-icon
                @click="() => change_navigation_open({open: !navigation_open , mini:false})"
            >
                mdi-hamburger
            </v-icon>

        </v-btn>
    </div>
</template>

<script>


import {layout_mixin} from "@/shared/mixins/layout";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "button_bar",
    mixins: [layout_mixin],
    computed: {
        ...mapGetters({
            current_user: "auth/current_user",
            navigation_open: "navigation/navigation_open"
        }),

    },
    methods: {
        ...mapActions({
            change_navigation_open: "navigation/change_navigation_open"
        })
    }
}
</script>

<style scoped>

</style>