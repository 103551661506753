<template>
    <v-dialog v-model="internal_dialog" v-if="loaded">
        <IndeDialogLayout>
            <template #main>
                <InputMain
                    v-model="app.settings[app.type]"
                    :dotted_path="`settings.${app.type}`"
                    :domain_settings="domain_settings"
                    is_card
                />
            </template>
            <template #actions>
                <v-card-actions class="inde_flex_footer">
                    <v-btn @click="save_app_settings">
                        {{ translate("save", true) }}
                    </v-btn>
                    <v-btn @click="internal_dialog = false">
                        {{ translate("cancel", true) }}
                    </v-btn>
                </v-card-actions>
            </template>
        </IndeDialogLayout>
    </v-dialog>
</template>

<script>
import InputMain from "@/shared/components/dialogs/input_main.vue";
import {api_mixin} from "@/shared/mixins/api_mixin";
import {layout_mixin} from "@/shared/mixins/layout";
import IndeDialogLayout from "@/shared/components/other/inde_dialog_layout.vue";
import {mapActions} from "vuex";

export default {
    name: "AppSettings",

    components: {IndeDialogLayout, InputMain},
    mixins: [api_mixin, layout_mixin],
    props: {
        value: {
            type: Boolean,
            required: true
        },
        app_id: {
            type: String,
            required: true
        },

    },
    data: function () {
        return {
            internal_dialog: false,
            domain_settings: null,
            app: {},
            loaded: false
        }
    },
    watch: {
        internal_dialog(val) {
            if (!val) {
                this.close_item()
                this.$emit("input", val)
            }
            this.internal_dialog = val

        }
    },
    methods: {
        ...mapActions({
            open_item: "dialog/open_item",
            close_item: "dialog/close_item"
        }),
        async save_app_settings() {
            let res = await this.api_put(
                {
                    resource: "apps",
                    data: this.app
                }
            )
            this.toast_results(res)
            await this.get_app()
            if (res)
                this.$emit("input", false)
        },
        async get_app() {
            let app = await this.api_get_items({
                    resource: "apps",
                    lookup: {
                        _id: this.app_id,
                    },
                    first: true
                }
            )
            if (!app) {
                throw "Could not find app"
            }
            if (!("settings" in app)) {
                app.settings = {}
            }
            if (!(app["type"]) in app.settings) {
                app.settings[app["type"]] = {}
            }
            this.app = app
        }
    },
    async created() {
        this.internal_dialog = this.value
        await this.get_app()

        let domain_settings = await this.get_schema("apps")
        this.domain_settings = domain_settings.schema["settings"].schema[this.app["type"]]
        await this.open_item({
            resource: "apps",
            resource_id: this.app_id,
            locked_data: {_id: this.app_id},
            mode: "edit",
            dialog_settings: {disable_dialog: true},
        })
        this.loaded = true


    }
}
</script>

<style scoped>

</style>