<template>
    <div>
        {{ translate("indehouse.dependable_options") }} <br>
        <InputAutoComplete
            v-model="internal_value.depends_on_field"
            resource="form_layouts"
            :label="translate('indehouse.depends_on_field', true)"
            :lookup="depends_on_field_lookup"
            :dotted_path="dotted_path"
            :disabled="disabled"
            item-text="name"
            item-value="_id"
        />
        <v-expansion-panels v-if="internal_value.depends_on_field" multiple>
            <v-expansion-panel
                v-for="(value, index) in internal_value.options"
                :key="index"
            >
                <v-expansion-panel-header>
                    <v-row>
                        <p class="mt-5">
                            <view_objectid v-model="value['equals']" resource="form_options"/>
                            <br>
                            =>
                            <view_objectid v-model="value['options']" resource="form_options"/>
                        </p>
                        <v-spacer/>
                        <v-btn
                            icon
                            @click="remove_item(value)"
                            class="mr-4"

                        >
                            <v-icon
                                v-if="current_mode!== 'view'"
                            >
                                mdi-delete
                            </v-icon>
                        </v-btn>
                    </v-row>
                    <template #actions>
                    </template>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                    <InputAutoComplete
                        v-model="value.equals"
                        :label="translate('indehouse.equals')"
                        :disabled="disabled"
                        item-value="_id"
                        item-text="name"
                        multiple
                        resource="form_options"
                        :lookup="{filter: internal_equals_options_in_lookup}"
                        :search_fields="search_fields"
                    />
                    <InputAutoComplete
                        v-model="value.options"
                        :label="translate('indehouse.option')"
                        :disabled="disabled"
                        :lookup="{filter: internal_equals_options_nin_lookup}"
                        :search_fields="search_fields"
                        item-value="_id"
                        item-text="name"
                        clearable
                        resource="form_options"
                        multiple
                    />

                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-btn @click="add = !add" class="mt-3" v-if="add_enabled">
            <v-icon>
                mdi-plus
            </v-icon>
        </v-btn>
        <v-card v-if="add">
            <v-card-text>
                <InputAutoComplete
                    v-model="internal_temp_value.equals"
                    :label="translate('indehouse.equals')"
                    resource="form_options"
                    :lookup="{filter: internal_equals_options_in_lookup}"
                    :disabled="disabled"
                    :search_fields="search_fields"
                    item-value="_id"
                    item-text="name"
                    multiple
                />
                <InputAutoComplete
                    v-model="internal_temp_value.options"
                    :label="translate('indehouse.option')"
                    :disabled="disabled"
                    :lookup="{filter: internal_equals_options_nin_lookup}"
                    :search_fields="search_fields"
                    item-value="_id"
                    item-text="name"
                    clearable
                    resource="form_options"
                    multiple
                />
            </v-card-text>
            <v-card-actions>
                <v-btn v-on:click="save">
                    {{ translate("common.save") }}
                </v-btn>
                <v-btn v-on:click="add = false">
                    {{ translate("common.cancel") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import {api_mixin} from "@/shared/mixins/api_mixin";
import {mapGetters} from "vuex";
import {layout_mixin} from "@/shared/mixins/layout";
import InputSelect from "@/shared/components/dialogs/input_select.vue";
import InputType from "@/shared/components/dialogs/input_type.vue";
import {dict_mixin} from "@/shared/mixins/dict_mixin";
import InputAutoComplete from "@/shared/components/dialogs/custom_components/input_auto_complete.vue";
import Loading from "@/shared/components/loading.vue";
import View_objectid from "@/shared/components/helper/view_objectid.vue";

export default {
    name: "SelectDependableOption",
    components: {View_objectid, Loading, InputAutoComplete, InputType, InputSelect},
    mixins: [api_mixin, layout_mixin, dict_mixin],
    props: {
        value: {
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false
        },
        dotted_path: {
            type: String
        },
        field: {
            type: Object
        },
    },
    data() {
        return {
            loading: false,
            internal_value: {},
            internal_options_per_id: {},
            internal_equals_options_in_lookup: {},
            internal_equals_options_nin_lookup: {},
            internal_temp_value: {},
            internal_layout: {},
            add: false,
            search_fields: ['name', 'locale.se', 'locale.en']
        }
    },
    watch: {
        value(val) {
            this.create_internal_value()
            this.create_internal_temp_value()
            this.create_internal_options_set_up()
        },
        internal_value(val) {
            this.$emit("input", val)
        },
        'internal_value.depends_on_field': {
            async handler(val) {
                await this.create_internal_options_set_up()
            }
        },

    },
    computed: {
        ...mapGetters({
            current_locked_data: "dialog/current_locked_data",
            current_mode: "dialog/current_mode",
            current_id: "dialog/current_id",
            current_resource: "dialog/current_resource"
        }),
        depends_on_field_lookup() {
            let lookup = {...this.field.schema.depends_on_field.data_relation.lookup}
            if (this.current_id) {
                this.deep_set(lookup, "filter._id.$ne", this.current_id)
            }
            return lookup
        },
        add_enabled() {
            if (this.current_mode !== 'view') {
                if (!this.add) {
                    if (this.internal_value?.depends_on_field) {
                        return true
                    }
                }
            }
            return false
        },

    },
    methods: {
        create_internal_value() {
            if (typeof this.value != 'object') {
                this.internal_value = {
                    depends_on_field: null,
                    options: []
                }
            } else {
                this.internal_value = this.value
                if (!("options" in this.internal_value)) {
                    this.internal_value.options = []
                }
            }
        },
        create_internal_temp_value() {
            if (this.field.is_data_type) {
                this.internal_temp_value = null
            } else {
                this.internal_temp_value = {}
            }
        },
        save() {
            this.internal_value.options.push(this.internal_temp_value)
            this.$emit("input", this.internal_value)
            this.add = false
            this.create_internal_temp_value()
        },
        remove_item(item) {
            let index = this.internal_value.options.indexOf(item)
            if (index > -1) { // only splice array when item is found
                this.internal_value.options.splice(index, 1); // 2nd parameter means remove one item only
            }
        },
        async create_internal_options_set_up() {
            if (!this.internal_value?.depends_on_field) {
                return
            }
            if (this.internal_layout?.["_id"] !== this.internal_value.depends_on_field) {
                this.internal_layout = await this.api_get_items(
                    {
                        resource: "form_layouts",
                        lookup: {"_id": this.internal_value.depends_on_field},
                        first: true
                    }
                )
            }
            if (this.internal_layout.dependable_options) {
                let dependable_options = this.internal_layout.dependable_options.options
                let ret2 = []
                for (let option of dependable_options) {
                    if (!option.options) {
                        continue
                    }
                    for (let option_id of option.options) {
                        if (!(option_id in ret2)) {
                            ret2.push(option_id)
                        }

                    }
                }
                this.internal_equals_options_in_lookup = {
                    _id: {
                        "$in": ret2
                    },
                    app: this.current_locked_data["app"]
                }
                this.internal_equals_options_nin_lookup = {
                    _id: {
                        "$nin": ret2
                    },
                    app: this.current_locked_data["app"]
                }

            } else if (this.internal_layout.options) {

                this.internal_equals_options_in_lookup = {
                    _id: {
                        "$in": this.internal_layout.options
                    },
                    app: this.current_locked_data["app"]
                }
                this.internal_equals_options_nin_lookup = {
                    _id: {
                        "$nin": this.internal_layout.options
                    },
                    app: this.current_locked_data["app"]
                }

            } else {
                console.log(`Missing options or dependable value for layout ${this.internal_layout.name}`)
            }
        },
    },
    async created() {
        this.internal_loading = true
        this.dialog_open = this.open_by_default


        this.create_internal_value()
        this.create_internal_temp_value()

        await this.create_internal_options_set_up()
        this.internal_loading = false
    }

}
</script>

<style scoped>

</style>