import Vue from 'vue'
import VueRouter from 'vue-router'
import {get_before_router} from "@/shared/mixins/router_helper";
import {form_routes} from "@/router/form";
import {company_routes} from "@/router/company_routes";
import {other_routes} from "@/router/other";
import {theme_routes} from "@/router/theme";
import {syncog_routes} from "@/router/syncog";

Vue.use(VueRouter)

const routes = [
    ...form_routes,
    ...company_routes,
    ...other_routes,
    ...theme_routes,
    ...syncog_routes,
    {
        path: '/:pathMatch*',
        name: 'PageNotFound',
        component: () => import(/*webpackChunkName "page_not_found"*/ '@/views/Error.vue'),
    },
]
const router = new VueRouter({
    mode: "history",
    routes
})
let before_router = get_before_router(router)
router.beforeEach(async (to, from, next) => {
        return await before_router(to, from, next)
    }
)

export default router
