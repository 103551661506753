<template>
    <div>
        <IndeDialogCard
            v-if="domain_settings.form_inventory_adjustment"
            :disabled="false"
            :is_card="true"
        />
    </div>
</template>
<script>
import IndeView from "@/shared/components/view/inde_view.vue";
import IndeDialog from "@/shared/components/dialogs/inde_dialog.vue";
import {mapActions, mapGetters} from "vuex";
import IndeDialogCard from "@/shared/components/dialogs/inde_dialog_card.vue";
import IndeDialogLayout from "@/shared/components/other/inde_dialog_layout.vue";
import {api_mixin} from "@/shared/mixins/api_mixin";
import {layout_mixin} from "@/shared/mixins/layout";
import InputMain from "@/shared/components/dialogs/input_main.vue";

export default {
    name: "modify_inventory",
    components: {InputMain, IndeDialogLayout, IndeDialogCard, IndeDialog, IndeView},
    mixins: [api_mixin, layout_mixin],
    props: {
        company_id: {
            type: String,
            required: true
        },
        app_id: {
            type: String,
            required: true
        }
    },
    data() {
        return {}
    },
    methods: {
        ...mapActions({set_resource: "dialog/set_resource", open_item: "dialog/open_item"})
    },
    computed: {
        ...mapGetters({domain_settings: "dialog/domain_settings"}),
        lookup() {
            return {
                company: this.company_id,
                app: this.app_id
            }
        }
    },
    async created() {
        await this.set_resource("form_inventory_adjustment")
        await this.open_item(
            {
                resource: "form_inventory_adjustment",
                mode: "create",
                locked_data: this.lookup,
                item: this.lookup,
                dialog_settings: {disable_dialog: true}
            }
        )
    }
}
</script>

<style scoped>

</style>