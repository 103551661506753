<template>
    <div>
        <v-card
            class="inde_flex_container"
            v-if="dialog"
        >
            <v-card-title class="inde_flex_header">
                <v-row>
                    <v-col align="left">
                        <v-btn
                            @click="close_item"
                            color="blue darken-1"
                            icon
                        >
                            <v-icon>
                                mdi-close
                            </v-icon>
                        </v-btn>
                        <v-btn
                            @click="() => change_dialog_location('default')"
                            color="blue darken-1"
                            v-if="current_location === 'fullscreen' && !is_card"
                            icon
                        >
                            <v-icon>
                                mdi-fullscreen-exit
                            </v-icon>
                        </v-btn>
                        <v-btn
                            @click="() => change_dialog_location('fullscreen')"
                            color="blue darken-1"
                            icon
                            v-else-if="!is_card"
                        >
                            <v-icon>
                                mdi-fullscreen
                            </v-icon>
                        </v-btn>

                        <v-btn
                            color="blue darken-1"
                            @click="change_mode('edit')"
                            icon
                            v-if="current_mode === 'view' & allowed.edit & !current_item?.hide_edit">
                            <v-icon>
                                mdi-pencil
                            </v-icon>
                        </v-btn>

                        <v-btn v-if="current_mode !== 'view'"
                               color="blue darken-1"
                               icon
                               v-on:click="() => internal_save(false)"
                        >
                            <v-icon v-if="current_item.mode === 'create'">
                                mdi-content-save-plus
                            </v-icon>
                            <v-icon v-else-if="current_item.mode === 'edit'">
                                mdi-content-save-edit-outline
                            </v-icon>
                            <p v-else>
                                Invalid mode {{ String(current_item?.mode) }}
                            </p>
                        </v-btn>
                    </v-col>

                    <v-col align="right">
                        <span class="headline">{{ label }} </span>
                    </v-col>
                </v-row>

            </v-card-title>
            <v-card-text class="inde_flex_content">
                <CustomComponent
                    v-if="!!internal_domain_settings.custom_component"
                    v-model="edited_item"
                    dotted_path=""
                    :domain_settings="internal_domain_settings"
                    :custom_component="internal_domain_settings.custom_component"
                    :disabled="disabled"
                />
                <InputMain
                    v-else
                    v-model="edited_item"
                    :domain_settings="internal_domain_settings"
                    :disabled="allowed[current_mode]"
                />

            </v-card-text>

            <v-card-actions class="inde_flex_footer">
                <v-btn
                    color="blue darken-1"
                    text
                    @click="close_item"
                >
                    Cancel
                </v-btn>
                <v-btn v-if="current_item.mode !== 'view'"
                       color="blue darken-1"
                       text
                       v-on:click="internal_save"
                >
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>

    </div>
</template>
<script>
import {layout_mixin} from "@/shared/mixins/layout";
import {mapActions, mapGetters} from "vuex";
import {api_mixin} from "@/shared/mixins/api_mixin";
import {dict_mixin} from "@/shared/mixins/dict_mixin";
import CustomComponent from "@/shared/components/dialogs/custom_components/index.vue";

export default {
    name: "IndeDialogCard",
    components: {
        CustomComponent,
        InputMain: () => import("./input_main")
    },
    mixins: [layout_mixin, api_mixin],
    props: {
        disabled: {
            type: Boolean,
            required: true
        },
        is_card: {
            type: Boolean,
            default: false
        },

    },
    data() {
        return {
            internal_value: undefined,
            loaded: false,
        }
    },
    computed: {
        ...mapGetters({
            current_resource: "dialog/current_resource",
            current_dialog_settings: "dialog/current_dialog_settings",
            domain_settings: "dialog/domain_settings",
            edited_item_vuex: "dialog/current_item",
            current_item: "dialog/current_item",
            current_mode: "dialog/current_mode",
            current_location: "dialog/current_location"

        }),
        dialog: {
            get() {
                return (!!this.current_resource && (!this.current_dialog_settings.disable_dialog || this.is_card))
            },
            set() {
            }
        },
        edited_item: {
            get() {
                return this.current_item.item
            },
            set(val) {
                this.change_item(val)
            }
        },
        label() {
            if (this.current_mode === "view") {
                return "View"
            }
            if (this.current_mode === "edit") {
                return "Edit"
            }
            return "New Item"
        },

        allowed() {
            let ret = {
                view: true,
            }
            if (this.hide_create_new) {
                ret["create"] = false
                ret["edit"] = false
                return ret
            }

            if (Array.isArray(this.domain_settings[this.current_resource].resource_methods)) {
                if (this.domain_settings[this.current_resource].resource_methods.includes('POST')) {
                    ret["create"] = this.disabled
                }
            } else {
                ret["create"] = false
            }
            if (Array.isArray(this.domain_settings[this.current_resource].item_methods)) {
                if (this.domain_settings[this.current_resource].item_methods.includes("PUT")) {
                    ret["edit"] = this.disabled
                }
            } else {
                ret["edit"] = false
            }
            return ret
        },
        internal_domain_settings() {
            if (this.current_dialog_settings.custom_domain_settings) {
                return this.current_dialog_settings.custom_domain_settings
            }
            return this.domain_settings[this.current_resource]
        }
    },
    methods: {
        ...mapActions({
            set_resource: "dialog/set_resource",
            open_dialog: "dialog/open_item",
            change_item: "dialog/change_item",
            change_mode: "dialog/change_mode",
            close_item: "dialog/close_item",
            change_refresh_resource: "dialog/change_refresh_resource",
            change_dialog_location: "dialog/change_dialog_location"
        }),

        async internal_save(close_item = true) {
            let update_not_save = '_id' in this.current_item.item
            let res

            if (this.current_dialog_settings.save_resource) {
                res = await this.current_dialog_settings.save_resource(close_item)
            } else {
                res = await this.save_resource(this.edited_item, this.domain_settings, this.current_resource)
            }

            let success_message
            let name = "data"
            if (this.current_item.item.name) {
                name = this.current_item.item.name
            }
            if (update_not_save) {
                success_message = `Updated ${name} in resource ${this.current_resource}`
            } else {
                success_message = `Created ${name} in resource ${this.current_resource}`
            }
            this.toast_results(res, success_message)
            if (dict_mixin.methods.deep_get(res, "_error.code") !== 422) {
                await this.change_refresh_resource({resource: this.current_resource, value: true})
                if (close_item) {
                    await this.close_item()
                } else {
                    await this.change_mode("view")
                }
            }
        }
    }
}
</script>