<template>
    <div>
        <template v-if="internal_data !== null">
            <v-list-item>
                <v-list-item-icon>
                    <v-btn icon @click="email_dialog = true">

                        <v-icon>
                            mdi-open-in-new
                        </v-icon>
                    </v-btn>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>
                        {{ translate('to') }}: {{ internal_data.to }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        {{ translate("sent") }}: {{ format(internal_data._created) }}
                    </v-list-item-subtitle>
                </v-list-item-content>

            </v-list-item>

        </template>
        <template v-else>
            Email not found
        </template>
        <v-dialog v-model="email_dialog" v-if="internal_data !== null">
            <v-card>
                <v-card-text>
                    <div v-html="internal_data.html_message" v-if="internal_data.html_message"/>
                    <p v-html="internal_data.message" v-if="internal_data.message"/>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="email_dialog = false">
                        {{ translate('close') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {api_mixin} from "@/shared/mixins/api_mixin";
import {layout_mixin} from "@/shared/mixins/layout";

export default {
    name: "OpenEmail",
    mixins: [api_mixin, layout_mixin],
    props: {
        email_distribution_id: {
            type: String,
        },
        email_distribution: {
            type: Object,
        }
    },
    data() {
        return {
            internal_data: null,
            email_dialog: false
        }
    },
    watch: {
        async email_distribution(val) {
            if (this.is_object_id(this.email_distribution)) {
                await this.get_data()
            } else {
                this.internal_data = null
            }

        }
    },
    methods: {
        async get_data() {
            if (this.is_object_id(this.email_distribution_id)) {
                this.internal_data = await this.api_get_items(
                    {
                        resource: "email_distributions",
                        lookup: {
                            "_id": this.email_distribution_id
                        },
                        first: true
                    }
                )
            } else if (this.email_distribution) {
                this.internal_data = this.email_distribution
            } else {
                console.warn(this.email_distribution_id)
                console.warn(this.email_distribution)
                throw "Invalid input email distribution"
            }

        }
    },
    async created() {
        await this.get_data()

    }
}
</script>

<style scoped>

</style>