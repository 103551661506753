<template>
    <v-row justify="center" v-if="user_bookings.length > 0">
        <v-card v-if="user_bookings.length === 0">
            <v-card-title>
                {{ translate('indehouse.no_bookings') }}
            </v-card-title>
        </v-card>
        <v-card v-else>
            <v-card-title>
                {{ translate('indehouse.future_bookings') }}
            </v-card-title>
            <v-card-text>
                <v-list v-for="(app, app_id) in app_to_app_name_mapping" v-bind:key="app._id">
                    <v-list-item-title>{{ app.company_name }} - {{ app.app_name }}</v-list-item-title>
                    <v-list-item
                        v-for="booking in user_bookings_per_app[app_id]"
                        v-bind:key="booking.date_from.hour"
                    >
                        <v-list-item-title>
                            {{ format(booking.date_from) }} - {{ format(booking.date_to) }}
                        </v-list-item-title>
                        <v-list-item-action>
                            <v-btn :to="go_to_app( booking)">
                                <v-icon>
                                    mdi-link
                                </v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-card-text>
        </v-card>
    </v-row>
</template>

<script>
import {layout_mixin} from "@/shared/mixins/layout";
import {booking_mixin} from "@/mixins/booking";
import {api_mixin} from "@/shared/mixins/api_mixin";
import Loading from "@/shared/components/loading.vue";
import {mapActions} from "vuex";
import {DateTime, now} from "@/shared/helpers/date_helper";

export default {
    name: "MyBookings",
    components: {Loading},
    mixins: [booking_mixin, layout_mixin, api_mixin],
    data: function () {
        return {
            user_bookings: [],
            loading: true,
            current_date: now()
        }
    },
    methods: {
        ...mapActions({set_loading: "dialog/set_loading"}),
        go_to_app(booking) {
            let date_from = new DateTime(booking.date_from)
            return {
                name: 'BookingApp',
                params: {
                    company_id: booking.company,
                    app_id: booking.app,
                    year: date_from.year,
                    month: date_from.month,
                    day: date_from.day
                }
            }
        }
    },
    computed: {
        user_bookings_per_app() {
            let tmp = {}
            for (let booking of this.user_bookings) {
                if (booking.app in tmp) {
                    tmp[booking.app].push(booking)
                } else {
                    tmp[booking.app] = [booking]
                }
            }
            return tmp
        },
        app_to_app_name_mapping() {
            let tmp = {}
            for (let booking of this.user_bookings) {
                if (!(booking.app in tmp)) {
                    tmp[booking.app] = {
                        company_name: booking.company_name,
                        app_name: booking.app_name,
                        app: booking.app,
                        company: booking.company
                    }
                }
            }
            return tmp
        }
    },

    async created() {
        let current_date = now()
        this.user_bookings = await this.api_get_items({
                resource: "bookings",
                lookup: {date_to: {$gt: current_date.gmt_string}}
            }
        )
    }
}
</script>

<style scoped>

</style>