<template>
    <inde-dialog-layout>
        <template #main>
            <v-row dense>
                <v-col>
                    <h3 class="pb-3">{{ translate(value.event) }}</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <span v-if="value?.assigned_to_name">
                        {{ translate("assigned_to") }} {{ value.assigned_to_name }}
                    </span>
                    <p v-if="value?.expected_delivery">
                        {{ translate("expected_delivery") }} {{ format_date(value.expected_delivery) }}
                    </p>
                    <p v-if="value?.internal_note">
                        {{ value.internal_note }}
                    </p>
                </v-col>
                <v-col>
                    <p> {{ translate('updated_by') }} {{ value.user_name }}</p>
                </v-col>
            </v-row>
        </template>
        <template #actions>
            <v-card
                flat
                height="100%"
                width="100%"
                align="center"
            >
                <v-card-subtitle align="center">
                    {{ translate('created') }} {{ format_date(value._created) }}
                </v-card-subtitle>
            </v-card>
        </template>
    </inde-dialog-layout>
</template>

<script>
import {format_date} from "@/shared/helpers/date_helper";
import {layout_mixin} from "@/shared/mixins/layout";
import IndeDialogCard from "@/shared/components/dialogs/inde_dialog_card.vue";
import IndeDialogLayout from "@/shared/components/other/inde_dialog_layout.vue";

export default {
    name: "form-logs",
    components: {IndeDialogLayout, IndeDialogCard},
    mixins: [layout_mixin],
    props: {
        value: {
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false
        },
        dotted_path: {
            type: String
        },
        field: {
            type: Object
        },
    },
    methods: {
        format_date
    }
}
</script>

<style scoped>

</style>