<template>
    <div>
        <v-row
            :justify="justify" :align="align"
            v-for="i in Math.ceil(items.length / comp_cols_per_row)"
            v-bind:key="i"
        >
            <v-col
                :align="align"
                :cols="12/comp_cols_per_row"
                v-for="(item,index) in items.slice((i - 1) * comp_cols_per_row, i * comp_cols_per_row)"
                v-bind:key="bind_key(item, index)"
            >
                <slot name="default" :item="item"/>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import {layout_mixin} from "@/shared/mixins/layout";

export default {
    name: "IndeCardViewBasic",
    mixins: [layout_mixin],
    props: {
        items: {
            type: Array,
            required: true
        },
        align: {
            type: String,
            default: "center"
        },
        justify: {
            type: String,
            default: "center"
        },
        cols: {
            type: Number,
            default: 1
        },
        sm: {
            type: Number,
            default: null
        },
        md: {
            type: Number,
            default: null
        },
        lg: {
            type: Number,
            default: null
        },
        xl: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            internal_xs: null,
            internal_sm: null,
            internal_md: null,
            internal_lg: null,
            internal_xl: null,
        }
    },
    methods: {
        bind_key(item, index) {
            if (item?._id) {
                return item._id
            }
            return index
        }
    },
    computed: {
        comp_cols_per_row() {
            if (this.$vuetify.breakpoint.xsOnly) {
                return this.internal_xs
            }
            if (this.$vuetify.breakpoint.smOnly) {
                return this.internal_sm
            }
            if (this.$vuetify.breakpoint.mdOnly) {
                return this.internal_md
            }
            if (this.$vuetify.breakpoint.lgOnly) {
                return this.internal_lg
            }

            if (this.$vuetify.breakpoint.xlOnly) {
                return this.internal_xl
            }
        }
    },
    created() {
        if (this.cols) {
            this.internal_xs = this.cols
            this.internal_sm = this.cols
            this.internal_md = this.cols
            this.internal_lg = this.cols
            this.internal_xl = this.cols
        }
        if (this.sm) {
            this.internal_sm = this.sm
            this.internal_md = this.sm
            this.internal_lg = this.sm
            this.internal_xl = this.sm
        }
        if (this.md) {
            this.internal_md = this.md
            this.internal_lg = this.md
            this.internal_xl = this.md
        }
        if (this.lg) {
            this.internal_lg = this.lg
            this.internal_xl = this.lg
        }
        if (this.xl) {
            this.internal_xl = this.xl
        }

    }
}
</script>

<style scoped>

</style>