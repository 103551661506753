<template>
    <div>
        <IndeTableView
            :resource="resource"
            :lookup="lookup"
        />
    </div>
</template>

<script>
import IndeTableView from "@/shared/components/view/inde_table_view";
import {api_mixin} from "@/shared/mixins/api_mixin";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "ViewResource",
    components: {IndeTableView},
    mixins: [api_mixin],
    props: {
        resource: {
            type: String
        }
    },
    data() {
        return {
            lookup: {}
        }
    },
    methods: {
        ...mapActions({
            set_resource: "dialog/set_resource"
        })
    },
    async created() {
        await this.set_resource(this.resource)
    }
}
</script>

<style scoped>

</style>