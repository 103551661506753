<template>
    <div>
        <v-text-field v-model="create_company_name" :placeholder="translate('indehouse.create_company')"/>
        <v-btn :disabled="company_name_error !== 'Ok'" v-on:click="create_company_vuex">
            {{ translate('indehouse.create_company') }}
        </v-btn>
        <p> {{ result }} </p>
    </div>
</template>


<script>

import {mapActions, mapGetters} from "vuex";
import {company_app_mixin} from "@/mixins/company_app_mixin";
import {api_mixin} from "@/shared/mixins/api_mixin";
import {layout_mixin} from "@/shared/mixins/layout";

export default {
    name: "create_company",
    mixins: [company_app_mixin, api_mixin, layout_mixin],
    data: function () {
        return {
            create_company_name: "",
            result: "",
            checked_company_name: ""
        }
    },
    methods: {
        ...mapActions({change_refresh_resource: "dialog/change_refresh_resource"}),
        async create_company_vuex() {
            let res = await this.api_post(
                {
                    resource: "companies", data: {
                        name: this.create_company_name,
                        email: this.current_user.email
                    }
                }
            )

            if (res._status === "OK") {
                await this.change_refresh_resource({resource: "companies", value: true})
                this.$toasted.success(`Created company ${this.create_company_name}`)
            } else {
                this.$toasted.error(`Error creating company: ${res.error}`)
            }
        },

        company_name_error_calc() {
            if (this.create_company_name.length === 0) {
                this.result = ""
            } else if (this.create_company_name.length <= 4) {
                this.result = this.translate("company_name_length", true)
            } else {
                try {
                    this.api_get_items({
                            resource: "companies",
                            lookup: {
                                name: this.create_company_name,
                            },
                            first: true

                        }
                    ).then(
                        (res) => {
                            if (res === undefined) {
                                this.result = "Ok"
                            } else {
                                this.result = `Company ${this.create_company_name} already exists.`
                            }

                        }
                    )
                } catch (error) {
                    this.result = error
                }


            }
        },
    },

    computed: {
        ...mapGetters({"current_user": "auth/current_user"}),
        company_name_error() {
            this.company_name_error_calc()
            return this.result
        },
    }
}
</script>

<style scoped>

input {
    font-size: 20px;
    text-align: center;
    width: fit-content;
    height: 30px;
    border: 1px solid #999999;
    padding: 5px;
}

</style>
