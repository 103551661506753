<template>
    <div>
        {{ translate("indehouse.show_if") }} <br>
        <loading v-if="internal_loading"></loading>
        <div v-else>

            <v-expansion-panels>
                <v-expansion-panel
                    v-for="(value, index) in internal_value"
                    :key="index"
                >
                    <v-expansion-panel-header>
                        <v-row>
                            <view_objectid v-model="value['field']" resource="form_layouts"/>
                            =>
                            <view_objectid v-model="value['equals']" resource="form_options"/>
                            <v-spacer/>
                            <v-btn
                                icon
                                @click="remove_item(value)"
                                class="mr-4"

                            >
                                <v-icon v-if="current_mode!== 'view'">
                                    mdi-delete
                                </v-icon>
                            </v-btn>
                        </v-row>
                        <template #actions>
                        </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <InputAutoComplete
                            :value="value.field"
                            :label="translate('indehouse.field', true)"
                            :lookup="depends_on_field_lookup"
                            :dotted_path="dotted_path"
                            :disabled="disabled"
                            item-text="name"
                            item-value="_id"
                            resource="form_layouts"
                            :search_fields="['name', 'locale.se', 'locale.en']"
                        />
                        <InputAutoComplete
                            :value="value.equals"
                            :label="translate('indehouse.option')"
                            :disabled="disabled"
                            :lookup="{filter: internal_equals_options_lookup}"
                            item-value="_id"
                            item-text="name"
                            clearable
                            resource="form_options"
                            multiple
                            :search_fields="['name', 'locale.se', 'locale.en']"
                        />
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-btn @click="add = !add" class="mt-3" v-if="add_enabled">
                <v-icon>
                    mdi-plus
                </v-icon>
            </v-btn>
            <v-card v-if="add">
                <v-card-text>
                    <InputAutoComplete
                        v-model="internal_temp_value.field"
                        :label="translate('indehouse.field', true)"
                        :lookup="depends_on_field_lookup"
                        :dotted_path="dotted_path"
                        :disabled="disabled"
                        item-text="name"
                        item-value="_id"
                        resource="form_options"
                        :search_fields="['name', 'locale.se', 'locale.en']"
                    />
                    <InputAutoComplete
                        v-model="internal_temp_value.equals"
                        :label="translate('indehouse.option')"
                        :disabled="disabled"
                        :lookup="{filter: internal_equals_options_lookup}"
                        item-value="_id"
                        item-text="name"
                        clearable
                        resource="form_options"
                        multiple
                        :search_fields="['name', 'locale.se', 'locale.en']"
                    />
                </v-card-text>
                <v-card-actions>
                    <v-btn v-on:click="save">
                        {{ translate("common.save") }}
                    </v-btn>
                    <v-btn v-on:click="add = false">
                        {{ translate("common.cancel") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </div>
    </div>
</template>

<script>
import {api_mixin} from "@/shared/mixins/api_mixin";
import {mapGetters} from "vuex";
import {layout_mixin} from "@/shared/mixins/layout";
import InputSelect from "@/shared/components/dialogs/input_select.vue";
import InputType from "@/shared/components/dialogs/input_type.vue";
import {dict_mixin} from "@/shared/mixins/dict_mixin";
import Loading from "@/shared/components/loading.vue";
import View_objectid from "@/shared/components/helper/view_objectid.vue";
import InputAutoComplete from "@/shared/components/dialogs/custom_components/input_auto_complete.vue";

export default {
    name: "select-dependable-option",
    components: {InputAutoComplete, View_objectid, Loading, InputType, InputSelect},
    mixins: [api_mixin, layout_mixin, dict_mixin],
    props: {
        value: {
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false
        },
        dotted_path: {
            type: String
        },
        field: {
            type: Object
        },
    },
    data() {
        return {
            internal_value: [],
            internal_options: [],
            internal_options_per_id: {},
            internal_layouts_per_id: {},
            internal_equals_options: [],
            internal_equals_options_lookup: {},
            internal_temp_value: {},
            internal_layout: {},
            add: false,

        }
    },
    watch: {
        value(val) {
            if (!Array(this.value)) {
                this.internal_value = []
            } else {
                this.internal_value = val
            }
            this.create_internal_temp_value()
        },
        internal_value(val) {
            this.$emit("input", val)
        },
        'internal_value.depends_on_field': {
            async handler(val) {
                await this.create_internal_options_set_up()
            }
        },

    },
    computed: {
        ...mapGetters({
            current_locked_data: "dialog/current_locked_data",
            current_mode: "dialog/current_mode",
            current_id: "dialog/current_id",
        }),
        depends_on_field_lookup() {
            let lookup = {...this.field.schema.field.data_relation.lookup}
            if (this.current_id) {
                this.deep_set(lookup, "filter._id.$ne", this.current_id)
            }
            return lookup
        },
        add_enabled() {
            if (this.current_mode !== 'view') {
                if (!this.add) {
                    return true
                }
            }
            return false
        },
    },
    methods: {
        create_internal_temp_value() {
            this.internal_temp_value = {}
        },
        save() {
            this.internal_value.push(this.internal_temp_value)
            this.$emit("input", this.internal_value)
            this.add = false
            this.create_internal_temp_value()
        },
        remove_item(item) {
            let index = this.internal_value.indexOf(item)
            if (index > -1) { // only splice array when item is found
                this.internal_value.splice(index, 1); // 2nd parameter means remove one item only
            }
        },
    },
    async created() {
        this.internal_loading = true
        if (!Array.isArray(this.value)) {
            this.internal_value = []
        } else {
            this.internal_value = this.value
        }
        this.internal_options = await this.api_get_items(
            {
                resource: "form_options",
                lookup: this.current_locked_data
            }
        )
        for (let option of this.internal_options) {
            this.internal_options_per_id[option["_id"]] = option
        }

        this.internal_items = await this.api_get_items(
            {
                resource: "form_layouts",
                lookup: this.current_locked_data
            }
        )
        for (let layout of this.internal_items) {
            this.internal_layouts_per_id[layout["_id"]] = layout
        }
        this.dialog_open = this.open_by_default
        this.create_internal_temp_value()
        this.internal_loading = false
    }

}
</script>

<style scoped>

</style>