<template>
    <div>
        <v-card>
            <v-card-text>
                <input-select
                    v-model="theme_template_id"
                    resource="theme_templates"
                    label="theme_templates"
                    item-text="name"
                    :lookup="{'filter':{'theme': theme['_id']}}"
                    hide_create
                />
                <v-row>
                    <v-col cols="3">
                        <v-expansion-panels v-if="theme_template?._id">
                            <v-expansion-panel
                                v-for="(section, index) in theme_template.sections"
                                v-bind:key="index"
                            >
                                <v-expansion-panel-header>
                                    <span style="font-size: small">
                                        {{ get_translation(section.name) }}
                                    </span>
                                    <template #actions>

                                        <v-btn icon @click.stop="open_section_settings(section)">
                                            <v-icon>
                                                mdi-cog
                                            </v-icon>
                                        </v-btn>
                                        <v-btn icon @click.stop="load_section_data(section)">
                                            <v-icon>
                                                mdi-open-in-new
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-expansion-panel
                                        v-for="(block, index) in section.blocks"
                                        v-bind:key="index"
                                    >
                                        <v-expansion-panel-header>
                                            {{ get_translation(block.name) }}
                                            <template #actions>
                                                <v-btn
                                                    small
                                                    icon
                                                    @click.stop="open_block_settings(section, block)">
                                                    <v-icon>
                                                        mdi-cog
                                                    </v-icon>
                                                </v-btn>
                                                <v-btn icon @click.stop="delete_block(section, block, index)">
                                                    <v-icon>
                                                        mdi-delete
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                        </v-expansion-panel-header>

                                    </v-expansion-panel>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel
                            >
                                <v-expansion-panel-header>
                                    layout
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <input-select
                                        v-model="theme_template.layout"
                                        resource="theme_layouts"
                                        :lookup="{'theme': theme['_id']}"
                                        item-value="_id"
                                        item-text="name"
                                    />
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <v-row>
                            <v-col>
                                <v-btn small @click="load_template_data" v-if="theme_template_id">
                                    Load template
                                </v-btn>
                                <v-btn small @click="show_code_dialog=true">
                                    Show code
                                </v-btn>
                                <v-btn small @click="save_template" v-if="theme_template_id">
                                    Save template
                                </v-btn>
                            </v-col>

                        </v-row>
                    </v-col>
                    <v-col cols="3" style="z-index: 2; background-color: white;" v-if="selected_section">
                        <v-card flat>
                            <v-card-text>
                                <ThemeTemplateBlock
                                    v-if="selected_block"
                                    v-model="selected_block"
                                    :section_id="selected_section.section"
                                    :theme="theme"
                                    :theme_locales="theme_locales"
                                />
                                <ThemeTemplateSection
                                    v-else-if="selected_section"
                                    v-model="selected_section.settings"
                                    :theme="theme"
                                    :theme_locales="theme_locales"
                                    :section_id="selected_section.section"
                                />
                            </v-card-text>
                        </v-card>
                    </v-col>


                </v-row>
                <v-col cols="12" style="z-index: 1">
                    <div v-html="template_html"></div>
                </v-col>
            </v-card-text>
        </v-card>


        <v-dialog v-model="show_code_dialog">
            <v-card>
                <v-card-text>
                    <div>{{ template_html }}</div>
                </v-card-text>
            </v-card>
        </v-dialog>
        <div>section {{ selected_section }}</div>
        <br>
        <div>block {{ selected_block }}</div>


    </div>

</template>


<script>
import InputJsonViewer from "@/shared/components/dialogs/custom_components/input_json_viewer.vue";
import InputSelect from "@/shared/components/dialogs/input_select.vue";
import ThemeSettingItem from "@/views/components/theme/settings/theme_setting_item.vue";
import {api_mixin} from "@/shared/mixins/api_mixin";
import ThemeTemplateSection from "@/views/components/theme/settings/theme_template_section.vue";
import {dict_mixin} from "@/shared/mixins/dict_mixin";
import {layout_mixin} from "@/shared/mixins/layout";
import ThemeTemplateBlock from "@/views/components/theme/settings/theme_template_block.vue";
import {mapActions} from "vuex";

export default {
    name: "ThemeTemplateSettings",
    components: {ThemeTemplateBlock, ThemeTemplateSection, ThemeSettingItem, InputSelect, InputJsonViewer},
    mixins: [api_mixin, dict_mixin, layout_mixin],
    props: {
        theme: {
            type: Object,
            required: true
        },
        theme_locales: {
            default: null,
            type: Object
        }
    },
    data() {
        return {
            internal_theme_locales: null,
            theme_settings_schema: null,
            theme_template_id: null,
            theme_template: null,
            theme_settings: null,
            template_html: "",
            selected_section: null,
            selected_block: null,
            show_code_dialog: false,
        }
    },
    methods: {
        ...mapActions({change_navigation_open: "navigation/change_navigation_open"}),
        get_translation(val) {
            let path = `data.${val.substring(2)}`
            let translation = this.deep_get(this.internal_theme_locales, path)
            if (translation) {
                return translation
            }
            return val
        },
        open_block_settings(section, block) {
            this.selected_block = block
            this.selected_section = section
        },
        delete_block(section, block, index) {
            if (section.blocks[index] === block) {
                section.blocks.splice(index, 1)
            }
        },
        open_section_settings(section) {
            this.selected_block = null
            this.selected_section = section
        },
        async load_section_data(section) {
            let response = await this.api_post({
                    resource: "themes/render_section",
                    data: {
                        theme: this.theme["_id"],
                        template: this.theme_template_id,
                        section: section.section,
                    }
                }
            )
            this.template_html = response.data
        },
        async load_template_data() {
            let response = await this.api_post({
                    resource: "themes/render_template",
                    data: {
                        theme: this.theme["_id"],
                        template: this.theme_template_id,
                        variables: {}
                    }
                }
            )
            this.template_html = response.data
        },
        async load_theme_settings() {
            if (!this.theme["current_setting"]) {
                throw "Missing current setting in theme "
            }
            this.theme_settings = await this.api_get_items({
                resource: "theme_setting_data",
                lookup: {
                    _id: this.theme["current_setting"],
                    theme: this.theme["_id"],
                },
                first: true
            })
        },
        async load_theme_template() {
            this.theme_template = await this.api_get_items({
                resource: "theme_templates",
                lookup: {
                    theme: this.theme["_id"],
                    "$or": [
                        {"_id": this.theme_template_id}, {"file_info.base_name": "index"},
                    ]
                },
                first: true
            })
            if (this.theme_template_id == null) {
                this.theme_template_id = this.theme_template["_id"]
            }
            await this.load_template_data()
        },
        async save_template() {
            let res = await this.api_put({
                    resource: "theme_templates",
                    data: this.theme_template,
                }
            )
            this.toast_results(res, "Template updated")
            if (res._status === 'OK') {
                this.theme_template._etag = res._etag
            }
        }
    },
    watch: {
        async theme_template_id(val) {
            if (val) {
                await this.load_theme_template()
            }
        }
    },
    async created() {
        await this.change_navigation_open({open: false, mini: true})
        await this.load_theme_settings()
        await this.load_theme_template()
    }
}
</script>


<style scoped>

</style>