import {now} from "@/shared/helpers/date_helper";

export const company_app_mixin = {
    methods: {
        app_url(app, sub_path = null) {
            if (app.type === "booking") {
                let d = now()
                return {
                    name: "BookingApp",
                    params: {
                        company_id: app.company,
                        app_id: app._id,
                        year: d.year,
                        month: d.month,
                        day: d.day
                    }
                }
            } else if (app.type === "hotel") {
                return {
                    name: "HotelApp",
                    params: {
                        company_id: app.company,
                        app_id: app._id,
                    }
                }
            } else if (app.type === "pickup") {
                return {
                    name: "PickupApp",
                    params: {
                        company_id: app.company,
                        app_id: app._id,
                    }
                }
            } else if (app["type"] === "form") {
                return this.form_url(app, sub_path)
            } else if (app["type"] === "theme") {
                return {
                    name: "ThemeApp",
                    params: {
                        company_id: app.company,
                        app_id: app._id,
                    }
                }
            } else if (app["type"] === "syncogs") {
                return {
                    name: "SyncogsListings",
                    params: {
                        company_id: app.company,
                        app_id: app._id,
                    }
                }
            }
            throw `Invalid type company app mixin ${app.type} sub_path ${sub_path}`
        },
        form_url(app, sub_path) {
            if (sub_path == null) {
                return {
                    name: "FormBookings",
                    params: {
                        company_id: app.company,
                        app_id: app._id,
                    }
                }
            } else if (sub_path === "booking") {
                return {
                    name: "FormBookings",
                    params: {
                        company_id: app.company,
                        app_id: app._id,
                    }
                }
            }
            if (sub_path === "settings") {
                return {
                    name: "FormSettings",
                    params: {
                        company_id: app.company,
                        app_id: app._id,
                    }
                }
            }
            if (sub_path === "customer") {
                return {
                    name: "FormCustomer",
                    params: {
                        company_id: app.company,
                        app_id: app._id,
                    }
                }
            }
            if (sub_path === "dashboard") {
                return {
                    name: "FormApp",
                    params: {
                        company_id: app.company,
                        app_id: app._id,
                    }
                }
            }
            if (sub_path === "external") {
                let is_public = app.settings?.form?.is_public
                if (is_public) {
                    return {
                        name: "FormExternal",
                        params: {
                            company_id: app.company,
                            app_id: app._id,
                        }
                    }
                }
                return {
                    name: "FormExternalWithAuth",
                    params: {
                        company_id: app.company,
                        app_id: app._id,
                    }
                }

            }
            if (sub_path === "inventory") {
                return {
                    name: "FormInventory",
                    params: {
                        company_id: app.company,
                        app_id: app._id,
                    }
                }
            }
        }
    },
    computed: {
        company_app_default_data() {
            if (!(this.app_id && this.company_id)) {
                throw "Missing app_id or company_id"
            }
            return {
                company: this.company_id,

            }

        }
    }
}