<template>
    <v-card :elevation="10">
        <v-card-subtitle class="ml-10">
            {{ internal_value?.name }}
        </v-card-subtitle>
        <v-card-text>
            <v-row v-if="!internal_value?.id">
                <v-col cols="6">
                    <v-file-input
                        v-model="file_to_upload"
                        :accept="field.custom_component_args[0]"
                        label="File input"
                        @change="on_file_change"
                    ></v-file-input>
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col>
                    <v-img :src="internal_value.file" alt="" max-width="100px" max-height="100px"/>
                    <v-btn @click="remove_file" :disabled="disabled">
                        Remove file
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

        </v-card-text>
        <v-dialog v-model="open_file" max-width="500px">
            <v-img v-if="file_url " :src="file_url">
            </v-img>
        </v-dialog>

    </v-card>
</template>

<script>
import {api_mixin} from "@/shared/mixins/api_mixin";
import {mapGetters} from "vuex";
import IndeCardView from "@/shared/components/view/inde_card_view.vue";
import {DataType} from "@/shared/mixins/schema";

export default {
    name: "InputFileUpload",
    components: {IndeCardView},
    mixins: [api_mixin],
    props: {
        value: {
            required: true
        },
        dotted_path: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        },
        field: {
            type: DataType,
            required: true
        }
    },
    data() {
        return {
            internal_value: null,
            file_to_upload: {},
            new_file: false,
            add_file: false,
            file_url: null,
            open_file: false,
            images: []
        }
    },
    watch: {
        value(val) {
            this.internal_value = val
        },
        internal_value(val) {
            this.$emit("input", val)
        },
        file_to_upload() {
            // this.upload_file()
        },
    },
    computed: {
        ...mapGetters({
            current_item: "dialog/current_item",
            current_resource: "dialog/current_resource",
            current_mode: "dialog/current_mode",

        }),
    },

    methods: {
        on_file_change(file) {
            this.create_image(file);
        },
        remove_file() {
            this.internal_value = {}
        },
        create_image(file) {
            let reader = new FileReader();
            reader.onload = (event) => {
                const file_url = event.target.result;
                this.internal_value = {
                    "name": file.name,
                    "size": file.size,
                    "content_type": file.type,
                    "file": file_url
                };

            }
            reader.readAsDataURL(file);
        },
        removeImage() {
            this.internal_value = null
        }
    },
    async created() {
        if (this.value === undefined) {
            this.internal_value = {}
        } else {
            this.internal_value = this.value
        }
    },

}
</script>

<style scoped>

</style>