<template>
    <div>
        <p> color scheme group todo</p>
    </div>
</template>

<script>
import InputColorPicker from "@/shared/components/dialogs/custom_components/input_color_picker.vue";

export default {
    name: "ColorPickerGroup",
    components: {InputColorPicker},
    props: {
        settings: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            internal_value: null
        }
    }
}
</script>


<style scoped>

</style>