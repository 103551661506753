<template>
    <div>
        <div v-if="false" :align="'right'">
            mdAndUp{{ $vuetify.breakpoint.mdAndUp }}
            lgAndUp{{ $vuetify.breakpoint.lgAndUp }}
            smAndUp{{ $vuetify.breakpoint.smAndUp }}
        </div>
        <h1 style="margin: 50px;text-align: center">
            {{ translate("indehouse.welcome") }}
        </h1>
        <not-logged-in v-if="current_user === null"/>
        <logged-in v-else/>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {user_mixin} from "@/mixins/users";
import NotLoggedIn from "@/views/components/home/not_logged_in";
import LoggedIn from "@/views/components/home/logged_in";
import {layout_mixin} from "@/shared/mixins/layout";
import {token_mixin} from "@/shared/mixins/token";
import ButtonShadow from "@/shared/components/inde_components/button_shadow.vue";

export default {
    name: 'Home',
    components: {ButtonShadow, LoggedIn, NotLoggedIn},
    mixins: [token_mixin, user_mixin, layout_mixin],
    data: function () {
        return {}
    },
    computed: {
        ...mapGetters({current_user: "auth/current_user"}),
    },
    watch: {},
}

</script>

<style scoped>
</style>
