import Vuex from 'vuex'
import Vue from 'vue'
import {auth_store} from "@/shared/store/auth";
import {booking_store} from "@/store/booking_store";
import {create_persistent_storage} from "@/shared/store/auth";
import {variable_store} from "@/store/variables_store";
import {dialog_store} from "@/shared/store/dialog_store";
import {navigation_store} from "@/store/navigation";


Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        auth: auth_store,
        booking: booking_store,
        variables: variable_store,
        dialog: dialog_store,
        navigation: navigation_store
    },
    plugins: [create_persistent_storage(['auth']).plugin]


})
