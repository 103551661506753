<template>
    <div>
        <v-form v-model="form_valid" ref="form">
            <IndeCardViewBasic
                :items="layouts_on_this_page"
                :align="'start'"
                :justify="'start'"
                :cols="1"
                :md="2"
            >
                <template #default="{item}">
                    <FormInput
                        v-model="internal_value[item._id]"
                        :options_per_id="options_per_id"
                        :services_per_id="services_per_id"
                        :products_per_id="products_per_id"
                        :layout="item"
                        :dependable_value="dependable_values[item._id].options"
                        :show_if_value="dependable_values[item._id].show_if"
                        :auth="auth"
                        :app_id="app_id"
                    />
                </template>
            </IndeCardViewBasic>
        </v-form>
    </div>
</template>

<script>
import IndeTableView from "@/shared/components/view/inde_table_view.vue";
import {mapGetters} from "vuex";
import IndeStepperDate from "@/shared/components/dialogs/stepper_date.vue";
import IndeCardViewBasic from "@/shared/components/other/inde_card_view_basic.vue";
import FormInput from "@/views/components/form/form/input.vue";

export default {
    name: "FormInputs",
    emits: ["update:valid", "input"],
    components: {FormInput, IndeCardViewBasic, IndeStepperDate, IndeTableView},
    props: {
        layouts: {
            type: Array,
            required: true
        },
        options_per_id: {
            type: Object,
            required: true
        },
        services_per_id: {
            type: Object,
            required: true
        },
        products_per_id: {
            type: Object,
            required: true
        },
        page: {
            type: Object,
        },
        value: {},
        auth: {
            type: Boolean,
            required: true
        },
        app_id: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            app: null,
            products: [],
            internal_value: {},
            dependable_values: {},
            dependable_show_if_value: {},
            layouts_on_this_page: [],
            form_valid: false,
            validate_result: null
        }
    },
    watch: {
        internal_value: {
            handler(val, old_val) {
                this.$emit("input", val)
                this.refresh_dependable_value()
                this.layouts_on_this_page = this.update_layouts_on_this_page()
            },
            deep: true
        },
        form_valid(val) {
            this.$emit("update:valid", val)
        },
        value(val, old_val) {
            if (val === old_val) {
                return
            }
            this.internal_value = val
            this.refresh_dependable_value()
            this.layouts_on_this_page = this.update_layouts_on_this_page()

        }
    },
    methods: {
        update_layouts_on_this_page() {
            if (this.layouts === undefined) {
                return []
            }
            let dependable_vals = this.dependable_values

            function filter_function(layout) {
                if (layout.show_if) {
                    for (let condition of layout.show_if) {
                        let dependable_value = dependable_vals[layout._id].show_if[condition.field]
                        if (condition.equals) {
                            if (!condition.equals.includes(dependable_value)) {
                                return false
                            }
                        }
                    }
                }
                return true
            }


            if (this.page === undefined) {
                return this.layouts.sort(x => x.order)
            }
            let res1 = this.layouts.filter(x => x.page === this.page._id)
            let res2 = res1.filter(x => filter_function(x))

            return res2.sort(x => x.order)
        },
        refresh_dependable_value() {
            for (let layout of this.layouts) {

                let current_options = this.dependable_values?.[layout._id]?.options
                let current_show_id = this.dependable_values?.[layout._id]?.show_if
                this.dependable_values[layout._id] = {
                    options: Object(current_options) === current_options ? current_options : null,
                    show_if: Object(current_show_id) === current_show_id ? current_show_id : {}
                }
                if (layout.dependable_options) {
                    let dependable_field = this.layouts_per_id[layout.dependable_options.depends_on_field]
                    if (dependable_field) {
                        if (this.dependable_values[layout._id].options !== this.internal_value[dependable_field._id]) {
                            this.dependable_values[layout._id].options = this.internal_value[dependable_field._id]
                        }
                    }
                }
                if (layout.show_if) {
                    for (let condition of layout.show_if) {
                        let df = this.layouts_per_id[condition.field] //dependable_field
                        if (this.dependable_values[layout._id].show_if[condition.field] !== this.internal_value[df._id]) {
                            this.dependable_values[layout._id].show_if[condition.field] = this.internal_value[df._id]
                        }
                    }
                }
            }
        },

        validate() {
            return this.$refs.form.validate()
        },
    },
    computed: {
        ...mapGetters({
            current_user: "auth/current_user"
        }),
        locale() {
            return this.$i18n.locale
        },
        lookup() {
            return {}
        },
        default_data() {
            return {}
        },
        layouts_per_id() {
            let tmp = {}
            for (let layout of this.layouts) {
                tmp[layout['_id']] = layout
            }
            return tmp
        },


    },
    async created() {
        this.internal_value = this.value
        this.refresh_dependable_value()
        this.layouts_on_this_page = this.update_layouts_on_this_page()

    }
}
</script>

<style scoped>

</style>