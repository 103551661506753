<template>
    <div v-if="!internal_loading">
        <FormInputMain
            :app_id="app_id"
            :company_id="company_id"
            :app="app"
            :auth="auth"
        />
        <LocaleSelector disable_text disable_update_user_settings></LocaleSelector>
    </div>

</template>

<script>
import LocaleSelector from "@/shared/components/other/locale_selector.vue";
import {api_mixin} from "@/shared/mixins/api_mixin";
import Spacing from "@/shared/components/helper/Spacing.vue";
import {layout_mixin} from "@/shared/mixins/layout";
import {dict_mixin} from "@/shared/mixins/dict_mixin";
import FormInputMain from "@/views/components/form/form/index.vue";

export default {
    name: "FormExternal",
    components: {FormInputMain, Spacing, LocaleSelector},
    mixins: [api_mixin, layout_mixin, dict_mixin],
    props: {
        app_id: {
            type: String,
            required: true
        },
        company_id: {
            type: String,
            required: true
        },
        auth: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            app: null,

        }
    },
    async created() {
        console.log("Yo")
        let response = await this.api_get_items(
            {
                resource: `apps/${this.app_id}`,
                lookup: {},
                // first: true,
                auth: this.auth,
                return_items: false,
                return_data: false
            }
        )
        if ("_id" in response) {
            this.app = response
        } else {
            return
        }
        this.app = response
        let locale = this.deep_get(this.app, "settings.form.default_locale")
        if (locale) {
            this.$i18n.locale = locale
        }


        let color_settings = this.deep_get(this.app, `settings.form.colors`)
        this.set_theme(color_settings)
        this.$vuetify.theme.dark = false
        this.internal_loading = false

    },
    beforeDestroy() {
        this.set_theme({})
    }
}
</script>

<style scoped>

</style>