import SyncogsSettings from "@/views/components/syncogs/syncogs_settings.vue";
import SyncogsListings from "@/views/components/syncogs/syncogs_listings.vue";

export let syncog_routes = [
    {
        path: "/s/settings/:company_id/:app_id",
        name: "SyncogsSettings",
        component: SyncogsSettings,
        props: true,
        meta: {requiresAuth: true},
    },
    {
        path: "/s/listings/:company_id/:app_id",
        name: "SyncogsListings",
        component: SyncogsListings,
        props: true,
        meta: {requiresAuth: true},
    },
]