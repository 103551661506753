<template>
    <div v-if="theme?._id">
        <v-tabs
            v-model="internal_tab"
            center-active
        >
            <v-tab v-for="resource in theme_resources"
                   :to="{name: 'theme_editor', params: {theme_id,  tab:resource}}"
                   :key="resource"
                   :id="resource"
            >
                {{ translate(resource, true) }}
            </v-tab>
            <v-tab
                key="site"
                :to="{name: 'theme_editor', params: {theme_id, tab: 'site'}}"
            >
                Site settings
            </v-tab>

            <v-tab
                key="templates"
                :to="{name: 'theme_editor', params: {theme_id, tab: 'templates'}}"
            >
                Template Settings
            </v-tab>
        </v-tabs>
        <v-tabs-items :value="tab">
            <v-tab-item key="themes" value="themes" id="themes">
                <v-card>
                    <v-card-title>
                        {{ theme.name }}
                    </v-card-title>
                    <v-card-text>
                        <p> Status: {{ theme.status }}</p><br>
                        <p> Current Settings:
                            <ViewObjectid
                                v-if="theme.current_setting"
                                v-model="theme.current_setting"
                                resource="theme_setting_data"
                                item-text="name"
                            />
                        </p>
                        <v-btn @click.stop="()=>open_theme(theme)" icon class="mb-2">
                            <v-icon>
                                mdi-link
                            </v-icon>
                        </v-btn>
                    </v-card-text>
                    <v-card-actions>
                        <IndeResourceAction
                            v-if="theme.status !== 'completed'"
                            resource="themes"
                            action="parse_theme"
                            :item="{'theme_id': theme['_id']}"
                            :default_data_fields="['theme_id']"
                            :locked_data_fields="['theme_id']"
                            disable_reload_item
                            :on_reload="load_data"
                        >
                            <template #default="{item}">
                                <p></p>
                            </template>
                        </IndeResourceAction>
                        <IndeResourceAction
                            v-else
                            resource="themes"
                            action="theme_delete_related_objects"
                            :item="{'theme_id': theme['_id']}"
                            :default_data_fields="['theme_id']"
                            :locked_data_fields="['theme_id']"
                            disable_reload_item
                            :on_reload="load_data"
                        >
                            <template #default="{item}">
                                <p></p>
                            </template>
                        </IndeResourceAction>
                    </v-card-actions>
                </v-card>
            </v-tab-item>
            <v-tab-item key="theme_pages" value="theme_pages">
                <inde-table-view resource="theme_pages" :lookup="default_lookup">
                </inde-table-view>
            </v-tab-item>
            <v-tab-item key="monaco" value="monaco">
                <ThemeEditorPage :theme="theme"/>
            </v-tab-item>
            <v-tab-item
                key="site"
                value="site"
                id="site"
            >
                <ThemeSiteSettings :theme="theme" :theme_locales="theme_locales"/>
            </v-tab-item>
            <v-tab-item
                value="templates"
                key="templates"
                id="templates"
            >
                <ThemeTemplateSettings :theme_locales="theme_locales" :theme="theme"/>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>
<script>
import IndeTableView from "@/shared/components/view/inde_table_view.vue";
import {layout_mixin} from "@/shared/mixins/layout";
import Editor from "@/views/components/editor/editor.vue";
import Monaco from "@/views/components/editor/monaco.vue";
import {api_mixin} from "@/shared/mixins/api_mixin";
import ThemeEditorPage from "@/views/components/theme/theme_editor_page.vue";
import IndeResourceAction from "@/shared/components/view/inde_resource_action.vue";
import ViewObjectid from "@/shared/components/helper/view_objectid.vue";
import InputSelect from "@/shared/components/dialogs/input_select.vue";
import ThemeTemplateSettings from "@/views/components/theme/settings/theme_template_settings.vue";
import ThemeSiteSettings from "@/views/components/theme/settings/theme_site_settings.vue";

export default {
    name: "ThemeEditor",
    components: {
        ThemeSiteSettings,
        ThemeTemplateSettings,
        ThemeEditorPage,
        InputSelect,
        ViewObjectid,
        IndeResourceAction,
        Monaco,
        Editor,
        IndeTableView
    },
    mixins: [layout_mixin, api_mixin],
    props: {
        theme_id: {
            type: String,
            required: true
        },
        tab: {
            type: String,
            default: null
        },
        sub_tab: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            current_item: null,
            current_resource: null,
            custom_headers: ["open_theme"],
            internal_tab: null,
            theme_resources: ["themes", "theme_links", "monaco"],
            theme: null,
            theme_locales: null
        }
    },
    methods: {
        open_theme(item) {
            let url = `${process.env.VUE_APP_BACKEND_URL}/api/themes/render/${item._id}`
            window.open(url)
        },
        open_theme_page(item) {
            let url = `${process.env.VUE_APP_BACKEND_URL}/api/themes/render/${item.theme}/${item.file_info.base_name}`
            window.open(url)
        },
        open_theme_section(item) {
            let url = `${process.env.VUE_APP_BACKEND_URL}/api/themes/render_section/${item.theme}/${item.file_info.base_name}`
            window.open(url)
        },
        async load_data() {
            this.theme = await this.api_get_items({
                resource: "themes",
                lookup: {_id: this.theme_id},
                first: true
            })
            if (!this.theme) {
                this.$toasted.error("Could not find theme")
                return
            }

            this.theme_locales = await this.api_get_items({
                    resource: "theme_locales",
                    lookup: {
                        theme: this.theme_id,
                        name: "en.default.schema.json",
                        is_default: true
                    },
                    first: true
                }
            )
        }
    },
    computed: {
        default_lookup() {
            if (this.theme?._id) {
                return {theme: this.theme._id}
            }
            return {theme: "invalid"}
        }
    },
    async created() {
        if (this.tab === null) {
            this.internal_tab = "themes"
        }
        await this.load_data()
        this.internal_tab = this.tab
    }
}
</script>


<style scoped>

</style>