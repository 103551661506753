<template>
    <v-row justify="center">
        <v-col align="center" cols="12" sm="10" md="8" lg="4">
            <v-card v-if="loaded">
                <v-card-title>
                    {{ app_settings?.confirmation_title?.[locale] }}
                </v-card-title>

                <v-card-subtitle class="mt-2">
                    {{ app_settings?.confirmation_text?.[locale] }}
                </v-card-subtitle>
                <v-card-text>
                    <p :align="'start'">{{ translate("service", true) }}</p>
                    <v-list dense>
                        <v-list-item v-for="(service, index) in form.services" v-bind:key="index" dense>
                            <v-list-item-content>
                                <div>
                                    <p style="float: left">
                                        {{ service?.locale?.[locale] ? service?.locale?.[locale] : service.name }}
                                    </p>
                                    <p style="float: right">
                                        {{ service.amount }} kr
                                    </p>
                                </div>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <br>
                    <p :align="'start'" v-if="form.products.length">{{ translate("products", true) }}</p>
                    <v-list dense>
                        <v-list-item v-for="(product, index) in form.products" v-bind:key="index">
                            <v-list-item-content>
                                <div>
                                    <p style="float: left">
                                        <template v-if="product.number_of_items !== 1 " style="text-align: left">
                                            {{ product.number_of_items }} st
                                        </template>
                                        {{ product?.locale?.[locale] ? product?.locale?.[locale] : product.name }}
                                    </p>


                                    <p style="float: right">
                                        {{ product.amount }} kr
                                    </p>
                                </div>


                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <br>
                    <p :align="'start'"
                       v-for="(contact_field, index) in contact_info" v-bind:key="index"
                    >
                        {{ translate(contact_field.locale, true) }}:
                        {{ contact_field.value }}
                    </p>
                    <p :align="'start'">{{ translate("reference_number") }} {{ form?.reference_number }} </p>
                    <p :align="'start'">{{ translate("status") }}
                        {{ form?.status ? form?.status : translate('no_status', true) }} </p>
                    <p :align="'start'"> {{ translate("total", true) }}: {{ form.total }} kr</p>

                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import {api_mixin} from "@/shared/mixins/api_mixin";
import {layout_mixin} from "@/shared/mixins/layout";
import {dict_mixin} from "@/shared/mixins/dict_mixin";

export default {
    name: "ConfirmationPage",
    mixins: [api_mixin, layout_mixin, dict_mixin],
    props: {
        form_id: {
            type: String,
            required: true
        },
        app_id: {
            type: String,
            required: true
        },
        auth: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            form: {},
            app: {},
            loaded: false
        }
    },
    computed: {
        locale() {
            return this.$i18n.locale
        },
        app_settings() {
            return this.deep_get(this.app, `settings.${this.app.type}`)
        },
        contact_info() {
            let contact_field = "customer"
            let tmp = []
            if (contact_field in this.form) {

                for (let key of Object.keys(this.form[contact_field])) {
                    if (['ip_address', 'url', "customer", "status"].includes(key)) {
                        continue
                    }
                    tmp.push({
                        locale: key,
                        value: this.form[contact_field][key]
                    })
                }
            }
            return tmp
        }
    },
    async created() {
        this.form = await this.api_get_items(
            {
                resource: "forms",
                lookup: {
                    "_id": this.form_id,
                    "app": this.app_id
                },
                first: true,
                auth: this.auth
            }
        )
        this.app = await this.api_get_items(
            {
                resource: "apps",
                lookup: {
                    "_id": this.app_id,
                },
                first: true,
                auth: this.auth
            }
        )
        this.loaded = true
    }
}
</script>

<style scoped>

</style>