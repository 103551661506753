<template>
    <div>
        <InputFileUpload
            v-if="custom_component === 'media_file'"
            v-model="internal_value"
            :dotted_path="dotted_path"
            :field="domain_settings"
            :disabled="disabled"
        />
        <InputFileUploadList
            v-else-if="custom_component === 'media_file_list'"
            v-model="internal_value"
            :dotted_path="dotted_path"
            :field="domain_settings"
            :disabled="disabled"
        />
        <InputTextArea
            v-else-if="custom_component === 'text_area'"
            v-model="internal_value"
            :dotted_path="dotted_path"
            :field="domain_settings"
            :disabled="disabled"
        />
        <InputJsonViewer
            v-else-if="custom_component === 'json_viewer'"
            v-model="internal_value"
            :dotted_path="dotted_path"
            :field="domain_settings"
            :disabled="disabled"
        />
        <InputColorPicker
            v-else-if="custom_component === 'color_picker'"
            v-model="internal_value"
            :dotted_path="dotted_path"
            :field="domain_settings"
            :disabled="disabled"
        />
        <InputHtmlViewer
            v-else-if="custom_component === 'html'"
            v-model="internal_value"
        />
        <Component
            v-else-if="custom_component in project_custom_components"
            :is="project_custom_components[custom_component]"
            v-model="internal_value"
            :dotted_path="dotted_path"
            :field="domain_settings"
            :disabled="disabled"
        />
        <p v-else>
            Invalid custom component <{{ custom_component }}> <{{ Object.keys(project_custom_components) }}>
        </p>

    </div>
</template>

<script>
import InputFileUpload from "./input_file_upload.vue";
import InputFileUploadList from "./input_file_upload_list.vue";
import InputTextArea from "./input_text_area.vue";
import InputJsonViewer from "./input_json_viewer.vue";
import {mapGetters} from "vuex";
import {custom_components} from "@/views/custom_components";
import InputColorPicker from "@/shared/components/dialogs/custom_components/input_color_picker.vue";
import InputHtmlViewer from "@/shared/components/dialogs/custom_components/input_html_viewer.vue";

export default {
    name: "custom-component",
    components: {
        InputHtmlViewer,
        InputColorPicker,
        InputJsonViewer,
        InputTextArea,
        InputFileUploadList,
        InputFileUpload
    },
    props: {
        value: {},
        custom_component: {
            type: String,
            required: true
        },
        dotted_path: {
            type: String,
            required: true
        },
        domain_settings: {
            type: Object,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            internal_value: null,
            project_custom_components: custom_components
        }
    },
    watch: {
        value(val) {
            this.internal_value = val
        },
        internal_value(val) {
            if (val !== null) {
                this.$emit("input", val)
            }
        },
    },
    computed: {
        ...mapGetters({
                current_mode: "dialog/current_mode",
                current_item: "dialog/current_item"
            }
        ),
    },
    created() {
        this.internal_value = this.value
    }
}
</script>

<style scoped>

</style>