<template>
    <div>
        <v-autocomplete
            v-model="internal_value"
            :label="label"
            :items="internal_items"
            :item-text="itemText"
            :item-value="itemValue"
            :disabled="disabled"
            :multiple="multiple"
            :search-input.sync="search_input"
            :loading="loading"
            clearable
        >
            <template #selection="{item}">
                <slot name="selection" :item="item">
                    <v-chip small>
                        {{ deep_get(item, itemText) }}
                    </v-chip>
                </slot>
            </template>
            <template #item="{item}">
                <slot name="item" :item="item">
                    {{ deep_get(item, itemText) }}
                </slot>
            </template>
        </v-autocomplete>
    </div>
</template>
<script>
import {api_mixin} from "@/shared/mixins/api_mixin";
import {mapActions, mapGetters} from "vuex";
import {layout_mixin} from "@/shared/mixins/layout";
import {dict_mixin} from "@/shared/mixins/dict_mixin";

export default {
    name: 'InputAutoComplete',
    mixins: [api_mixin, layout_mixin, dict_mixin],
    props: {
        value: {},
        label: {
            type: String,
            default: ""
        },
        items: {
            type: Array,
            default: () => []
        },
        resource: {
            type: String,
            default: null
        },
        lookup: {
            type: Object,
            default() {
                return {}
            }
        },
        itemText: {
            type: String,
            default: 'label'
        },
        itemValue: {
            type: String,
            default: '_id'
        },
        clearable: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        search_fields: {
            type: Array,
            default() {
                return []
            }
        }

    },
    data: function () {
        return {
            internal_items: [],
            internal_value: undefined,
            internal_resource: undefined,
            search_input: "",
            loading: false
        }
    },
    watch: {
        value(val) {
            if (this.multiple && !Array.isArray(val)) {
                this.internal_value = []
            } else {
                this.internal_value = val
            }
            this.get_related_options()
        },
        internal_value(val) {
            this.$emit("input", val)
        },
        async search_input() {
            await this.debounce(
                this.get_related_options(),
                400
            )
        }
    },
    computed: {
        ...mapGetters({
            current_locked_data: "dialog/current_locked_data",
            current_resource: "dialog/current_resource",
            current_item: "dialog/current_item",

        }),
    },
    methods: {
        ...mapActions({
            set_resource: "dialog/set_resource",
            open_item: "dialog/open_item",
        }),
        sort_function(a, b) {
            let x, y
            if (a.name === undefined) {
                x = a._created
                y = b._created
            } else {
                x = a.name.toLowerCase();
                y = b.name.toLowerCase();
            }

            //compare the word which is comes first
            if (x > y) {
                return 1;
            }
            if (x < y) {
                return -1;
            }
            return 0;
        },
        async create_related_item() {
            let default_data_copy = {...this.current_locked_data}
            let locked_data_copy = {...this.current_locked_data}
            await this.open_item({
                    resource: this.internal_resource,
                    resource_id: null,
                    item: default_data_copy,
                    mode: "create",
                    locked_data: locked_data_copy,
                    dialog_settings: this.dialog_settings,
                    open_on_close: this.current_item
                }
            )
        },
        regex_option() {
            let regex = {"$or": []}
            for (let search_field of this.search_fields) {
                regex["$or"].push({[search_field]: {"$regex": this.search_input}})
            }
            return regex
        },
        async get_related_options() {
            this.internal_loading = true
            let lookup_from_schema = {}
            if (this.lookup.filter) {
                lookup_from_schema = this.lookup.filter
            }
            if (this.lookup.params) {
                for (let param of Object.keys(this.lookup.params)) {
                    if (this.current_locked_data[this.lookup.params[param]] === undefined) {
                        throw `Missing ${param} from current locked data (input_auto_complete)`
                    }
                    lookup_from_schema[param] = this.current_locked_data[this.lookup.params[param]]
                }
            }

            let lookup_and = []
            let lookup_complement = {}
            if (this.search_fields.length !== 0) {
                if (this.search_input !== null && this.search_input.length !== 0) {
                    lookup_and.push(this.regex_option())
                }
            }
            if (Object.keys(lookup_from_schema).length) {
                lookup_and.push(lookup_from_schema)
            }
            if (this.multiple && this.internal_value.length > 0) {
                lookup_and.push({"_id": {"$nin": this.internal_value}})
                lookup_complement = {"_id": {"$in": this.internal_value}}

            } else if (!this.multiple && !!this.internal_value) {
                lookup_and.push({"_id": {"$ne": this.internal_value}})
                lookup_complement = {"_id": this.internal_value}
            }
            let tmp = []
            if (!this.disabled) {
                let lookup_tmp
                if (lookup_and.length) {
                    lookup_tmp = {"$and": lookup_and}
                } else {
                    lookup_tmp = {}
                }
                tmp = tmp.concat(
                    await this.api_get_items({
                            resource: this.internal_resource,
                            lookup: lookup_tmp,
                            sort: {},
                            projection: {[this.itemText]: 1},
                        }
                    )
                )
            }
            if (Object.keys(lookup_complement).length) {
                tmp = tmp.concat(
                    await this.api_get_items({
                            resource: this.internal_resource,
                            lookup: lookup_complement,
                            sort: {},
                            projection: {[this.itemText]: 1},
                        }
                    )
                )

            }
            tmp.sort(this.sort_function)
            this.internal_items = tmp
            this.internal_loading = false

        }
    },
    async created() {
        if (this.multiple && !Array.isArray(this.value)) {
            this.internal_value = []
        } else {
            this.internal_value = this.value
        }

        this.internal_resource = this.lookup.resource ? this.lookup.resource : this.resource
        if (this.resource) {
            await this.set_resource(this.resource)
            if (this.items.length) {
                console.log("items", this.items)
                throw new Error(`Can't have both items and data_relation resource ${this.resource} items: ${this.items}`)
            }
            await this.get_related_options()
        } else {
            if (!this.items) {
                throw "Missing resource or items"
            }
            this.internal_items = this.items
        }
    }
}
</script>