<template>
    <div>
        <p> Color schema picker todo</p>
    </div>
</template>


<script>
export default {
    name: "ColorSchemePicker"
}
</script>

<style scoped>

</style>