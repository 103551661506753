<template>
    <div>
        <inde-table-view resource="syncogs_listings">

        </inde-table-view>
        <v-btn :to="settings_route">
            Settings
        </v-btn>
    </div>
</template>


<script>
import {mapActions} from "vuex";
import IndeTableView from "@/shared/components/view/inde_table_view.vue";

export default {
    name: "SyncogsListings",
    components: {IndeTableView},
    props: {
        company_id: {
            type: String,
            required: true
        },
        app_id: {
            type: String,
            required: true
        }
    },
    methods: {},
    computed: {
        settings_route() {
            return {
                name: "SyncogsSettings",
                params: {
                    "app_id": this.app_id,
                    "company_id": this.company_id
                }
            }

        }
    }
}
</script>

<style scoped>

</style>