export let variable_store = {
    namespaced: true,
    state() {
        return {
            links: {
                common: [
                    {
                        name: "Companies",
                        url: "/c",
                    }
                ],
                admin: [],
                extra: []
            }
        }
    },

}