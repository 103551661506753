<template>
    <div>
        <v-dialog v-model="status_update_dialog" width="unset" overlay-opacity="0">
            <v-card>
                <v-card-title>
                    What new status?
                </v-card-title>
                <v-card-text>
                    <v-select v-model="status" :items="allowed_statuses">
                        <template #selection="{item}">
                            {{ translate(item, true) }}
                        </template>
                        <template #item="{item}">
                            {{ translate(item, true) }}
                        </template>
                    </v-select>
                    <v-checkbox v-model="send_email" label="Send email?">
                    </v-checkbox>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="status_update_dialog = false">
                        {{ translate('cancel', true) }}
                    </v-btn>
                    <v-btn
                        @click="save_status"
                        :loading="request_loading"
                        :disabled="request_loading || status === form.status">
                        {{ translate('save', true) }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-btn
            @click.stop="status_update_dialog = true"
            :small="small"
            :icon="icon">
            <v-icon v-if="icon">
                mdi-list-status
            </v-icon>
            <template v-else> {{ translate("update_status") }}</template>
        </v-btn>
    </div>

</template>

<script>
import {api_mixin} from "@/shared/mixins/api_mixin";
import {layout_mixin} from "@/shared/mixins/layout";
import Spacing from "@/shared/components/helper/Spacing.vue";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "UpdateFormStatus",
    components: {Spacing},
    mixins: [api_mixin, layout_mixin],
    props: {
        value: {
            type: Boolean
        },
        form: {
            type: Object,
            required: true
        },
        allowed_statuses: {
            type: Array,
            required: true
        },
        icon: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        },


    },
    data() {
        return {
            status_update_dialog: false,
            send_email: true,
            status: null,
            request_loading: false
        }
    },
    computed: {
        ...mapGetters({
            current_item: "dialog/current_item",
        }),
    },
    watch: {
        "form.status": {
            handler(val) {
                this.status = val
            }
        }
    },
    methods: {
        ...mapActions({
            reload_item: "dialog/reload_item",
            change_refresh_resource: "dialog/change_refresh_resource",
            change_item_lazy: "dialog/change_item"
        }),
        async save_status() {
            this.request_loading = true
            let data = {
                _id: this.form._id,
                _etag: this.form._etag,
                status: this.status,
                send_email: this.send_email

            }
            let res = await this.api_post(
                {
                    resource: "forms/update_status",
                    data,
                    remove_id: false
                }
            )
            this.toast_results(res, "status_updated")
            this.request_loading = false
            this.status_update_dialog = false
            await this.reload_item({resource: "forms", resource_id: this.form._id})
            await this.change_refresh_resource({resource: "forms", value: true})
            await this.change_refresh_resource({resource: "form_logs", value: true})
            this.request_loading = false
        },
    },
    created() {
        this.status = this.form.status
    }
}
</script>

<style scoped>

</style>