<template>
    <div>
        <v-dialog v-model="show_dialog" width="unset" overlay-opacity="0">
            <v-card>
                <v-card-title>
                    {{ translate("add_note") }}
                </v-card-title>
                <v-card-text>
                    <v-textarea v-model="message"></v-textarea>
                </v-card-text>
                <v-card-actions>
                    <button-shadow @click="show_dialog = false">
                        {{ translate('cancel', true) }}
                    </button-shadow>
                    <button-shadow @click="save_note" :disabled="!message">
                        {{ translate('save', true) }}
                    </button-shadow>
                </v-card-actions>
            </v-card>

        </v-dialog>
        <v-btn @click="show_dialog = true" :icon="icon" :class="{'primary': !icon}">
            <v-icon v-if="icon" :small="small">
                mdi-plus
            </v-icon>
            <template v-else>
                {{ translate('add_note') }}
            </template>
        </v-btn>
    </div>
</template>

<script>
import {api_mixin} from "@/shared/mixins/api_mixin";
import {layout_mixin} from "@/shared/mixins/layout";
import Spacing from "@/shared/components/helper/Spacing.vue";
import {mapActions, mapGetters} from "vuex";
import ButtonShadow from "@/shared/components/inde_components/button_shadow.vue";

export default {
    name: "AddInternalNote",
    components: {ButtonShadow, Spacing},
    mixins: [api_mixin, layout_mixin],
    props: {
        form: {
            type: Object
        },
        icon: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            show_dialog: false,
            message: null
        }
    },
    computed: {
        ...mapGetters({current_item: "dialog/current_item"})
    },
    methods: {
        ...mapActions({
            reload_item: "dialog/reload_item",
            change_refresh_resource: "dialog/change_refresh_resource"
        }),
        async save_note() {
            if (this.message === null) {
                return
            }
            let data = {
                _id: this.form["_id"],
                _etag: this.form["_etag"],
                message: this.message
            }
            let res = await this.api_post(
                {
                    resource: "forms/add_note",
                    data: data,
                    remove_id: false
                }
            )
            this.toast_results(res, "note_saved")
            this.show_dialog = false
            await this.reload_item({resource: "forms", resource_id: this.form._id})
            await this.change_refresh_resource({resource: "forms", value: true})
            await this.change_refresh_resource({resource: "form_logs", value: true})
            this.message = ""
        },
    },
    created() {
    }
}
</script>

<style scoped>

</style>