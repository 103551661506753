<template>
    <v-card class="inde_flex_container" :elevation="elevation">
        <v-card-title class="inde_flex_header">
            <v-row>
                <v-col align="left">
                    <slot name="tool_bar_left"></slot>
                </v-col>
                <v-col align="right">
                    <slot name="tool_bar_right"></slot>
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-text class="inde_flex_content">
            <slot name="main"></slot>
        </v-card-text>
        <v-card-actions class="inde_flex_footer">
            <slot name="actions"></slot>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: "inde-dialog-layout",
    props: {
        elevation: {
            default: 1
        }
    }
}
</script>

<style scoped>

</style>