<template>
    <div id="app">
        <v-app>
            <LeftNavigation v-if="!($route.meta.disable_navbar && current_user === null) "></LeftNavigation>
            <v-main>
                <v-container fluid :class="{'hide': loading_vuex}">
                    <v-row style="min-height: 100vh">
                        <v-col class="pb-16">
                            <router-view/>
                        </v-col>
                    </v-row>
                    <Button_bar v-if="$vuetify.breakpoint.mdAndDown && !$route.meta.disable_navbar"/>
                </v-container>
            </v-main>
        </v-app>
        <Loading v-if="loading_vuex"/>
        <IndeDialog/>
        <DeleteDialog/>
    </div>
</template>

<script>
import TopBar from "@/shared/components/layout/TopBar";
import Loading from "@/shared/components/loading";
import {token_mixin} from "@/shared/mixins/token";
import {mapActions, mapGetters} from "vuex";
import {layout_mixin} from "@/shared/mixins/layout";
import IndeDialog from "@/shared/components/dialogs/inde_dialog.vue";
import DeleteDialog from "@/shared/components/dialogs/delete_dialog.vue";
import {api_mixin} from "@/shared/mixins/api_mixin";
import LeftNavigation from "@/views/components/layout/LeftNavigation.vue";
import Button_bar from "@/views/components/layout/BottonBar";
import {handle_login_app_vue} from "@/shared/helpers/login";

export default {
    name: 'App',
    mixins: [token_mixin, layout_mixin, api_mixin],
    components: {
        LeftNavigation,
        DeleteDialog,
        IndeDialog,
        Button_bar,
        Loading,
        TopBar
    },

    computed: {
        ...mapGetters({
            current_user: "auth/current_user",
            loading_vuex: "dialog/loading"
        })
    },
    methods: {
        ...mapActions({
                set_loading: "dialog/set_loading",
                logout: "auth/logout",
                load_user: "auth/load_user",
                refresh_token: "auth/refresh_token",
                load_user_settings: "auth/load_user_settings",
                load_current_company: "navigation/load_current_company",
            }
        ),
        handle_login_app_vue
    },
    async created() {
        let load_user = await this.handle_login_app_vue(this)
        if (load_user) {
            await this.load_current_company()
        }
        await this.set_loading(false)

    }
}
</script>

<style lang="css">
.hide {
    display: none;
}
</style>
