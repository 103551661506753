<template>
    <div>
        <v-dialog v-model="email_dialog" width="unset" overlay-opacity="0">
            <v-card min-width="400px">
                <v-card-text>
                    <v-col cols="12" sm="12" md="12">
                        <p class="text-subtitle-1"> Email log</p>
                        <v-list v-if="Array.isArray(form?.email_distributions)" dense>
                            <div v-for="email_distribution in form.email_distributions"
                                 v-bind:key="email_distribution">
                                <OpenEmail :email_distribution_id="email_distribution"/>
                            </div>
                        </v-list>
                        <p v-else>
                            No email sent
                        </p>
                    </v-col>
                </v-card-text>
            </v-card>

        </v-dialog>
        <v-badge
            bordered
            :value="!!(form?.email_distributions && !hide_badge)"
            :offset-x="29"
        >
            <template #badge>
                {{ form?.email_distributions?.length }}
            </template>
            <v-btn @click.stop="email_dialog = true" :small="small" :icon="icon">
                <v-icon v-if="icon">
                    mdi-email
                </v-icon>
                <template v-else> {{ translate("email_distributions") }}</template>
            </v-btn>
        </v-badge>

    </div>

</template>

<script>
import OpenEmail from "@/views/components/form/view_booking/open_email.vue";
import {layout_mixin} from "@/shared/mixins/layout";

export default {
    name: "email_distribution",
    mixins: [layout_mixin],
    components: {OpenEmail},
    props: {
        form: {
            type: Object,
            required: true
        },
        icon: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        },
        hide_badge: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            email_dialog: false
        }
    },
    created() {
    }
}
</script>

<style scoped>

</style>