import { render, staticRenderFns } from "./RequestCreate.vue?vue&type=template&id=5b075070&scoped=true"
import script from "./RequestCreate.vue?vue&type=script&lang=js"
export * from "./RequestCreate.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b075070",
  null
  
)

export default component.exports