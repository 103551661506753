<template>
    <div>
        <!-- TODO you should be able to choose from already uploaded images  -->
        {{ label }}
        <v-file-input
            v-if="!internal_value?.id"
            v-model="file_to_upload"
            :accept="allowed_formats"
            :label=label
            @change="on_file_change"
            :clearable="false"
            style="width: 200px"
        ></v-file-input>
        <div v-if="!!internal_value?.id">
            <v-img :src="internal_value.file" alt="" max-width="100px" max-height="100px"/>
            <v-img v-if="file_url" :src="file_url" alt="" max-width="100px" max-height="100px"/>
            <v-btn @click="remove_file" small icon>
                <v-icon>mdi-delete</v-icon>
            </v-btn>
        </div>
        <v-dialog v-model="open_file" max-width="500px">
            <v-img v-if="file_url " :src="file_url">
            </v-img>
        </v-dialog>
    </div>
</template>

<script>
import {api_mixin} from "@/shared/mixins/api_mixin";
import {mapGetters} from "vuex";
import IndeCardView from "@/shared/components/view/inde_card_view.vue";
import {DataType} from "@/shared/mixins/schema";

export default {
    name: "ThemeImagePicker",
    components: {IndeCardView},
    mixins: [api_mixin],
    props: {
        value: {
            type: Object,
            default: null
        },
        label: {type: String},
        allowed_formats: {type: Array},
        settings: {type: Object}
    },
    data() {
        return {
            internal_value: null,
            file_to_upload: {},
            new_file: false,
            add_file: false,
            file_url: null,
            open_file: false,
            images: []
        }
    },
    watch: {
        value(val) {
            this.internal_value = val
        },
        internal_value(val) {
            this.$emit("input", val)
        },

    },
    computed: {
        ...mapGetters({
            current_item: "dialog/current_item",
            current_resource: "dialog/current_resource",
            current_mode: "dialog/current_mode",

        }),
    },

    methods: {
        async get_image_url() {
            let tmp = await this.api_get(
                {
                    endpoint: `media_url/theme_assets/${this.internal_value.id}`
                }
            )
            if (tmp._status === "OK") {
                this.file_url = tmp.url
            }
        },
        on_file_change(file) {
            this.create_image(file);
        },
        remove_file() {
            this.internal_value = {}
        },
        create_image(file) {
            let reader = new FileReader();
            reader.onload = (event) => {
                const file_url = event.target.result;
                this.internal_value = {
                    "name": file.name,
                    "size": file.size,
                    "content_type": file.type,
                    "file": file_url
                };

            }
            reader.readAsDataURL(file);
        },
    },
    async created() {
        if (this.value === undefined) {
            this.internal_value = {}
        } else {
            this.internal_value = this.value

        }
        if (this.internal_value?.id) {
            await this.get_image_url()
        }
    },

}
</script>

<style scoped>

</style>