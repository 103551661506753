<template>
    <div>
        {{ type }}
        <input-color-picker v-model="internal_value" :field="{label: settings.name}" :label="label">

        </input-color-picker>

    </div>
</template>

<script>
import InputColorPicker from "@/shared/components/dialogs/custom_components/input_color_picker.vue";

export default {
    name: "color_picker",
    components: {InputColorPicker},
    props: {
        settings: {
            type: Object,
            required: true
        },
        label: {
            type: String
        },
        type: {
            type: String
        }
    },
    data() {
        return {
            internal_value: null
        }
    }
}
</script>


<style scoped>

</style>