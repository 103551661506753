<template>
    <inde-dialog-layout>
        <template #main>
            <div>
                <vue-monaco-editor
                    style="min-height: 400px"
                    v-model="internal_value"
                    theme="vs-dark"
                    :options="options"
                    :language="language"
                    height="80vh"
                />
            </div>
        </template>
    </inde-dialog-layout>
</template>
<script>
import IndeDialogLayout from "@/shared/components/other/inde_dialog_layout.vue";
import {layout_mixin} from "@/shared/mixins/layout";
import {api_mixin} from "@/shared/mixins/api_mixin";

const options = {
    automaticLayout: true,
    formatOnType: true,
    formatOnPaste: true,
}
export default {
    name: "monaco",
    mixins: [layout_mixin, api_mixin],
    components: {IndeDialogLayout},
    props: {
        value: {
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false
        },
        dotted_path: {
            type: String
        },
        field: {
            type: Object
        },
        reset_amount: {
            type: Boolean,
            default: false
        },
        content_type: {
            type: String
        }
    },
    data() {
        return {
            monaco: null,
            internal_value: null,
            options,
            loaded: false

        }
    },
    watch: {
        value(val) {
            if (val) {
                this.internal_value = val
            }
        },
        internal_value(val) {
            this.$emit("input", val)
        },
    },
    methods: {
        handleMount(val) {
            console.log(val, "handlemount")
        },
    },
    computed: {
        language() {
            let mapper = {
                "application/json": "json",
                "application/liquid": "liquid"
            }
            if (this.content_type in mapper) {
                return mapper[this.content_type]
            }
            return null
        }
    },
    async created() {
        this.internal_value = this.value
    }
}
</script>


<style scoped>

</style>
