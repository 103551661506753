<template>
    <div v-if="!internal_loading">
        <v-col v-for="(setting, index) in blocks_per_id[internal_value.type].settings"
               v-if="!!blocks_per_id[value.type]?.settings"
               v-bind:key="index">
            <v-row>
                <ThemeSettingItem
                    v-model="internal_settings_dict[setting.id]"
                    :name="setting.name"
                    :setting="setting"
                    :label="setting.label"
                    :type="setting.type"
                    :theme_locales="theme_locales"
                />
            </v-row>
        </v-col>
    </div>
</template>

<script>
import {api_mixin} from "@/shared/mixins/api_mixin";
import ThemeSettingItem from "@/views/components/theme/settings/theme_setting_item.vue";
import {layout_mixin} from "@/shared/mixins/layout";
import {dict_mixin} from "@/shared/mixins/dict_mixin";

export default {
    name: "ThemeTemplateBlock",
    components: {ThemeSettingItem},
    mixins: [api_mixin, layout_mixin, dict_mixin],
    props: {
        value: {
            type: [Array, Object],
        },
        section_id: {
            type: String,
            required: true
        },
        theme: {
            type: Object,
            required: true
        },
        theme_locales: {
            type: Object,
            required: true
        },

    },
    data() {
        return {
            internal_value: {},
            internal_section: null,
            internal_settings_dict: {}
        }
    },
    methods: {
        async load_data() {
            this.internal_section = await this.api_get_items({
                resource: "theme_sections",
                lookup: {
                    theme: this.theme["_id"],
                    _id: this.section_id,
                },
                first: true
            })
        },
        get_translation(val) {
            let path = `data.${val.substring(2)}`
            let translation = this.deep_get(this.theme_locales, path)
            if (translation) {
                return translation
            }
            return val
        },
        convert_to_dict(value) {
            let tmp = {}
            for (let setting of value.settings) {
                tmp[setting.name] = setting.value
            }
            return tmp
        },
    },
    computed: {
        blocks_per_id() {
            let tmp = {}
            if (!this.internal_section) {
                return tmp
            }
            for (let block of this.internal_section.blocks) {
                tmp[block.type] = block
            }
            return tmp
        }
    },
    watch: {
        internal_settings_dict: {
            handler(val) {
                let tmp = []
                let should_emit = false
                let value_dict = this.convert_to_dict(this.value)
                for (let setting in val) {
                    tmp.push({name: setting, value: val[setting]})
                    if (val[setting] !== value_dict[setting]) {
                        should_emit = true
                    }
                }
                if (should_emit) {
                    this.internal_value.settings = tmp
                    this.$emit("input", this.internal_value)
                }

            },
            deep: true
        },
        value: {
            async handler(val, old_val) {
                this.internal_value = val
                this.internal_settings_dict = this.convert_to_dict(val)
            },
            deep: true
        },
        async section_id(val, old_val) {
            if (val && val !== old_val) {
                this.internal_loading = true
                await this.load_data()
                this.internal_loading = false

            }
        }
    },

    async created() {
        await this.load_data()
        this.internal_value = this.value
        this.internal_settings_dict = this.convert_to_dict(this.value)
        this.internal_loading = false
    }
}
</script>

<style scoped>

</style>