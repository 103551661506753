export async function handle_login_app_vue(this_app) {
    if (this_app.$router.currentRoute.name === "Activate") {
        return
    }
    if (this_app.user_settings && this_app.user_settings.dark) {
        this_app.$vuetify.theme.dark = this_app.user_settings.dark
    }
    let load_user = true
    if (!this_app.check_token_valid()) {
        // token is not valid
        if (this_app.check_refresh_token_valid()) {
            load_user = await this_app.refresh_token()
        } else {
            // neither token or refresh token is valid
            load_user = false
        }
    } else {
        load_user = true
    }
    if (load_user) {
        await this_app.load_user()
        await this_app.load_user_settings({i18n: this.$i18n, vuetify: this.$vuetify})
    }
    return load_user
}
