import select_dependable_option_form from "./select_dependable_options_form.vue";
import show_if_options_form from "./show_if_options_form.vue";
import email_template from "./email_template.vue";
import forms from "@/views/components/form/view_booking/forms.vue";
import form_logs from "./form_logs.vue";
import form_customer from "@/views/components/form/customers/customer.vue";
import form_inventory_adjustment from "./form_inventory_adjustment.vue";
import monaco from "@/views/components/editor/monaco.vue";

export let custom_components = {
    select_dependable_option_form: select_dependable_option_form,
    show_if_options_form: show_if_options_form,
    email_template,
    forms,
    form_logs,
    form_customer,
    form_inventory_adjustment,
    editor: monaco
}