<template>
    <div>
        <v-textarea
            v-model="internal_value"
            :label="translate(field.name)"
            :rules="get_rules(field)"
            :disabled="disabled"
        >

        </v-textarea>
    </div>
</template>

<script>
import {dialog_mixin} from "@/shared/mixins/dialog_mixin";
import {layout_mixin} from "@/shared/mixins/layout";

export default {
    name: "InputTextArea",
    mixins: [dialog_mixin, layout_mixin],
    data() {
        return {
            internal_value: null
        }
    },
    props: {
        value: {
            type: String
        },
        field: {
            type: Object,
            required: true
        },
        dotted_path: {
            type: String
        },
        label: {
            type: String,
            default: ""
        },
        items: {
            type: Array,
            default: () => []
        },
        resource: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        internal_value(val) {
            this.$emit("input", val)
        }
    },
    created() {
        this.internal_value = this.value
    }
}
</script>

<style scoped>

</style>