<template>
    <div>
        <inde-dialog-layout>
            <template #main>
                <v-row>
                    <v-col>
                        <IndeTableView resource="form_customers" :lookup="computed_lookup">
                            <template #search>
                                <v-col cols="3">
                                    <v-text-field v-model="search" class="mt-6" :label="translate('search')">
                                    </v-text-field>
                                </v-col>
                            </template>
                        </IndeTableView>
                    </v-col>

                </v-row>
            </template>
            <template #actions>

            </template>
        </inde-dialog-layout>
    </div>
</template>

<script>
import IndeDialogLayout from "@/shared/components/other/inde_dialog_layout.vue";
import IndeTableView from "@/shared/components/view/inde_table_view.vue";
import {layout_mixin} from "@/shared/mixins/layout";

export default {
    name: "form-customers",
    components: {IndeTableView, IndeDialogLayout},
    mixins: [layout_mixin],
    props: {
        app_id: {
            type: String
        },
        company_id: {
            type: String
        }
    },
    data() {
        return {
            search: ""
        }
    },
    computed: {
        computed_lookup() {
            let lookup = {
                app: this.app_id,
                company: this.company_id
            }
            if (this.search) {
                lookup["name"] = {"$regex": this.search}
                lookup["email"] = {"$regex": this.search}
            }
            return lookup
        }
    }
}
</script>

<style scoped>

</style>