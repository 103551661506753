<template>
    <div>
        <CustomComponent
            v-model="internal_value"
            v-if="field.custom_component"
            :custom_component="field.custom_component"
            :dotted_path="dotted_path"
            :disabled="internal_disabled"
            :domain_settings="field"
        />
        <input-autocomplete
            v-else-if="field.type === 'objectid'"
            v-model="internal_value"
            :label="translate(field.name)"
            :resource="field.data_relation.resource"
            :item-text="field.data_relation.label"
            :item-value="'_id'"
            :lookup="field.data_relation.lookup"
            :disabled="internal_disabled"
            :field="field"
            :clearable="true"
        />
        <InputSelect
            v-else-if="field.type === 'enum'"
            v-model="internal_value"
            clearable
            item-text="name"
            item-value="_id"
            :lookup="field.lookup"
            :items="field.allowed.map(x => {return {'name': x}})"
            :label="translate(field.name)"
            :disabled="internal_disabled"
            :field="field"
        />

        <v-checkbox
            v-else-if="field.type === 'boolean'"
            v-model="internal_value"
            :label="translate(field.name)"
            :disabled="internal_disabled"
        />
        <!--        <InputSelect-->
        <!--            v-else-if="field.type === 'objectid'"-->
        <!--            v-model="internal_value"-->
        <!--            :label="translate(field.name)"-->
        <!--            :resource="field.data_relation.resource"-->
        <!--            :item-text="field.data_relation.label"-->
        <!--            :item-value="'_id'"-->
        <!--            :lookup="field.data_relation.lookup"-->
        <!--            :disabled="internal_disabled"-->
        <!--            :field="field"-->
        <!--        />-->
        <v-text-field
            v-else-if="['number', 'integer'].includes(field.type)"
            v-model.number="internal_value"
            hide-details
            type="number"
            :label="translate(field.name)"
            :disabled="internal_disabled"

        />
        <v-text-field
            v-else-if="field.type === 'string'"
            v-model="internal_value"
            :label="translate(field.name)"
            :rules="get_rules(field)"
            :disabled="internal_disabled"
        />
        <IndeStepperDate
            v-else-if="field.type === 'datetime'"
            v-model="internal_value"
            :label="translate(field.name)"
            :disabled="internal_disabled"
            :time="!!field.rules?.time"
            date
        />
        <p v-else> Invalid type: {{ field.type }} name: {{ field }} </p>
    </div>
</template>
<script>
import IndeStepperDate from "@/shared/components/dialogs/stepper_date"
import {DataType} from "@/shared/mixins/schema";
import InputSelect from "@/shared/components/dialogs/input_select";
import {mapGetters} from "vuex";
import {dialog_mixin} from "@/shared/mixins/dialog_mixin";
import {layout_mixin} from "@/shared/mixins/layout";
import InputAutocomplete from "@/shared/components/dialogs/input_autocomplete.vue";

export default {
    name: "InputType",
    mixins: [dialog_mixin, layout_mixin],
    components: {
        InputAutocomplete,
        CustomComponent: () => import('@/shared/components/dialogs/custom_components'),
        InputSelect,
        IndeStepperDate
    },
    props: {
        field: {
            type: DataType
        },
        value: {},
        dotted_path: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            internal_value: undefined
        }
    },
    computed: {
        ...mapGetters({
            current_item: "dialog/current_item",
            current_mode: "dialog/current_mode",
            current_locked_data: "dialog/current_locked_data",
        }),
        internal_disabled() {
            if (this.dotted_path in this.current_locked_data) {
                return true
            }
            return this.disabled
        },
    },
    watch: {
        value(val) {
            this.internal_value = val
        },
        internal_value(val) {
            this.$emit("input", val)
        }
    },
    created() {
        this.internal_value = this.value
    }
}
</script>