<template>
    <v-row justify="center">
        <v-col cols="3">
            {{ translate("indehouse.title_1") }} <br>
            {{ translate("indehouse.title_2") }}

            <v-spacer></v-spacer>
            <v-btn class="mt-5" large @click="() => send_to_login({next:$router.currentRoute})">
                {{ translate("login") }}
            </v-btn>
        </v-col>

    </v-row>
</template>

<script>
import {mapActions} from "vuex";
import {layout_mixin} from "@/shared/mixins/layout";

export default {

    name: "NotLoggedIn",
    mixins: [layout_mixin],
    methods: {
        ...mapActions({send_to_login: "auth/send_to_login"})
    },

}
</script>

<style scoped>

</style>