<template>
    <div>
        <v-tabs
            v-model="tab"
            :vertical="!small_screen && !force_horizontal_tabs"
            v-if="number_of_tabs.number !== 1"
        >
            <v-tab v-if="schema_fields.length">
                {{ translate("details") }}
            </v-tab>
            <v-tab-item v-if="schema_fields.length">
                <v-card min-height="30vh">
                    <v-card-text>
                        <IndeCardViewBasic :items="schema_fields" :cols="2">
                            <template #default="{item}">
                                <InputType
                                    :field="item"
                                    :rules="get_rules(item)"
                                    :dotted_path="item.name"
                                    :disabled="internal_disabled"
                                    v-model="internal_value[item.name]"
                                />
                            </template>
                        </IndeCardViewBasic>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab
                v-for="field in schema_object"
                v-bind:key="field.name">
                {{ translate(field.name) }}
            </v-tab>
            <v-tab-item
                v-for="field in schema_object"
                v-bind:key="field.name"
            >
                <v-card min-height="30vh">
                    <v-card-text>
                        <InputObjectView
                            v-model="internal_value"
                            :field="field"
                            :disabled="disabled"
                            field_type="dict"
                        />
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab
                v-for="field in schema_list"
                v-bind:key="field.name"
            >
                {{ translate(field.name) }}
            </v-tab>
            <v-tab-item
                v-for="field in schema_list"
                v-bind:key="field.name"
            >
                <v-card min-height="30vh" flat>
                    <v-card-text>
                        <InputObjectView
                            v-model="internal_value"
                            :field="field"
                            :disabled="disabled"
                            @save="add = false"
                            field_type="list"
                        />
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab
                v-for="field in schema_custom_components"
                v-bind:key="field.name"
            >
                {{ translate(field.name) }}
            </v-tab>
            <v-tab-item
                v-for="field in schema_custom_components"
                v-bind:key="field.name"
            >
                <InputObjectView
                    v-model="internal_value"
                    :field="field"
                    :disabled="disabled"
                    @save="add = false"
                    field_type="custom"
                />
            </v-tab-item>
        </v-tabs>
        <v-card v-else-if="number_of_tabs.schema_type === 'root'" min-height="30vh">
            <v-card-text>
                <IndeCardViewBasic :items="schema_fields_computed" :cols="1" :md="1" :lg="1">
                    <template #default="{item}">
                        <InputType
                            v-model="internal_value[item.name]"
                            :field="item"
                            :rules="get_rules(item)"
                            :dotted_path="item.name"
                            :disabled="disabled"
                        />
                    </template>
                </IndeCardViewBasic>
            </v-card-text>
        </v-card>
        <InputObjectView
            v-else
            v-model="internal_value"
            :field="number_of_tabs.fields[0]"
            :field_type="number_of_tabs.schema_type"
            :disabled="disabled"
            @save="add=false"
        />
    </div>
</template>
<script>


import {layout_mixin} from "@/shared/mixins/layout";
import {mapActions, mapGetters} from "vuex";
import {api_mixin} from "@/shared/mixins/api_mixin";
import {dialog_mixin} from "@/shared/mixins/dialog_mixin";

export default {
    name: 'InputMain',
    components: {
        InputObjectView: () => import("@/shared/components/dialogs/input_object_view.vue"),
        IndeCardViewBasic: () => import('@/shared/components/other/inde_card_view_basic.vue'),
        InputDictDialog: () => import('./input_dict_dialog'),
        InputType: () => import('./input_type'),
        InputListDialog: () => import('./input_list_dialog'),
        CustomComponent: () => import('./custom_components/index.vue')
    },
    mixins: [layout_mixin, api_mixin, dialog_mixin],
    props: {
        domain_settings: {
            type: Object,
            required: true
        },
        value: {
            type: [Object, Array]
        },
        list: {
            type: Boolean,
            default: false
        },
        dotted_path: {
            type: String
        },
        force_horizontal_tabs: {
            type: Boolean,
            default: false
        },
        locked_data: {
            type: Object,
            default: function () {
                return {}
            }
            // this is not yet implemented, and we are still using the store
        },
        disabled: {
            type: Boolean,
            default: null
        }
    },
    data() {
        return {
            internal_value: undefined,
            add: false,
            list_value: {},
        }
    },

    watch: {
        value(val) {
            this.internal_value = val
        },
        internal_value: {
            handler(val) {
                // main
                this.$emit("input", val)
            },
            deep: true
        },

    },
    methods: {
        ...mapActions({
                change_tab: "dialog/change_tab"
            }
        )
    },
    computed: {
        ...mapGetters({
            current_item: "dialog/current_item",
            current_mode: "dialog/current_mode",
            current_tab: "dialog/current_tab"
        }),
        type() {
            return this.domain_settings.schema.type
        },
        tab: {
            get() {
                return this.current_tab
            },
            set(val) {
                if (!this.force_horizontal_tabs) {
                    if (val !== this.tab) {
                        this.change_tab(val)
                    }
                }
            }
        },
        schema_fields_computed() {
            if (this.disabled) {
                return this.schema_fields.filter(x => this.internal_value[x.name])
            }
            return this.schema_fields
        },
        internal_disabled() {
            if (this.disabled !== null) {
                return this.current_mode === 'view'
            }
            return this.disabled
        },
    },
    created() {
        if (this.value === undefined) {
            if (this.list) {
                this.internal_value = []
            } else {
                this.internal_value = {}
            }
        } else {
            this.internal_value = this.value
        }
    }
}
</script>