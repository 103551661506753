<template>
    <div>
        <v-timeline>
            <v-timeline-item
                v-for="(status, index) in allowed_statuses"
                v-bind:key="status"
                :class="{'text-right': index % 2 !== 0, 'text-left': index % 2 === 0}"
                :color="get_status_color(status)"
            >
                <p class="mt-4">
                    {{ translate(status, true) }}<br>
                    <span v-if="form?.status_date?.[status]">
                        {{ format(form?.status_date?.[status]) }}
                    </span>
                </p>

            </v-timeline-item>
        </v-timeline>
        <UpdateStatus
            :allowed_statuses="domain_settings.forms.schema.status.allowed"
            :form="form"
            icon
        />
    </div>

</template>
<script>
import UpdateStatus from "@/views/components/form/view_booking/update_status.vue";
import {layout_mixin} from "@/shared/mixins/layout";
import {mapGetters} from "vuex";

export default {
    name: 'StatusTimeline',
    mixins: [layout_mixin],
    components: {UpdateStatus},
    props: {
        form: {
            type: Object
        }
    },
    computed: {
        ...mapGetters({domain_settings: "dialog/domain_settings"}),
        allowed_statuses() {
            let statuses = this.domain_settings.forms.schema.status.allowed
            let new_statues = []
            if (this.form.status === "cancelled") {
                new_statues = ["cancelled"]
            }
            for (let status of statuses) {
                if (status !== "cancelled") {
                    new_statues.push(status)
                }
            }
            return new_statues
        }
    },
    methods: {
        get_status_color(status) {

            let current_status = this.form.status
            if (current_status === status && status === "cancelled") {
                return "grey"
            }
            let index_of_current_status = this.allowed_statuses.indexOf(current_status)
            let index_of_status = this.allowed_statuses.indexOf(status)
            return index_of_current_status < index_of_status ? "white" : "green"
        }
    }
}
</script>