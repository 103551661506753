<template>
    <div>
        <v-navigation-drawer
            app
            disable-resize-watcher
            :mini-variant.sync="mini"
            hide-overlay
            v-model="open"
            :right="right"
            height="100vh"
        >
            <div style="height: 100vh" class="inde_flex_container">
                <div class="inde_flex_header">
                    <v-list elevation="3">
                        <v-list-item class="px-2 ml-1 pl-1" v-if="current_user !== null">

                            <v-list-item-avatar v-if="current_user.picture" :size="size">
                                <v-img :src="current_user.picture"></v-img>
                            </v-list-item-avatar>
                            <v-list-item-avatar v-else
                                                :size="size"
                                                :style="{'background-color': 'green'}">
                                <strong class="initials"
                                        :style="{'color': 'white','font-size': size / 40.0 * 17 + 'px'}">
                                    {{ (split_name[0] + split_name[1]) }}
                                </strong>
                            </v-list-item-avatar>
                            <v-list-item-title style="font-size: 1.3rem">
                                {{ current_user.full_name }}
                            </v-list-item-title>
                            <v-btn
                                icon
                                @click.stop="close_on_small_screen(!mini)"
                            >
                                <v-icon>mdi-chevron-left</v-icon>
                            </v-btn>
                        </v-list-item>
                        <v-list-item
                            v-else
                            class="px-2"
                            @click="internal_send_to_login">
                            <v-list-item-title class="text-h6">
                                Login
                            </v-list-item-title>
                            <v-btn
                                icon
                                @click.stop="close_on_small_screen(!mini)"
                            >
                                <v-icon>mdi-chevron-left</v-icon>
                            </v-btn>
                        </v-list-item>
                        <v-list-item
                            v-if="current_company"
                            :to="{name: 'EditCompany', params:{ company_id: current_company._id }}"
                            link
                        >
                            <v-list-item-content>
                                <v-list-item-subtitle>
                                    Space {{ current_company.name }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item link :to="{name: 'Company'}" v-if="current_user !== null">
                            <v-list-item-content>
                                <v-list-item-subtitle class="text-h10">
                                    Change Space
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>

                </div>

                <div class="inde_flex_content">
                    <v-list
                        v-if="current_rights >0"
                        nav
                        dense
                        style="z-index: 10"
                    >
                        <v-list-group
                            v-for="app in current_apps.filter(x => x.type ==='form')"
                            :key="app._id"
                            :to="app_url(app)"
                        >
                            <template #activator>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title v-text="app.name"></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                            </template>
                            <v-list-item
                                v-for="page in form_pages.filter(x => x.rights <= current_rights)"
                                link
                                :to="app_url(app, page.name)"
                                :key="app._id + page.name"
                            >
                                <v-list-item-icon>
                                    <v-icon>
                                        {{ page.icon }}
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-title v-text="translate(page.name)"></v-list-item-title>
                            </v-list-item>
                        </v-list-group>
                        <v-list-item
                            v-for="app in current_apps.filter(x => x.type ==='theme')"
                            link
                            :to="app_url(app)"
                            :key="app._id"
                        >
                            <v-list-item-icon>
                                <v-icon>
                                    {{ icon_mapping.theme }}
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-title v-text="translate('theme_editor')"></v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            v-for="app in current_apps.filter(x => x.type ==='syncogs')"
                            link
                            :to="app_url(app)"
                            :key="app._id"
                        >
                            <v-list-item-icon>
                                <v-icon>
                                    {{ icon_mapping.theme }}
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-title v-text="translate('syncogs')"></v-list-item-title>
                        </v-list-item>
                    </v-list>
                </div>

                <div class="inde_flex_footer">
                    <v-list>
                        <v-divider></v-divider>
                        <v-list-item>
                            <v-row>
                                <v-col align="center">
                                    <v-btn
                                        icon
                                        v-if="$vuetify.theme.dark"
                                        @click="change_dark_mode({dark: false, vuetify: $vuetify})"
                                    >
                                        <v-icon>
                                            mdi-theme-light-dark
                                        </v-icon>
                                    </v-btn>
                                    <v-btn
                                        icon v-else
                                        @click="change_dark_mode({dark: true, vuetify: $vuetify})"
                                    >
                                        <v-icon>
                                            mdi-theme-light-dark
                                        </v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-list-item>
                        <locale-selector></locale-selector>

                        <v-list-item
                            v-if="current_user !== null"
                            @click="internal_logout"
                        >
                            <v-list-item-title>Log out</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            v-else
                            @click="internal_send_to_login"
                        >
                            <v-list-item-title>Log in</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </div>
            </div>
        </v-navigation-drawer>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {company_app_mixin} from "@/mixins/company_app_mixin";
import LocaleSelector from "@/shared/components/other/locale_selector.vue";
import {layout_mixin} from "@/shared/mixins/layout";

export default {
    name: "left-navigation",
    components: {LocaleSelector},
    mixins: [company_app_mixin, layout_mixin],
    props: {
        value: {
            type: Boolean
        },
        right: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            size: 40,
            icon_mapping: {
                form: "mdi-bicycle",
                booking: "mdi-bicycle",
                hotel: "home-modern",
                theme: "mdi-web",
                syncogs: "mdi-web",
            },
            form_pages: [
                {
                    name: "dashboard",
                    icon: "mdi-chart-areaspline",
                    rights: 1
                },
                // {
                //     name: "inventory",
                //     icon: "mdi-counter",
                //     rights: 10
                // },
                {
                    name: "booking",
                    icon: "mdi-monitor-dashboard",
                    rights: 1
                },
                {
                    name: "settings",
                    icon: "mdi-cog",
                    rights: 10
                },
                {
                    name: "external",
                    icon: "mdi-open-in-new",
                    rights: 1
                },
                {
                    name: "customer",
                    icon: "mdi-account-star",
                    rights: 1
                }
            ]
        }
    },
    watch: {
        user_settings(val) {
            if (val !== null && this.current_company === null) {
                this.load_current_company()
            }
        },
        navigation_open(val) {
            this.open = val
        },
        navigation_mini(val) {
            this.mini = val
        },
        "$vuetify.breakpoint.mdAndUp": {
            handler(val) {
                if (val) {
                    this.change_navigation_open({open: false, mini: false})
                } else {
                    this.change_navigation_open({open: false, mini: false})
                }
            }
        },
        "$vuetify.breakpoint.lgAndUp": {
            handler(val) {
                if (val) {
                    this.change_navigation_open({open: true, mini: false})
                } else {
                    if (this.$vuetify.breakpoint.mdAndUp) {
                        this.change_navigation_open({open: true, mini: true})
                    }

                }
            }
        }

    },
    computed: {
        ...mapGetters({
            current_user: "auth/current_user",
            current_company: "navigation/current_company",
            current_rights: "navigation/current_rights",
            current_apps: "navigation/current_apps",
            navigation_open: "navigation/navigation_open",
            navigation_mini: "navigation/navigation_mini",
            user_settings: "auth/user_settings",
            current_resource: "dialog/current_resource",

        }),
        open: {
            get() {
                return this.navigation_open
            },
            set(val, old_val) {
                this.change_navigation_open({open: val})
            }
        },
        mini: {
            get() {
                return this.navigation_mini
            },
            set(val) {
                this.change_navigation_open({mini: val})
            }
        },
        split_name() {
            if (this.current_user == null) {
                return ["", ""]
            }
            let split = this.current_user?.full_name?.split(" ")
            if (split.length <= 2) {
                return [split[0][0], split[1][0]]
            } else if (split.length === 1) {
                return [split[0], ""]
            }
            return ["", ""]
        }
    },
    methods: {
        ...mapActions({
            change_navigation_open: "navigation/change_navigation_open",
            send_to_login: "auth/send_to_login",
            logout: "auth/logout",
            load_current_company: "navigation/load_current_company",
            change_dark_mode: "auth/change_dark_mode"
        }),
        close_on_small_screen(val) {
            if (val) {
                this.mini = true
                return
            }
            if (this.$vuetify.breakpoint.mdAndDown) {
                this.open = false
            } else {
                this.mini = false
            }
        },
        async internal_logout() {
            await this.logout()
            await this.load_current_company()
            if (!(this.$router.currentRoute.name === "Home")) {
                await this.$router.push({name: "Home"})
            }
        },
        async internal_send_to_login() {
            await this.send_to_login(this.$router.currentRoute)
        }
    },
    created() {
        this.mini = !this.$vuetify.breakpoint.lgAndUp
        this.open = !this.$vuetify.breakpoint.mdAndDown
    }
}
</script>

<style scoped>
.fixed {
    position: fixed;
}

.initials {
    text-transform: uppercase;
}
</style>