<template>

    <v-row justify="center">
        <v-card v-if="user_pickups.length === 0">
            <v-card-title>
                {{ translate("indehouse.no_pickups") }}
            </v-card-title>
        </v-card>
        <v-card v-else>
            <v-card-title>
                {{ translate("indehouse.future_pickups") }}
            </v-card-title>
            <v-card-text>
                <v-list v-for="(app, app_id) in app_to_app_name_mapping" v-bind:key="app._id">
                    <v-list-item>
                        <v-list-item-title>{{ app.company_name }} - {{ app.app_name }}</v-list-item-title>
                        <v-list-item-action>
                            <v-btn
                                icon
                                :to="go_to_app({company_id: app.company, app_id:app.app})"
                            >

                                <v-icon>
                                    mdi-link
                                </v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider>

                    </v-divider>
                    <v-list-item
                        v-for="pickup in user_pickups_per_app[app_id]"
                        v-bind:key="pickup._id"
                    >
                        <v-list-item-content>
                            {{ translate("common.from", true) }} {{ pickup.from }} {{ translate("common.to") }}
                            {{ pickup.to }}
                            {{ format(pickup.timestamp) }}
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn
                                icon
                                @click='open_item({
                                resource: "pickups",item:pickup , resource_id: pickup._id,
                                locked_data: {company: pickup.company, app:pickup.app}
                                })'
                            >

                                <v-icon>
                                    mdi-magnify-plus
                                </v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-card-text>
        </v-card>
    </v-row>
</template>

<script>
import {layout_mixin} from "@/shared/mixins/layout";
import {booking_mixin} from "@/mixins/booking";
import {api_mixin} from "@/shared/mixins/api_mixin";
import Loading from "@/shared/components/loading.vue";
import {mapActions} from "vuex";
import {now} from "@/shared/helpers/date_helper";

export default {
    name: "MyPickups",
    components: {Loading},
    mixins: [booking_mixin, layout_mixin, api_mixin],
    data: function () {
        return {
            user_pickups: [],
            loading: true
        }
    },
    methods: {
        ...mapActions({
            set_loading: "dialog/set_loading",
            open_item: "dialog/open_item",
            set_resource: "dialog/set_resource"
        }),
        go_to_app({company_id, app_id}) {
            return {
                name: 'PickupApp',
                params: {
                    company_id,
                    app_id
                }
            }
        }
    },
    computed: {
        user_pickups_per_app() {
            let tmp = {}
            for (let pickup of this.user_pickups) {
                if (pickup.app in tmp) {
                    tmp[pickup.app].push(pickup)
                } else {
                    tmp[pickup.app] = [pickup]
                }
            }
            return tmp
        },
        app_to_app_name_mapping() {
            let tmp = {}
            for (let booking of this.user_pickups) {
                if (!(booking.app in tmp)) {
                    tmp[booking.app] = {
                        company_name: booking.company_name,
                        app_name: booking.app_name,
                        app: booking.app,
                        company: booking.company
                    }
                }
            }
            return tmp
        }
    },

    async created() {
        this.user_pickups = await this.api_get_items({
                resource: "pickups",
                lookup: {timestamp: {"$gt": now()}},
                disable_cache: true,
                sort: {timestamp: -1}
            }
        )
        await this.set_resource("pickups")
    }
}
</script>

<style scoped>

</style>