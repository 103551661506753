import {BookingDay} from "@/interfaces";

import store from "@/store";
import {DateTime} from "@/shared/helpers/date_helper";

function default_state() {
    return {
        week: {},
        last_week: null,
        next_week: null,
        monday: {},
        sunday: {},
        bookings: {},
        today: {},
        year: null,
        app_id: null,
        company_id: null,
        data: [],
        dates: [],
        selected: {
            first: null, second: null, hours: {}, current_date: null
        },
    }
}

export let state = {
    week: {},
    last_week: null,
    next_week: null,
    monday: {},
    sunday: {},
    bookings: {},
    today: {},
    year: null,
    app_id: null,
    company_id: null,
    data: [],
    dates: [],
    loading: {dates: true, hours: true},
    selected: {date_from: null, date_to: null, hours: {}, current_date: null},
}

export let mutation = {
    reset_booking(state) {
        Object.assign(state, ...default_state())
        state.loaded = false
    },
    update_selected(state, {key, value}) {
        state.selected[key] = value
    },
    update_booking(state, {today, year, week, next_week, last_week, monday, sunday, dates}) {
        state.today = new DateTime(today)
        state.week = week
        state.year = year
        state.next_week = new DateTime(next_week)
        state.last_week = new DateTime(last_week)
        state.monday = monday
        state.sunday = sunday
        state.dates = dates.map((d) => new BookingDay(d))
        state.selected = {first: null, second: null, hours: {}, current_date: null}
        state.data = []
        state.current_user = store.getters["auth/current_user"]
    },
    update_company(state, {company_id, app_id}) {
        state.app_id = app_id
        state.company_id = company_id
    },
    update_booking_day(state, bookings) {
        state.data = bookings
    },
    insert_hour(state, book) {
        if (book.date_from.hour in state.selected.hours) {
            console.warn(`book ${book.date_from.hour} already in selected hour`)
            return false
        } else {
            book.selected = true
            state.selected.hours[book.date_from.hour] = book
            return true
        }
    },
    clear_selected_hours(state) {
        for (let book in state.selected.hours) {
            state.selected.hours[book].selected = false
        }
        state.selected.hours = {}
        state.selected.date_from = null
        state.selected.date_to = null
    }
}