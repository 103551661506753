<template>
    <div>
        <input-select
            v-model="theme"
            resource="themes"
            item-text="name"
            item-value="_id"
        ></input-select>
        <monaco
            v-model="html"
        />
        <v-btn @click="get_rendered_html" :disabled="!valid">
            run
        </v-btn>
        <div v-html="out_html"></div>

    </div>

</template>

<script>
import InputSelect from "@/shared/components/dialogs/input_select.vue";
import Monaco from "@/views/components/editor/monaco.vue";
import {api_mixin} from "@/shared/mixins/api_mixin";
import InputJsonViewer from "@/shared/components/dialogs/custom_components/input_json_viewer.vue";

export default {
    name: "RenderRaw",
    components: {InputJsonViewer, Monaco, InputSelect},
    mixins: [api_mixin],
    data() {
        return {
            theme: null,
            html: "",
            settings: {},
            out_html: ""

        }
    },
    methods: {
        async get_rendered_html() {
            let res = await this.api_post({
                resource: "themes/render_raw",
                data: {html: this.html, settings: this.settings, theme: this.theme}
            })
            console.log(res)
            this.out_html = res.html
        }
    },
    computed: {
        valid() {
            return !!this.theme && !!this.html
        }
    }
}
</script>


<style scoped>

</style>