import Vue from 'vue'

import App from '@/App.vue'
import router from '@/router'
import store from '@/store/index'
import i18n from '@/shared/i18n'
import {vuetify} from '@/shared/init'
import VueRouter from 'vue-router'
import {install as VueMonacoEditorPlugin} from '@guolao/vue-monaco-editor'
import JsonViewer from 'vue-json-viewer'

Vue.use(JsonViewer)

Vue.use(VueMonacoEditorPlugin, {
    paths: {
        // The recommended CDN config
        vs: 'https://cdn.jsdelivr.net/npm/monaco-editor@0.43.0/min/vs'
    },
})
Vue.use(VueRouter)
new Vue({
    vuetify,
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
