<template>
    <div>
        <h4 v-if="type === 'header'">
            {{ setting.content }}
        </h4>
        <p v-else-if="type === 'paragraph'">
            {{ setting.content }}
        </p>
        <ThemeImagePicker
            v-model="internal_value"
            v-else-if="setting.type === 'image_picker'"
            :label="get_translation(setting.label)"
            :settings="setting"
            :allowed_formats="['png']"
        />
        <ColorPickerGroup
            v-model="internal_value"
            v-else-if="setting.type === 'color_scheme_group'"
            :name="get_translation(setting.label)"
            :settings="setting" :type="setting.type"
        />
        <v-select
            v-model="internal_value"
            v-else-if="type === 'select'"
            :label="get_translation(setting.label)"
            :items="options"
            item-text="label"
            item-value="value"
            clearable
        >
        </v-select>
        <v-checkbox
            v-model="internal_value"
            v-else-if="type === 'checkbox'"
            :label="get_translation(setting.label)"/>

        <v-textarea
            v-model="internal_value"
            v-else-if="type === 'inline_richtext'"
            :label="get_translation(setting.label)"
        />
        <!--        Should be some html templating here-->
        <v-textarea

            v-model="internal_value"
            v-else-if="type === 'richtext'"
            :label="get_translation(label)"
        />
        <v-textarea
            v-model="internal_value"
            v-else-if="type === 'text'"
            :label="get_translation(label)"
        />
        <ColorSchemePicker
            v-model="internal_value"
            v-else-if="type === 'color_scheme'"
            :label="get_translation(label)"
            :settings="setting"
            :type="type"
        />
        <ColorPicker
            v-model="internal_value"
            v-else-if="type === 'color'"
            :label="get_translation(label)"
            :settings="setting"
            :type="type"
        />
        <ColorPicker
            v-model="internal_value"
            v-else-if="type === 'color_background'"
            :label="get_translation(label)"
            :settings="setting"
            :type="type"
        />
        <v-text-field
            v-else-if="type==='video_url'"
            v-model="internal_value"
            :label="get_translation(label)">

        </v-text-field>
        <v-text-field
            v-else-if="type==='url'"
            v-model="internal_value"
            :label="get_translation(label)">
        </v-text-field>
        <v-radio-group
            v-else-if="type === 'radio'"
            v-model="internal_value"
        >
            <v-radio
                v-for="(option,index) in setting.options"
                v-bind:key="index"
                :label="option.label"
                :name="option.value"
                :value="option.value"
            />
        </v-radio-group>

        <div v-else-if="type === 'range'">
            <span style="font-size: small">{{ get_translation(label) }}</span>
            <v-slider
                class="my-0 py-0"
                style="width: 200px;"
                v-model="internal_value"
                :min="Number(setting.min ? setting.min: 0)"
                :max="Number(setting.max)"
                :step="Number(setting.step)"
                thumb-label="thumb-label"
            />
        </div>
        <div v-else>
            Missing type <{{ setting.type }},{{ get_translation(setting.label) }}, {{ internal_value }}>
        </div>
    </div>
</template>

<script>
import ColorPicker from "@/views/components/theme/settings/color_picker.vue";
import ColorPickerGroup from "@/views/components/theme/settings/color_picker_group.vue";
import {dict_mixin} from "@/shared/mixins/dict_mixin";
import InputFileUpload from "@/shared/components/dialogs/custom_components/input_file_upload.vue";
import ThemeImagePicker from "@/views/components/theme/settings/theme_image_picker.vue";
import ColorSchemePicker from "@/views/components/theme/settings/color_scheme_picker.vue";

export default {
    name: "ThemeSettingItem",
    components: {ColorSchemePicker, ThemeImagePicker, InputFileUpload, ColorPickerGroup, ColorPicker},
    mixins: [dict_mixin],
    props: {
        value: {},
        type: {
            type: String,
            required: true
        },
        name: {
            type: String,
        },
        label: {
            type: String,
        },
        setting: {
            type: Object,
            required: true
        },
        theme_locales: {
            type: Object
        }
    },
    data() {
        return {
            internal_value: null
        }
    },
    watch: {
        internal_value(val, old_val) {
            this.$emit("input", val)
        },
        value(val) {
            this.internal_value = val
        }
    },
    methods: {
        get_translation(val, capitalize = true) {
            if (val === undefined) {
                console.log("Undefined setting item")
                console.log(this.setting.label)
                console.log(this.label)
                return "undefined"
            }
            let path = `data.${val.substring(2)}`
            let translation = this.deep_get(this.theme_locales, path)
            if (translation) {
                if (capitalize) {
                    return translation.charAt(0).toUpperCase() + translation.slice(1)
                }
                return translation
            }
            if (capitalize) {
                return val.charAt(0).toUpperCase() + val.slice(1)
            }
            return val
        }
    },

    computed: {
        options() {
            return this.setting.options.map((val) => {
                    return {
                        label: this.get_translation(val.label), value:
                        val.value
                    }
                }
            )
        }
    },
    created() {
        this.internal_value = this.value
    }
}
</script>


<style scoped>

</style>