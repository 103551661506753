<template>
    <div>
        <loading v-if="loading"/>
        <v-row justify="center" v-else>
            <v-col cols="8">
                <br>
                <p :align="'start'">{{ translate("service", true) }}</p>
                <v-list dense>
                    <v-list-item v-for="(service, index) in form.services" v-bind:key="index" dense>
                        <v-list-item-content>
                            {{ service?.locale?.[locale] ? service?.locale?.[locale] : service.name }}
                            {{ service.amount }} kr
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <br>
                <p :align="'start'">{{ translate("products", true) }}</p>
                <v-list dense>
                    <v-list-item v-for="(product, index) in form.products" v-bind:key="index">
                        <v-list-item-content>
                            <v-text-field type="number" v-model="product.number_of_items">

                            </v-text-field>
                            {{ }}{{ product?.locale?.[locale] ? product?.locale?.[locale] : product.name }}
                            {{ product.amount }} kr
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <br>
                <p :align="'start'" v-for="(contact_field, index) in contact_info" v-bind:key="index">
                    {{ translate(contact_field.locale, true) }}:
                    {{ contact_field.value }}
                </p>
                <p :align="'start'">{{ translate("reference_number") }} {{ form?.reference_number }} </p>
                <p :align="'start'">{{ translate("status") }}
                    {{ form?.status ? form?.status : translate('no_status', true) }} </p>
                <p :align="'start'"> {{ translate("total", true) }}: {{ form.total }} kr</p>
            </v-col>
        </v-row>

    </div>
</template>

<script>
import {api_mixin} from "@/shared/mixins/api_mixin";
import {layout_mixin} from "@/shared/mixins/layout";
import {dict_mixin} from "@/shared/mixins/dict_mixin";
import Loading from "@/shared/components/loading.vue";

export default {
    name: "ValidationPage",
    components: {Loading},
    mixins: [api_mixin, layout_mixin, dict_mixin],
    props: {
        value: {
            required: true
        },
        app: {
            type: Object,
            required: true
        },
        recalculate: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            form: {},
            loading: false
        }
    },
    computed: {
        locale() {
            return this.$i18n.locale
        },
        app_settings() {
            return this.deep_get(this.app, `settings.${this.app.type}`)
        },
        contact_info() {
            let contact_field = "customer"
            let tmp = []
            if (contact_field in this.form) {

                for (let key of Object.keys(this.form[contact_field])) {
                    if (['ip_address', 'url'].includes(key)) {
                        continue
                    }
                    tmp.push({
                        locale: key,
                        value: this.form[contact_field][key]
                    })
                }
            }
            return tmp
        },

    },
    watch: {
        recalculate(val) {
            if (val) {
                this.get_form_data()
            }
        }
    },
    methods: {
        async get_form_data() {
            let data = {}
            data["app"] = this.app["_id"]
            data["company"] = this.app["company"]
            data["data"] = this.value
            console.log(data)
            let tmp = await this.api_post(
                {
                    resource: "forms/validate_form_booking",
                    data: data,
                    return_data: true,
                    auth: this.auth
                }
            )
            this.form = tmp.data

        },
    },
    async created() {
        await this.get_form_data()
        console.log(this.recalculate)
    }
}
</script>

<style scoped>

</style>