<template>
    <v-card v-if="!internal_loading">
        <v-card-text>
            <v-expansion-panels>
                <v-expansion-panel
                    v-for="(setting_groups, index) in theme_settings_schema.setting_groups"
                    v-bind:key="index"
                >
                    <v-expansion-panel-header>
                        {{ get_translation(setting_groups.name) }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-col v-for="setting in setting_groups.settings"
                               v-bind:key="setting.id">
                            <v-row>
                                <ThemeSettingItem
                                    v-model="theme_setting_data.data[setting.id]"
                                    :label="setting.label"
                                    :setting="setting"
                                    :type="setting.type"
                                    :theme_locales="theme_locales"
                                />
                            </v-row>
                        </v-col>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card-text>
        <v-card-actions>
            <v-btn @click="save_setting_data">
                {{ translate("save") }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import ThemeSettingItem from "@/views/components/theme/settings/theme_setting_item.vue";
import {layout_mixin} from "@/shared/mixins/layout";
import {api_mixin} from "@/shared/mixins/api_mixin";
import {dict_mixin} from "@/shared/mixins/dict_mixin";

export default {
    name: "ThemeSiteSettings",
    components: {ThemeSettingItem},
    mixins: [layout_mixin, api_mixin, dict_mixin],
    props: {
        theme: {
            type: Object,
            required: true
        },
        theme_locales: {
            default: null,
            type: Object
        }
    },
    data() {
        return {
            internal_theme_locales: null,
            theme_settings_schema: null,
            theme_setting_data: null
        }
    },
    methods: {
        get_translation(val) {
            if (val === undefined) {
                return "undefined"
            }
            let path = `data.${val.substring(2)}`
            let translation = this.deep_get(this.internal_theme_locales, path)

            if (translation) {
                return translation
            }
            return path
        },
        async load_data() {
            this.internal_theme_locales = await this.api_get_items({
                    resource: "theme_locales",
                    lookup: {
                        theme: this.theme_id,
                        name: "en.default.schema.json",
                        is_default: true
                    },
                    first: true
                }
            )
            this.theme_settings_schema = await this.api_get_items({
                resource: "theme_setting_schemas",
                lookup: {theme: this.theme["_id"]},
                first: true
            })
            this.theme_setting_data = await this.api_get_items({
                resource: "theme_setting_data",
                lookup: {
                    theme: this.theme["_id"],
                    _id: this.theme.current_setting
                },
                first: true
            })
            this.internal_loading = false
        },
        async save_setting_data() {
            let res = await this.api_put({
                    resource: "theme_setting_data",
                    data: this.theme_setting_data,
                }
            )
            this.toast_results(res, "Template updated")
            if (res._status === 'OK') {
                this.theme_setting_data._etag = res._etag
            }
        }
    },
    async created() {
        await this.load_data()
    }
}
</script>


<style scoped>

</style>