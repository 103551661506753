import { render, staticRenderFns } from "./monaco.vue?vue&type=template&id=33c172af&scoped=true"
import script from "./monaco.vue?vue&type=script&lang=js"
export * from "./monaco.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33c172af",
  null
  
)

export default component.exports