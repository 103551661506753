<template>
    <v-list dense>
        <activities_item :form_log="form_log" v-for="form_log in form_logs" v-bind:key="form_log._id">
        </activities_item>
    </v-list>
</template>

<script>
import {api_mixin} from "@/shared/mixins/api_mixin";
import Activities_item from "@/views/components/form/view_booking/activites/activities_item.vue";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "activity",
    components: {Activities_item},
    mixins: [api_mixin],
    props: {
        form: {
            type: Object
        }
    },
    data() {
        return {
            form_logs: []
        }
    },
    watch: {
        refresh_resource: {
            async handler(val) {
                if (val?.["form_logs"]) {
                    await this.get_data()
                    await this.change_refresh_resource({resource: "form_logs", value: false})
                }
            },
            deep: true
        }
    },
    computed: {
        ...mapGetters({refresh_resource: "dialog/refresh_resource"})
    },
    methods: {
        ...mapActions({change_refresh_resource: "dialog/change_refresh_resource"}),
        async get_data() {
            this.form_logs = await this.api_get_items({
                resource: "form_logs",
                lookup: {
                    form: this.form._id,
                    app: this.form.app,
                    company: this.form.company
                },
                sort: {
                    _created: -1
                }
            })
        }
    },
    async created() {
        await this.get_data()
    }

}
</script>

<style scoped>

</style>