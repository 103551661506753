<template>
    <div>
        <v-dialog v-model="show_dialog" width="unset" overlay-opacity="0">
            <v-card>
                <v-card-title>
                    {{ translate("update_expected_delivery") }}
                </v-card-title>
                <v-card-text>
                    <IndeStepperDate
                        v-model="date" min="today"
                        :time="false"
                        :label="translate('date')"
                    />

                    <InputSelect
                        v-model="assign_to"
                        :label="translate('user')"
                        resource="user_company_rights"
                        :lookup="lookup"
                        item-text="full_name"
                        item-value="_id"
                        hide_create
                    />
                    <v-btn @click="assign_to_me" class="primary">
                        {{ translate("assign_to_me") }}
                    </v-btn>

                </v-card-text>
                <v-card-actions>
                    <v-btn @click="show_dialog = false">
                        {{ translate('cancel', true) }}
                    </v-btn>
                    <v-btn @click="save_expected_delivery" :disabled="!(assign_to || date)" class="primary">
                        {{ translate('save', true) }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-btn @click="show_dialog = true" :icon="icon" :class="{'primary':!icon}">
            <v-icon v-if="icon" :small="small">
                mdi-clock
            </v-icon>
            <template v-else>
                {{ translate('update_expected_delivery') }}
            </template>
        </v-btn>
    </div>

</template>

<script>
import {api_mixin} from "@/shared/mixins/api_mixin";
import {layout_mixin} from "@/shared/mixins/layout";
import Spacing from "@/shared/components/helper/Spacing.vue";
import {mapActions, mapGetters} from "vuex";
import IndeStepperDate from "@/shared/components/dialogs/stepper_date.vue";
import InputSelect from "@/shared/components/dialogs/input_select.vue";
import Autocomplete from "@/views/components/form/form/autocomplete.vue";

export default {
    name: "AddExpectedDelivery",
    components: {Autocomplete, InputSelect, IndeStepperDate, Spacing},
    mixins: [api_mixin, layout_mixin],
    props: {
        form: {
            type: Object,
            required: true
        },
        icon: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            show_dialog: false,
            date: null,
            assign_to: null
        }
    },
    watch: {},
    computed: {
        ...mapGetters({
            current_item: "dialog/current_item",
            current_user: "auth/current_user"
        }),
        lookup() {
            return {
                params: {$company: "company"},
                filter: {$level: 5},
                aggregation: true,
                label: "full_name"
            }
        }
    },
    methods: {
        ...mapActions({
            reload_item: "dialog/reload_item",
            change_refresh_resource: "dialog/change_refresh_resource"
        }),

        assign_to_me() {
            this.assign_to = this.current_user._id
        },
        async save_expected_delivery() {
            if (this.date === null && this.assign_to == null) {
                return
            }
            let data = {
                _id: this.form["_id"],
                _etag: this.form["_etag"],
                expected_delivery: this.date,
                assign_to: this.assign_to
            }
            let res = await this.api_post(
                {
                    resource: "forms/update_expected_delivery",
                    data: data,
                    remove_id: false
                }
            )
            this.toast_results(res, "expected_delivery_updated")
            this.show_dialog = false

            await this.reload_item({resource: "forms", resource_id: this.form._id})
            await this.change_refresh_resource({resource: "forms", value: true})
        }
    },
    created() {
    }
}
</script>

<style scoped>

</style>