<template>
    <div style="border: #2222b8; border-width: 10px;">
        <div v-html="value">
        </div>
    </div>
</template>

<script>
export default {
    name: "InputHtmlViewer",
    props: {
        value: {
            type: String,
        },
        label: {
            type: String
        }
    }
}
</script>

<style scoped>

</style>