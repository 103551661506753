<template>
    <div>
        <v-autocomplete
            v-model="internal_value"
            :items="items"
            :item-text="itemText"
            :item-value="itemValue"
            :rules="rules"
            :clearable="clearable"
            :label="label"
            :multiple="multiple"
        >
            <template #selection="{item}">
                <slot name="selection" :item="item"/>
            </template>
            <template #item="{item}">
                <slot name="item" :item="item"/>
            </template>

        </v-autocomplete>
    </div>
</template>

<script>
export default {
    name: "autocomplete",
    props: {
        items: {
            required: true
        },
        itemText: {
            type: String,
            required: true
        },
        itemValue: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        rules: {
            type: Array,
            default() {
                return []
            }
        },
        clearable: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            internal_value: null
        }
    },
    computed: {},
    watch: {
        internal_value(val) {
            this.$emit("input", val)
        },
        value(val) {
            this.internal_value = val
        }

    },
    created() {
        if (!Array.isArray(this.items)) {
        }
        this.internal_value = this.value
    }
}
</script>

<style scoped>

</style>