<template>
    <div>
        <v-list-item v-if="event === 'new_booking'">
            <v-list-item-content>
                <v-list-item-title>
                    {{ translate('booking_was_created', true) }} {{ format_date(form_log._created) }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="form_log.user_name">
                    {{ format_smart(form_log._created, translate) }} {{ by }} {{ form_log.user_name }}
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="event === 'status'">
            <v-list-item-content>
                <v-list-item-title>
                    {{ form_log.user_name }} {{ translate('updated_status_to', false) }}
                    {{ translate(form_log.status, false) }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="form_log.user_name">
                    {{ format_smart(form_log._created, translate) }} {{ by }} {{ form_log.user_name }}
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="event === 'expected_delivery'">
            <v-list-item-content>
                <v-list-item-title>
                    Förväntad leverans uppdaterad till {{ format_date(form_log.expected_delivery) }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="form_log.user_name">
                    {{ format_smart(form_log._created, translate) }} {{ by }} {{ form_log.user_name }}
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="event === 'assigned_to'">
            <v-list-item-content>
                <v-list-item-title>
                    {{ translate("assigned_to") }} {{ form_log.assigned_to_name }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="form_log.user_name">
                    {{ format_smart(form_log._created, translate) }} {{ by }} {{ form_log.user_name }}
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="event === 'internal_note'">
            <v-list-item-content>
                <v-list-item-title>
                    {{ form_log.user_name }} {{ format_smart(form_log._created) }}
                </v-list-item-title>
                <v-text-field :value="form_log.internal_note" disabled dense/>
            </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="event === 'modified_booking'">
            <v-list-item-content>
                <v-list-item-title>
                    {{ translate('modified_booking') }}. {{ translate('new_total') }}: {{ form_log.modified_booking }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="form_log.user_name">
                    {{ format_smart(form_log._created, translate) }} {{ by }} {{ form_log.user_name }}
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <template v-if="event === 'expected_delivery_and_assigned_to'">
            <v-list-item v-if="form_log.assigned_to_name">
                <v-list-item-content>
                    <v-list-item-title>
                        {{ translate("assigned_to") }} {{ form_log.assigned_to_name }}
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="form_log.user_name">
                        {{ format_smart(form_log._created, translate) }} {{ by }} {{ form_log.user_name }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="form_log.expected_delivery">
                <v-list-item-content>
                    <v-list-item-title>
                        Förväntad leverans uppdaterad till {{ format_date(form_log.expected_delivery) }}
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="form_log.user_name">
                        {{ format_smart(form_log._created, translate) }} {{ by }} {{ form_log.user_name }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

        </template>
    </div>
</template>

<script>
import {format, format_date} from "@/shared/helpers/date_helper";
import {layout_mixin} from "@/shared/mixins/layout";

export default {
    name: "activities_item",
    mixins: [layout_mixin],
    methods: {format, format_date},
    props: {
        form_log: {
            type: Object,
            required: true
        }
    },
    computed: {
        event() {
            return this.form_log.event
        },
        by() {
            return this.translate("by", false)
        }
    }
}
</script>

<style scoped>

</style>