import {DateTime} from "@/shared/helpers/date_helper";


export let booking_mixin = {
    methods: {
        add_zeros(hour) {
            hour = hour.toString()
            if (hour.length === 1) {
                return "0" + hour + ""
            }
            return hour + ""
        },
        text(block) {
            return `${this.add_zeros(block.date_from.hour)} - ${this.add_zeros(block.date_to.hour)}`
        },

    }
}