<template>
    <div>
        <v-list-item
            dense
            v-for="(language, index) in locales"
            :key="'language_' + index"
            @click="update_locale(index)"
        >

            <v-list-item-avatar
                class="pb-1"
                tile
            >
                <CountryFlag
                    :country="language"
                    size="normal"
                />

            </v-list-item-avatar>

            <v-list-item-title v-if="!disable_text">{{ $t(`common.${index}`) }}</v-list-item-title>
            <v-list-item-avatar max-width="200px">
                <v-icon v-if="index === $i18n.locale" dense>
                    mdi-check-circle
                </v-icon>
            </v-list-item-avatar>

        </v-list-item>
    </div>
</template>

<script>
import {layout_mixin} from "@/shared/mixins/layout";
import CountryFlag from 'vue-country-flag'
import {mapActions, mapGetters} from "vuex";

export default {
    name: "locale-selector",
    components: {CountryFlag},
    props: {
        disable_update_user_settings: {
            type: Boolean,
            default: false
        },
        disable_text: {
            type: Boolean,
            default: false
        }
    },
    mixins: [layout_mixin],
    computed: {
        ...mapGetters({
            current_user: "auth/current_user"
        })
    },
    methods: {
        ...mapActions({
                change_locale: "auth/change_locale"
            }
        ),
        update_locale(locale) {
            if (this.current_user !== null) {
                this.change_locale({locale, i18n: this.$i18n})
            } else {
                this.$i18n.locale = locale
            }
        }
    },

}
</script>

<style scoped>

</style>