<template>
    <div>
        <inde-table-view
            resource="themes"
            :default_data="default_data"
            :locked_data="default_data"
            :lookup="default_data"
            :custom_headers="['edit_theme', 'parse_theme', 'open_theme', 'delete_related_theme_items']"
        >
            <template #item.edit_theme="{item}">
                <v-btn @click.stop="()=>go_to_theme_editor(item)" icon class="mb-2">
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
            </template>
            <template #item.open_theme="{item}">
                <v-btn @click.stop="()=>open_theme(item)" icon class="mb-2">
                    <v-icon>
                        mdi-link
                    </v-icon>
                </v-btn>
            </template>
            <template #item.delete_related_theme_items="{item}">
                <IndeResourceAction
                    resource="themes"
                    action="theme_delete_related_objects"
                    :item="{'theme_id': item['_id']}"
                    :default_data_fields="['theme_id']"
                    :locked_data_fields="['theme_id']"
                    disable_reload_item
                >
                    <template #default="{item}">
                        <p></p>
                    </template>
                </IndeResourceAction>
            </template>
            <template #item.parse_theme="{item}">
                <IndeResourceAction
                    v-if="item.status !== 'completed'"
                    resource="themes"
                    action="parse_theme"
                    :item="{'theme_id': item['_id']}"
                    :default_data_fields="['theme_id']"
                    :locked_data_fields="['theme_id']"
                    disable_reload_item
                >
                    <template #default="{item}">
                        <p></p>
                    </template>
                </IndeResourceAction>
            </template>

        </inde-table-view>
    </div>
</template>
<script>
import IndeDialog from "@/shared/components/dialogs/inde_dialog.vue";
import IndeTableView from "@/shared/components/view/inde_table_view.vue";
import {layout_mixin} from "@/shared/mixins/layout";
import IndeResourceAction from "@/shared/components/view/inde_resource_action.vue";

export default {
    name: "theme_main",
    mixins: [layout_mixin],
    props: {
        company_id: {type: String},
        app_id: {type: String},
    },
    components: {IndeResourceAction, IndeTableView, IndeDialog},
    computed: {
        default_data() {
            return {
                company: this.company_id,
                app: this.app_id
            }
        }
    },
    methods: {
        go_to_theme_editor(item) {
            this.$router.push({
                name: "theme_editor",
                params: {
                    theme_id: item["_id"]
                }
            })
        },
        open_theme(item) {
            let url = `${process.env.VUE_APP_BACKEND_URL}/api/themes/render/${item._id}`
            window.open(url)
        },
    }

}
</script>

<style scoped>

</style>